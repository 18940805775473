import React from "react";
import StakingTab from "./StakingTab";

const SateliteStake = () => {
  const [selectBurn1, setSelectBurn1] = React.useState(false);
  const [selectBurn, setSelectBurn] = React.useState(false);
  return (
    <div>
      <StakingTab
        //   AdminOwner={AdminOwner}
        // stakedValue={
        //   user.controlData?.satelliteStacking
        //     ? user.controlData?.satelliteStacking
        //     : 50
        // }
        createStake={false}
        selectBurn1={selectBurn1}
        setSelectBurn={setSelectBurn}
        setSelectBurn1={setSelectBurn1}
        //   handleCloseStake={handleCloseStake}
      />
    </div>
  );
};

export default SateliteStake;
