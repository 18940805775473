import React from "react";
import {
  makeStyles,
  Grid,
  Typography,
  Box,
  Card,
  CardContent,
  CardActions,
  Button,
} from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

const useStyles = makeStyles((theme) => ({
  main: {
    position: "relative",
    padding: "50px 0 0",
  },
  mintingButton: {
    color: "#fff",
    background: "linear-gradient(272.26deg, #0DD0FF 36.78%, #05A4CA 86.13%)",
    boxSizing: "border-box",
    borderRadius: "5px",
    width: "20%",
    height: "48px",
  },
  items1: {
    // display: "flex",
    // justifyContent: "center",
    width: "100%",
    height: "100%",
    // flexDirection: "column"
  },
  items2: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    paddingTop: "50px",
    flexWrap: "wrap",
    // border: "2px solid white",
    // flexDirection: "column"
  },

  img1: {
    // border: "1px dashed #0B628F",
    width: "100%",
    height: "100%",
  },

  textbox: {
    "& h1": {
      fontSize: "50px",
      fontWeight: "bold",
      color: "white",
      marginTop: "-72px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "30px",
        marginTop: "-29px",
      },
      "& span": {
        color: "#392472",
      },
    },
    "& p": {
      color: "white",
      fontSize: "14px",
      maxWidth: "767px",
      lineHeight: "25px",
    },

    "& small": {
      justifyContent: "center",
      "& div": {
        width: "50px",
        height: "1px",
        backgroundColor: "white",
        marginRight: "10px",
      },
      fontSize: "25px",
      color: "#392472",
      textShadow: "0px 0px 29px #392472",
      display: "flex",
      marginTop: "10px",
      alignItems: "center",
    },
  },
}));

function Roadmap1() {
  const classes = useStyles();
  return (
    <div>
      <Grid container lg={12} xs={12} className={classes.main}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={2}></Grid>
          <Grid item xs={12} sm={8}>
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src="./images/Roadmap.png" style={{ maxWidth: "70%" }} />
            </Box>
            <Box className={classes.textbox} align="center">
              <Typography variant="h1">Roadmap</Typography>
            </Box>
            <Box className={classes.textbox} align="center" mt={2}>
              <Typography variant="body2">
                A suit of tools were build to help you to create your own tokens
                and launchpads in a fast, simple and cheap way, with no prior
                code knowledge required and 100% decentralized!
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={2}></Grid>
        </Grid>
        <Grid item lg={12} md={12} xs={12} className={classes.items2}>
          <img
            src="../images/roadmap.svg"
            alt="loading...."
            width="100%"
            className={classes.img1}
          ></img>
        </Grid>
      </Grid>
      <Box align="center">
        <Button variant="contained" color="primary" size="large">
          View more details
        </Button>
      </Box>
    </div>
  );
}

export default Roadmap1;
