import React from "react";
import {
  Grid,
  Box,
  Container,
  Typography,
  makeStyles,
} from "@material-ui/core";
import NotificationCard from "../../components/NotificationCard";

const FaqDataList2 = [
  {
    img: "images/logo_2.png ",
    title: "Unicorn Cake Arts  ",
    time: "Unicorn Cake Arts ",
    discription:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy  ",
  },
  {
    img: "images/logo_2.png ",
    title: "Unicorn Cake Arts  ",
    time: "Unicorn Cake Arts ",
    discription:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy  ",
  },
  {
    img: "images/logo_2.png ",
    title: "Unicorn Cake Arts  ",
    time: "Unicorn Cake Arts ",
    discription:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy  ",
  },
  {
    img: "images/logo_2.png ",
    title: "Unicorn Cake Arts  ",
    time: "Unicorn Cake Arts ",
    discription:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy  ",
  },
];

const useStyles = makeStyles((theme) => ({
  FAQ: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(6),
  },
  PageHeading: {
    fontSize: "45px",
      fontWeight: "bold",
      lineHeight: "55px",
      color: "#FABE25",
      textAlign:"left",
  },
}));

export default function FAQ() {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.FAQ}>
        {/* featured */}
        <Box mt={5} mb={2}>
          <Container maxWidth="lg" align="left"> 
            <Typography variant="h2" className={classes.PageHeading}>
              Notification{" "}
            </Typography>
            
            <Box mt={5}>
            <Grid container spacing={2}>
              {FaqDataList2.map((data, i) => {
                return (
                  <Grid item xs={12} sm={12} md={12} key={i}>
                    <NotificationCard data={data} index={i} />
                  </Grid>
                );
              })}
            </Grid>
            </Box>
           
          </Container>
        </Box>
      </Box>
    </>
  );
}
