import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  Container,
  makeStyles,
  Button,
  Grid,
  Typography,
} from "@material-ui/core";
// import Page from "src/components/Page";
import Satellite from "./Satellite";
// import BNB from "./BNB";
// import ETH from "./ETH";
// import BTC from "./BTC";
// import Taral from "./Taral";
import { UserContext } from "../../context/User";
import { useWeb3React } from "@web3-react/core";
import { sortAddress, getContract, getWeb3ContractObject } from "../../utils";
import axios from "axios";
// import apiConfig from "src/config/apiConfig";
import {
  StatelliteStakingAddress,
  // busdStakingAddress,
  // stakeListAddress3,
  // stakeListAddress4,
  // getStakeAddress,
} from "../../constants";
import StatelliteStakingABI from "../../abis/StatelliteStakingABI.json";
import ERC20ABI from "../../abis/IERC20ABI.json";
import { toast } from "react-toastify";
// import { ethers } from "ethers";
import ConnectWallet from "../../components/ConnectWalletPopUp";
import { useHistory } from "react-router-dom";
import Web3 from "web3";

const useStyles = makeStyles((theme) => ({
  walletPage: {
    position: "relative",
    zIndex: "9",
    "& button": {
      color: "#000",
      fontSize: "16px",
      padding: "10px",
      border: " 1px solid #8eddff",
      textTransform: "capitalize",
      backgroundColor: "#fff",
      marginRight: "15px",
      borderRadius: "10px",
      minWidth: "150px",
      [theme.breakpoints.down("md")]: {
        marginBottom: "15px",
        minWidth: "auto",
      },
      "&:hover": {
        color: "#fff !important",
        backgroundColor: "#0e2137",
        borderColor: "#000",
      },
      "&.active": {
        color: "#fff !important",
        backgroundColor: "#0e2137",
        borderColor: "#000",
      },
    },
  },
  bannerBox: {
    display: "flex",
    justifyContent: "space-between",
    "& h2": {
      fontSize: "30px",
      fontWeight: "bold",
      marginBottom: "10px",
      color: "#0e2137",
      [theme.breakpoints.down("md")]: {
        fontSize: "35px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "30px",
      },
    },
  },
  StakingBox: {
    display: "flex",
    alignItems: "center",
    "& img": {
      width: "40px",
      marginRight: "10px",
    },
    "& h4": {
      fontSize: "18px",
    },
  },
  menuButtonBtn: {
    backgroundColor: "#0E2137",
    borderRadius: "5px",
    color: "#fff",
    height: "45px",
    paddingLeft: "15px",
    paddingRight: "15px",
    fontSize: "16px",
    minWidth: "100px",
    fontWeight: 600,
    marginLeft: "10px",
    // border: "3px solid #26476D",
    border: "3px solid #6A86A7",
    [theme.breakpoints.down("sm")]: {
      marginTop: "15px",
    },
    "&:hover": {
      // boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
    },
  },
}));
function Index(props) {
  const { account, library } = useWeb3React();
  const classes = useStyles();
  const history = useHistory();
  const user = useContext(UserContext);
  const { taral, revolution, rodmarsCoin, setalite } = user;
  const [tabview, setTabView] = useState("Satellite");
  const [setLiteAmount, setsetLiteAmount] = useState();
  const [harvestInterval, setHarvestInterval] = useState(0);
  const [withdrawInterval, setwithdrawInterval] = useState(0);
  const [nextHarvestUntil, setnextHarvestUntil] = useState(0);
  const [nextWithdrawUntil, setnextWithdrawUntil] = useState(0);
  const [stakeloader, setstakeloader] = useState(false);
  const [unStakeloader, setUnStakeloader] = useState(false);
  const [erwardperBlock, setReward] = useState();
  const [userStakedTokens, setUserStakedTokens] = useState();
  const [setLiteUnstakeAmount, setsetLiteUnstakeAmount] = useState();
  const [openWalletConnect, setOpenWalletConnect] = useState(false);
  const [satelliteBalance, setSatelliteBalance] = useState(0);
  const [taralityBalance, setTaralityBalance] = useState(0);
  const [redmarsBalance, setRedmarsBalance] = useState(0);
  const [revolutionBalance, setRevolutionBalance] = useState(0);
  const [pendingRewards, setPendingRewards] = useState();
  const [isActiveSubsription, setIsActiveSubsription] = useState(false);
  const createStakeData = props.createStake == true ? true : false;

  const httpProvider = new Web3.providers.HttpProvider(
    "https://bsc-dataseed1.ninicoin.io"
  );
  const web3 = new Web3(httpProvider);
  console.log(props.stakedValue);
  //deployContract
  //handleCloseStake

  // useEffect(() => {
  //   console.log(user.isLogin);
  //   // if (account && user.isLogin) {
  //   getCoinBalance();
  //   // }
  // }, [account]);
  // const getCoinBalance = async () => {
  //   console.log("erc20address");
  //   try {
  //     const web3 = (window.web3 = new Web3(window.ethereum));
  //     // const contractAddress = getStakeAddress(tab);
  //     const contract = new web3.eth.Contract(
  //       StatelliteStakingABI,
  //       StatelliteStakingAddress
  //     );
  //     console.log("erc20address", contract);

  //     const erc20address = await contract.methods.rewardToken().call();
  //     console.log("erc20address", erc20address);
  //     const ercContract = new web3.eth.Contract(ERC20ABI, erc20address);
  //     console.log(ercContract);
  //     // let balanceOf;
  //     if (account) {
  //       const balanceOf = await ercContract.methods.balanceOf(account);
  //       console.log("balanceOf", balanceOf.toString());
  //     }
  //   } catch (error) {
  //     console.log("ERROR", error);
  //   }
  // };
  const getCoinBalanceAccount = async () => {
    console.log("erc20address");
    try {
      const web3 = (window.web3 = new Web3(window.ethereum));
      // const contractAddress = getStakeAddress(tab);
      const contractObj = getContract(
        StatelliteStakingAddress,
        StatelliteStakingABI,
        library,
        account
      );
      console.log("erc20address", contractObj);

      const erc20address = await contractObj.rewardToken();
      console.log("erc20address", erc20address);
      const ercContract = getContract(erc20address, ERC20ABI, library, account);

      const balanceOf = await ercContract.balanceOf(account);
      console.log("balanceOf", balanceOf.toString());
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  useEffect(() => {
    // console.log(user.isLogin);
    if (account) {
      getCoinBalanceAccount();
      getPoolStakeHandler();
    }
  }, [account, user.isLogin]);

  const getPoolStakeHandler = async () => {
    try {
      // console.log(StatelliteStakingAddress);
      // const contractAddress = getStakeAddress(tabview);
      const contract = await new web3.eth.Contract(
        StatelliteStakingABI,
        StatelliteStakingAddress
      );
      // console.log(contract);
      const poolDetails = await contract.methods.pool().call();
      // console.log(poolDetails);
      setHarvestInterval(poolDetails?.harvestInterval.toString());
      setwithdrawInterval(poolDetails?.withdrawInterval.toString());

      const rewardPerBlock = await contract.methods.rewardPerBlock().call();
      // const rewad = web3.utils.toWei(rewardPerBlock.toString());

      // setReward(rewad);

      if (account) {
        const pendingRewards = await contract.methods
          .pendingRewards(account)
          .call();
        const pendingRewardsETH = web3.utils.fromWei(pendingRewards.toString());
        // console.log("pendingRewardsETH", pendingRewardsETH);
        setPendingRewards(pendingRewardsETH);
        const userInfo = await contract.methods.userInfo(account).call();
        setUserStakedTokens(web3.utils.fromWei(userInfo.amount.toString()));
        setnextHarvestUntil(userInfo.nextHarvestUntil.toString());
        setnextWithdrawUntil(userInfo.nextWithdrawUntil.toString());
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {}, [account, tabview]);
  const getStakingDataHandler = async () => {
    // console.log("setLiteAmount", setLiteAmount);
    let stakedAmountMin;
    if (props.createStake) {
      stakedAmountMin = props.stakedValue;
    } else {
      stakedAmountMin = setLiteAmount;
    }
    console.log(
      setLiteAmount,
      "setLiteAmount--------------setLiteAmount",
      stakedAmountMin
    );
    if (
      stakedAmountMin &&
      stakedAmountMin != "" &&
      Number(stakedAmountMin) > 0
    ) {
      try {
        setstakeloader(true);
        if (account) {
          if (tabview === "Satellite") {
            props.setSelectBurn1(true);
            const web3 = (window.web3 = new Web3(window.ethereum));

            const contract = getContract(
              StatelliteStakingAddress,
              StatelliteStakingABI,
              library,
              account
            );
            console.log("-------------", contract);
            const erc20address = await contract.pool();
            const contract1 = getContract(
              erc20address.stake,
              ERC20ABI,
              library,
              account
            );
            // console.log("setalite", setalite);
            console.log("-------------", contract1);
            console.log("-------------", erc20address);
            console.log(setalite);
            // if (setLiteAmount >= setalite[0]?.amount) {
            const amountToSend = web3.utils.toWei(stakedAmountMin.toString());
            const balanceOfValue = await contract1.balanceOf(account);
            const walletBalance = web3.utils.toWei(balanceOfValue.toString());
            console.log(setLiteAmount);
            if (Number(walletBalance) > stakedAmountMin) {
              if (props.createStake) {
                const transfer = await web3.eth.sendTransaction(
                  {
                    from: account,
                    to: props.AdminOwner,
                    value: web3.utils.toWei(
                      user.controlData?.commisionStacking.toString()
                    ),
                  },
                  function async(err, transactionHash) {
                    if (err) {
                      console.log("Payment failed Error", err);
                      return;
                    } else {
                    }
                  }
                );
              }

              const approveFun = await contract1.approve(
                StatelliteStakingAddress,
                amountToSend
              );
              await approveFun.wait();
              const ownerFun = await contract.deposit(amountToSend);
              await ownerFun.wait();
              props.setSelectBurn(true);
              props.setSelectBurn1(false);
              props.handleCloseStake();
              // const amount = setLiteAmount;
              // const stakeName = "Satellite";
              // SaveStakeDetails(amount, stakeName);
              toast.success("Succesfully stake");
            } else {
              setstakeloader(false);
              toast.error("Low balance");
            }
            // } else {
            //   setstakeloader(false);

            //   toast.error(
            //     `Stake amount should be greater than ${setalite[0]?.amount}`
            //   );
            // }
          } else {
            setstakeloader(false);

            toast.error("!");
          }
        } else {
          toast.error("Please connect your wallet first!");
        }
        getPoolStakeHandler();
        setstakeloader(false);
      } catch (error) {
        setstakeloader(false);

        toast.error(error.message);
        console.log("error????", error);
      }
    } else {
      toast.error("Please Enter valid data");
    }
    // getAllCoinsData();
    getCoinBalanceAccount();
  };
  const getUnStakingDataHandler = async () => {
    if (
      setLiteUnstakeAmount &&
      setLiteUnstakeAmount != "" &&
      Number(setLiteUnstakeAmount) > 0
    ) {
      try {
        setUnStakeloader("withdraw");
        if (account) {
          if (tabview === "Satellite") {
            const contract = getContract(
              StatelliteStakingAddress,
              StatelliteStakingABI,
              library,
              account
            );
            const canWithdraw = await contract.canWithdraw(account);

            if (canWithdraw) {
              const amountToSend = web3.utils.toWei(
                setLiteUnstakeAmount.toString()
              );
              // console.log("amountToSend", amountToSend);
              const ownerFun = await contract.withdraw(amountToSend);
              await ownerFun.wait();

              const amount = amountToSend;
              const stakeName = "Satellite";
              // SaveUnStakeDetails(amount, stakeName);
            } else {
              toast.error("Not enough withdraw time");
            }
          } else {
            toast.error("!");
          }
        } else {
          setUnStakeloader();
          toast.error("Please connect your wallet first!");
        }
        getPoolStakeHandler();
        setUnStakeloader();
      } catch (error) {
        setUnStakeloader();
        console.log(error);
        if (error.data) {
          toast.error(error.data.message);
        } else {
          toast.error(error.message);
        }
      }
    } else {
      toast.error("Please Enter valid data");
    }
    // getAllCoinsData();
    getCoinBalanceAccount();
  };

  const getClaimDataHandler = async () => {
    // if (isActiveSubsription) {
    if (pendingRewards && Number(pendingRewards) > 0) {
      try {
        setUnStakeloader("claim");
        if (account) {
          if (tabview === "Satellite") {
            const contract = getContract(
              StatelliteStakingAddress,
              StatelliteStakingABI,
              library,
              account
            );
            const canHarvest = await contract.canHarvest(account);
            if (canHarvest) {
              const ownerFun = await contract.claim();
              await ownerFun.wait();
            } else {
              toast.error("Not enough claim time");
            }
          } else {
            toast.error("!");
          }
        } else {
          setUnStakeloader();
          toast.error("Please connect your wallet first!");
        }
        getPoolStakeHandler();
        setUnStakeloader();
      } catch (error) {
        setUnStakeloader();
        console.log(error);
        if (error.data) {
          toast.error(error.data.message);
        } else {
          toast.error(error.message);
        }
      }
    } else {
      toast.error("Not enough balance to claim");
    }
    // getAllCoinsData();
    getCoinBalanceAccount();
    // } else {
    //   toast.error("You don`t have active subscription to claim");
    //   history.push("/app/mining");
    // }
  };
  const handleDesconnect = () => {
    user?.disconnectWallet();
  };

  return (
    <Box className={classes.mainBox} mb={3}>
      {openWalletConnect && (
        <ConnectWallet
          open={openWalletConnect}
          handleClose={() => setOpenWalletConnect(false)}
        />
      )}{" "}
      {!props.createStake && (
        <Box className={classes.bannerBox}>
          <Typography variant="h2">Crypto Staking</Typography>
          {account ? (
            <Button
              // onClick={handleDesconnect}
              // onClick={() => user?.setIsLogin(false)}
              // ref={anchorRef.community}
              // aria-controls={open1.community ? "menu-list-grow" : undefined}
              aria-haspopup="true"
              className={classes.menuButtonBtn}
            >
              {sortAddress(account)}
            </Button>
          ) : (
            <Button
              aria-haspopup="true"
              onClick={() => setOpenWalletConnect(true)}
              className={classes.menuButtonBtn}
            >
              Connect wallet
            </Button>
          )}
        </Box>
      )}
      <Box
        className={classes.Padding_Top}
        mt={5}
        style={{ marginBottom: "33px" }}
      >
        <Box className={classes.walletPage} mb={2}>
          <Box>
            <Button
              className={tabview === "Satellite" ? "active" : " "}
              onClick={() => setTabView("Satellite")}
            >
              <Box className={classes.StakingBox}>
                <img
                  src="images/satellite.png"
                  alt="icon"
                  className="coinIcon"
                />
                <Box>
                  <Typography variant="h4">Satellite</Typography>
                  {account && (
                    <Typography variant="body2">
                      Balance: {satelliteBalance}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Button>
          </Box>
        </Box>

        <Grid item xs={12} md={12} lg={12}>
          <Box className="TabButtonsContant">
            {tabview === "Satellite" ? (
              <Satellite
                createStakeData={createStakeData}
                stakedValue={props.stakedValue}
                selectBurn1={props.selectBurn1}
                unStakeloader={unStakeloader}
                stakeloader={stakeloader}
                harvestInterval={harvestInterval}
                getStakingDataHandler={getStakingDataHandler}
                setsetLiteAmount={setsetLiteAmount}
                setLiteAmount={setLiteAmount}
                getUnStakingDataHandler={getUnStakingDataHandler}
                setsetLiteUnstakeAmount={setsetLiteUnstakeAmount}
                setLiteUnstakeAmount={setLiteUnstakeAmount}
                withdrawInterval={withdrawInterval}
                erwardperBlock={erwardperBlock}
                userStakedTokens={userStakedTokens}
                nextHarvestUntil={nextHarvestUntil}
                nextWithdrawUntil={nextWithdrawUntil}
                getClaimDataHandler={getClaimDataHandler}
                pendingRewards={pendingRewards}
                minStakeAmount={setalite && setalite[0]?.amount}
              />
            ) : (
              ""
            )}
          </Box>
        </Grid>
      </Box>
    </Box>
  );
}

export default Index;
