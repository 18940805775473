import { useRef, useEffect } from "react";
import styled from "styled-components";
import {
  Modal,
  Text,
  Button,
  Flex,
  ButtonMenu,
  Checkbox,
  BalanceInput,
  HelpIcon,
  ButtonMenuItem,
  useTooltip,
} from "@pancakeswap/uikit";
import RoiCalculatorFooter from "./RoiCalculatorFooter";
import RoiCard from "./RoiCard";
import useRoiCalculatorReducer, {
  CalculatorMode,
  EditingCurrency,
} from "./useRoiCalculatorReducer";
import AnimatedArrow from "./AnimatedArrow";

const ScrollableContainer = styled.div`
  padding: 24px;
  max-height: 500px;
  // overflow-y: auto;
  ${({ theme }) => theme.mediaQueries.sm} {
    max-height: none;
  }
`;

const FullWidthButtonMenu = styled(ButtonMenu)`
  width: 100%;

  & > button {
    width: 100%;
    color: #262831;
  }

  // :active {
  //   background: #262831;
  // }

  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

const Arow = styled.div`
  svg {
    color: #fff;
  }
`;

const RoiCalculatorModal = ({
  onDismiss,
  onBack,
  balanceOfBNBInUSD,
  earningTokenPrice,
  apr,
  displayApr,
  linkLabel,
  linkHref,
  stakingTokenBalance,
  stakingTokenSymbol,
  stakingTokenPrice,
  multiplier,
  initialValue,
  earningTokenSymbol = "CAKE",
  autoCompoundFrequency = 0,
  performanceFee = 0,
  isFarm = false,
}) => {
  const account = "0x2fecb3d1bb524313D318cA6371508c3bD94d2077";
  const balanceInputRef = useRef(null);

  const {
    state,
    setPrincipalFromUSDValue,
    setPrincipalFromTokenValue,
    setStakingDuration,
    toggleCompounding,
    toggleEditingCurrency,
    setCompoundingFrequency,
    setCalculatorMode,
    setTargetRoi,
  } = useRoiCalculatorReducer(
    stakingTokenPrice,
    earningTokenPrice,
    apr,
    autoCompoundFrequency,
    performanceFee
  );

  const {
    compounding,
    activeCompoundingIndex,
    stakingDuration,
    editingCurrency,
  } = state.controls;
  const { principalAsUSD, principalAsToken } = state.data;

  // Auto-focus input on opening modal
  useEffect(() => {
    if (balanceInputRef.current) {
      balanceInputRef.current.focus();
    }
  }, []);

  // If user comes to calculator from staking modal - initialize with whatever they put in there
  useEffect(() => {
    if (initialValue) {
      setPrincipalFromTokenValue(initialValue);
    }
  }, [initialValue, setPrincipalFromTokenValue]);

  const { targetRef, tooltip, tooltipVisible } = useTooltip(
    isFarm
      ? "“My Balance” here includes both LP Tokens in your wallet, and LP Tokens already staked in this farm."
      : "“My Balance” here includes both  in your wallet, and already staked in this pool.",
    { placement: "top-end", tooltipOffset: [20, 10] }
  );

  const onBalanceFocus = () => {
    setCalculatorMode(CalculatorMode.ROI_BASED_ON_PRINCIPAL);
  };

  const editingUnit =
    editingCurrency === EditingCurrency.TOKEN ? stakingTokenSymbol : "USD";
  const editingValue =
    editingCurrency === EditingCurrency.TOKEN
      ? principalAsToken
      : principalAsUSD;
  const conversionUnit =
    editingCurrency === EditingCurrency.TOKEN ? "USD" : stakingTokenSymbol;
  const conversionValue =
    editingCurrency === EditingCurrency.TOKEN
      ? principalAsUSD
      : principalAsToken;
  const onUserInput =
    editingCurrency === EditingCurrency.TOKEN
      ? setPrincipalFromTokenValue
      : setPrincipalFromUSDValue;

  return (
    <>
      <ScrollableContainer>
        <Flex flexDirection="column" mb="8px">
          <Text
            color="secondary"
            bold
            fontSize="12px"
            textTransform="uppercase"
            style={{ color: "#fff" }}
          >
            {linkLabel}
          </Text>
          <BalanceInput
            style={{ background: "#89d5f5" }}
            currencyValue={`${conversionValue} ${conversionUnit}`}
            innerRef={balanceInputRef}
            placeholder="0.00"
            value={editingValue}
            unit={editingUnit}
            onUserInput={onUserInput}
            switchEditingUnits={toggleEditingCurrency}
            onFocus={onBalanceFocus}
          />
          <Flex justifyContent="space-between" mt="8px">
            <Button
              scale="xs"
              p="4px 16px"
              width="68px"
              variant="tertiary"
              style={{ color: "#262831" }}
              onClick={() => setPrincipalFromUSDValue("100")}
            >
              $100
            </Button>
            <Button
              scale="xs"
              p="4px 16px"
              width="68px"
              variant="tertiary"
              style={{ color: "#262831" }}
              onClick={() => setPrincipalFromUSDValue("1000")}
            >
              $1000
            </Button>
            <Button
              disabled={
                // !stakingTokenBalance?.isFinite() ||
                stakingTokenBalance?.lte(0) ||
                !account ||
                balanceOfBNBInUSD == 0
              }
              scale="xs"
              p="4px 16px"
              width="128px"
              variant="tertiary"
              style={{ color: "#262831" }}
              onClick={() => setPrincipalFromUSDValue(balanceOfBNBInUSD)}
            >
              {"My Balance"}
            </Button>
            <span ref={targetRef}>
              <HelpIcon
                width="16px"
                height="16px"
                color="textSubtle"
                style={{
                  fill: "#fff",
                }}
                // style={{ color: "#fff" }}
              />
            </span>
            {tooltipVisible && tooltip}
          </Flex>
          <Text
            mt="24px"
            color="secondary"
            bold
            fontSize="12px"
            textTransform="uppercase"
            style={{ color: "#fff" }}
          >
            {"Staked for"}
          </Text>
          <FullWidthButtonMenu
            activeIndex={stakingDuration}
            onItemClick={setStakingDuration}
            scale="sm"
            variant="subtle"
          >
            <ButtonMenuItem variant="tertiary">{"1D"}</ButtonMenuItem>
            <ButtonMenuItem variant="tertiary">{"7D"}</ButtonMenuItem>
            <ButtonMenuItem variant="tertiary">{"30D"}</ButtonMenuItem>
            <ButtonMenuItem variant="tertiary">{"1Y"}</ButtonMenuItem>
            <ButtonMenuItem variant="tertiary">{"5Y"}</ButtonMenuItem>
          </FullWidthButtonMenu>
          {autoCompoundFrequency === 0 && (
            <>
              <Text
                mt="24px"
                color="secondary"
                bold
                fontSize="12px"
                textTransform="uppercase"
                style={{ color: "#fff" }}
              >
                {"Compounding every"}
              </Text>
              <Flex alignItems="center">
                <Flex flex="1">
                  <Checkbox
                    scale="sm"
                    checked={compounding}
                    onChange={toggleCompounding}
                    style={{ radius: "50%" }}
                  />
                </Flex>
                <Flex flex="6">
                  <FullWidthButtonMenu
                    disabled={!compounding}
                    activeIndex={activeCompoundingIndex}
                    onItemClick={setCompoundingFrequency}
                    scale="sm"
                    variant="subtle"
                  >
                    <ButtonMenuItem>{"1D"}</ButtonMenuItem>
                    <ButtonMenuItem>{"7D"}</ButtonMenuItem>
                    <ButtonMenuItem>{"14D"}</ButtonMenuItem>
                    <ButtonMenuItem>{"30D"}</ButtonMenuItem>
                  </FullWidthButtonMenu>
                </Flex>
              </Flex>
            </>
          )}
        </Flex>
        <Arow>
          <AnimatedArrow
            calculatorState={state}
            style={{
              fill: "#fff",
            }}
          />
        </Arow>
        <Flex>
          <RoiCard
            earningTokenSymbol={earningTokenSymbol}
            calculatorState={state}
            setTargetRoi={setTargetRoi}
            setCalculatorMode={setCalculatorMode}
          />
        </Flex>
      </ScrollableContainer>
      <RoiCalculatorFooter
        isFarm={isFarm}
        apr={apr}
        displayApr={displayApr}
        autoCompoundFrequency={autoCompoundFrequency}
        multiplier={multiplier}
        linkLabel={linkLabel}
        linkHref={linkHref}
        performanceFee={performanceFee}
      />
    </>
  );
};

export default RoiCalculatorModal;
