import React from "react";
import {
  Box,
  Container,
  makeStyles,
  Typography,
  Grid,
} from "@material-ui/core";
import TokenCard from "../../components/TokenCard";
const useStyles = makeStyles((theme) => ({
  bannerBox: {
    position: "relative",
    padding: " 100px 0px",
    overflow: "hidden",
    zIndex: " 1",
  },
  textbox: {
    "& h1": {
      fontSize: "50px",
      fontWeight: "bold",
      color: "white",
      marginTop: "-48px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "30px",
        marginTop: "-29px",
      },
      "& span": {
        color: "#392472",
      },
    },
    "& p": {
      color: "white", 
      fontSize: "14px", 
      maxWidth: "767px", 
      lineHeight: "25px",
    },
  
    "& small": {
      justifyContent: "center",
      "& div": {
        width: "50px",
        height: "1px",
        backgroundColor: "white",
        marginRight: "10px",
      },
      fontSize: "25px",
      color: "#392472",
      textShadow: "0px 0px 29px #392472",
      display: "flex",
      marginTop: "10px",
      alignItems: "center",
    },
  },
}));
const Token = [
  {
    icon: "images/10.png",
    name: "Features",
    discription:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown.",
  },
  {
    icon: "images/10.png",
    name: "Features",
    discription:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown.",
  },
  {
    icon: "images/10.png",
    name: "Features",
    discription:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown.",
  },

  {
    icon: "images/10.png",
    name: "Features",
    discription:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown.",
  },
  {
    icon: "images/10.png",
    name: "Features",
    discription:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown.",
  },
  {
    icon: "images/10.png",
    name: "Features",
    discription:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown.",
  },
  {
    icon: "images/10.png",
    name: "Features",
    discription:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown.",
  },
  {
    icon: "images/10.png",
    name: "Features",
    discription:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown.",
  },
];
export default function Dashboard(props) {
  var classes = useStyles();
  return (
    <>
      <Box className={classes.bannerBox}>
      <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={2}></Grid>
            <Grid item xs={12} sm={8}>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src="./images/Features.png" style={{ maxWidth: "70%" }} />
              </Box>
              <Box className={classes.textbox} align="center">
                <Typography variant="h1">Features</Typography>
              </Box>
              <Box className={classes.textbox} align="center" mt={2}>
                <Typography variant="body2">
                  A suit of tools were build to help you to create your own
                  tokens and launchpads in a fast, simple and cheap way, with no
                  prior code knowledge required and 100% decentralized!
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={2}></Grid>
          </Grid>
       
        {/* <Box className={classes.textbox} align="center">
          <small>
            {" "}
            <div></div> Services<div style={{ marginLeft: "10px" }}></div>
          </small>
        </Box> */}
        <Box mt={5}>
          <Grid container spacing={2} justifyContent="center">
            {Token.map((data, i) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={3}
                  key={i}
                  className="walletSet"
                >
                  <TokenCard data={data} type="card" index={i} />
                </Grid>
              );
            })}
          </Grid>
        </Box>
        </Container>
      </Box>
    </>
  );
}
