import React from "react";
import {
  Box,
  makeStyles,
  Button,
  Typography, TextField

} from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
const useStyles = makeStyles((theme) => ({
  mainBox: {
    borderRadius: "10px",
    minHeight: "250px",
    overflow: "hidden",
    position: "relative",
    padding: "25px",
    transition: "0.5s",
    textAlign: "left",
    background:
      "linear-gradient( 152.97deg, rgba(255, 255, 255, 0.08) 0%, rgba(232, 66, 76, 0.062) 100%)",
    transition: "0.5s",
    borderRadius: "0 30px 0 0",
    backdropFilter: "blur(42px)",
    "& p": {
      fontSize: "16px !important",
      color: "#b8b8b8",
      marginTop: "15px",
    },
    "& label": {
      fontSize: "15px !important",
      lineHeight: "24px",
      color: "#7e7f81 !important",
    },

  },
  connectCard: {
    display: " flex",
    alignItems: "center",
    borderRadius: "5px",
    marginTop: "7px",
    color: "#fff",
    textDecoration: "none",
    "& img": {
      width: "50px",
      height: "50px",
      borderRadius: "50%",
      marginRight: "15px",
    },

  },

}));



function Roadmap(props) {
  const classes = useStyles();


  return (
    <Box pt={2} pb={6}>
        <Box className={classes.mainBox}>
          <Box className={classes.connectCard} >
            <img src="images/PancakeSwap.png" alt="logo" />
            <Box>
              <Typography variant="h5">PancakeSwap V2 Locker</Typography>
            </Box>
          </Box>
          <Typography variant="body2">
            Use the locker to prove to investors you have locked liquidity. If you are not a token developer, this section is almost definitely not for you.
          </Typography>
          <Typography variant="body2">Our lockers offer</Typography>
          <ul class="tokenSpecsList">
            <li>
              <DoneIcon />
              Lock splitting
            </li>
            <li>
              <DoneIcon />
              Liquidity Migration
            </li>
            <li>
              <DoneIcon />
              Relocking
            </li>
            <li>
              <DoneIcon />
              Lock ownership transfer
            </li>
          </ul>
          <Box mt={2}>
            <label>Enter the PancakeSwap pair address youd like to lock liquidity for</label>
            <TextField
              id="outlined-basic"
              variant="outlined"
              placeholder="Pair Address"
              fullWidth
            />
            <small>e.g. Oxabgs1254....2411skhdiudbbu</small>
          </Box>
          <Box align="center" mt={3}>
            <Button variant="contained" color="primary">
              Connect Wallet
            </Button>
          </Box>
        </Box>
 
    </Box>
  );
}

export default Roadmap;
