import React, { useState } from "react";
import {
  Box,
  Container,
  makeStyles,
  Typography,
  Grid,
  Tab,
  FormControl,
  OutlinedInput,
  InputAdornment,
} from "@material-ui/core";
import clsx from "clsx";
import { BiChevronDown } from "react-icons/bi";
import { BiTransfer } from "react-icons/bi";
import { HiOutlineInformationCircle } from "react-icons/hi";
import Tooltip from "@material-ui/core/Tooltip";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import Switch from "@material-ui/core/Switch";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { BsSearch } from "react-icons/bs";
import { withStyles, useTheme } from "@material-ui/core/styles";

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
const useStyles = makeStyles((theme) => ({
  mainBox: {
    paddingBottom: "50px",
  },
  heading: {
    textAlign: "center",
    "& h1": {
      // fontFamily: "Montserrat",
      fontWeight: "600",
      marginBottom: "12px",
      fontSize: "50px",
      lineHeight: "76px",
      textAlign: "center",
      color: "#10233a",
      [theme.breakpoints.down("xs")]: {
        fontSize: "30px",
        lineHeight: "40px",
        marginBottom: "0px",
      },
    },
  },
  swapBox: {
    border: "1px solid #16abd31f",
    padding: "15px",
    background: "#fff",
    transition: " 0.5s",
    borderRadius: "10px",
    "& label": {
      color: "#10233a",
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("xs")]: {
        fontSize: "12px",
      },
      "& button": {
        minWidth: "auto",
        padding: 0,
        fontSize: "18px",
        color: "#89d5f5",
        marginLeft: "5px",
      },
    },
    "& small": {
      color: "#b2b2b2",
      fontSize: "14px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "12px",
      },
      "& strong": {
        color: "#fff",
      },
    },
    "& .paybox": {
      padding: "10px",
      backgroundColor: "#89d5f5",
      borderRadius: "5px",
      marginBottom: "10px",
      "& input": {
        backgroundColor: " transparent",
        border: "none",
        color: "#10233a",
        fontSize: "18px",
        fontWeight: 800,
        textAlign: "right",
        width: "100%",
        "&:focus-visible": {
          outline: "none !important",
        },
      },
      "& p": {
        fontSize: "14px",
        color: "#10233a",
        [theme.breakpoints.down("xs")]: {
          fontSize: "12px",
        },
      },
      "& h6": {
        display: "flex",
        alignItems: "center",
        fontWeight: 800,
        color: "#10233a",
        [theme.breakpoints.down("xs")]: {
          fontSize: "14px",
        },
        "& img": {
          width: "25px",
          marginRight: "6px",
          [theme.breakpoints.down("xs")]: {
            width: "18px",
          },
        },
      },
    },
    "& .transfer": {
      width: " 100%",
      maxWidth: "30px",
      height: "30px",
      borderRadius: "50%",
      backgroundColor: "#071426",
      color: " #fff",
      fontSize: "15px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      margin: "10px auto",
      transform: "rotate(90deg)",
      cursor: "pointer",
    },
  },
  buttonright: {
    fontSize: "13px ",
    marginBottom: "20px",
    border: "2px solid transparent",
    fontWeight: 600,
    height: "44px ",
    background: "linear-gradient(272.26deg, #0DD0FF 36.78%, #05A4CA 86.13%)",
    color: "#fff",
    minWidth: "125px ",
    width: "100%",
    borderRadius: "10px",
    boxShadow: "inset 0px 0px 2px rgba(0, 0, 0, 0.4)",
    // boxShadow: "none ",
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      height: "45px ",
      minWidth: "120px ",
    },
    "&:hover": {
      color: "#fff",
      background: "#0c2739",

      border: "2px solid transparent",
      boxSizing: "border-box",
      backgroundClip: "padding-box, border-box",
      backgroundImage:
        "linear-gradient(#0c2739, #0c2739), linear-gradient(#2599fa, #1dcbfa)",
      backgroundOrigin: "padding-box, border-box",
    },
  },
  tokenBox: {
    height: "300px",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "5px",
    },
  },
  solutionBox: {
    backgroundColor: "#071426",
    padding: "10px",
    display: "flex",
    marginBottom: "6px",
    alignItems: "center",
    borderRadius: " 5px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#89d5f5",
      "& h4": {
        color: "#071426",
      },
    },
    "& h4": {
      fontSize: "16px",
      color: "#ffffff",
      fontWeight: " 800",
      [theme.breakpoints.down("xs")]: {
        fontSize: "14px",
      },
    },
    "& p": {
      [theme.breakpoints.down("xs")]: {
        fontSize: "12px",
      },
    },
    "& figure": {
      margin: "0",
      width: "30px",
      marginRight: "20px",
      [theme.breakpoints.down("xs")]: {
        width: "25px",
        marginRight: "15px",
      },
      "& img": {
        width: "auto",
        maxWidth: "100%",
      },
    },
  },
}));
const SolutionCard = [
  {
    image: "images/Layer1.png",
    heading: "Satellite",
    discription: "Binance Pegged Satellite",
  },
  {
    image: "images/BNB.png",
    heading: "BNB",
    discription: "Binance Pegged BNB",
  },
  {
    image: "images/Layer1.png",
    heading: "Satellite",
    discription: "Binance Pegged Satellite",
  },
  {
    image: "images/BNB.png",
    heading: "BNB",
    discription: "Binance Pegged BNB",
  },
  {
    image: "images/Layer1.png",
    heading: "Satellite",
    discription: "Binance Pegged Satellite",
  },
  {
    image: "images/BNB.png",
    heading: "BNB",
    discription: "Binance Pegged BNB",
  },
  {
    image: "images/Layer1.png",
    heading: "Satellite",
    discription: "Binance Pegged Satellite",
  },
  {
    image: "images/BNB.png",
    heading: "BNB",
    discription: "Binance Pegged BNB",
  },
  {
    image: "images/Layer1.png",
    heading: "Satellite",
    discription: "Binance Pegged Satellite",
  },
  {
    image: "images/BNB.png",
    heading: "BNB",
    discription: "Binance Pegged BNB",
  },
];
export default function Dashboard(props) {
  var classes = useStyles();
  const theme = useTheme();
  const [alignment, setAlignment] = React.useState("left");

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const [checked, setChecked] = React.useState(false);

  const toggleChecked = () => {
    setChecked((prev) => !prev);
  };

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    console.log("sdftgyhujikol", index);
    setValue(index);
  };
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Box className={classes.mainBox}>
      <Box className={classes.root} mt={5} textAlign="center">
        <Grid container style={{ display: "flex", justifyContent: "center" }}>
          <Grid item xl={4} lg={6} sm={12} xs={12}>
            <AppBar position="center" color="secandory">
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab label="BNB" {...a11yProps(0)} />
                <Tab label="ETH" {...a11yProps(1)} />
                <Tab label="MATIC" {...a11yProps(1)} />
              </Tabs>
            </AppBar>
          </Grid>
        </Grid>

        <Box className={classes.heading}>
          <Typography variant="h1">Swap</Typography>
        </Box>
        {/* <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}
        > */}
        {/* <TabPanel value={value} index={0} dir={theme.direction}> */}
        {value == 0 && (
          <Box className={classes.heading}>
            {/* <Typography variant="h1">Swap</Typography> */}
            <iframe
              src="https://poocoin.app/embed-swap?outputCurrency=0x721FD0E5759Ddf7cD4709DA95C19FA18220678dB"
              width="660"
              height="650"
              frameBorder="0"
            ></iframe>
          </Box>
        )}
        {/* </TabPanel> */}
        {/* <TabPanel value={value} index={1} dir={theme.direction}> */}
        {value == 1 && (
          <Box className={classes.heading}>
            {/* <Typography variant="h1">Swap</Typography> */}
            <iframe
              src="https://app.uniswap.org/#/swap?exactField=input&exactAmount=10&inputCurrency=0x6b175474e89094c44da98b954eedeac495271d0f"
              height="660px"
              width="100%"
              style={{
                border: "0",
                margin: " 0 auto",
                marginBottom: ".5rem",
                display: "block",
                borderRadius: "10px",
                maxWidth: "960px",
                minWidth: "300px",
              }}
            />
          </Box>
        )}
        {/* </TabPanel> */}
        {/* <TabPanel value={value} index={2} dir={theme.direction}> */}
        {value == 2 && (
          <Box className={classes.heading}>
            {/* <Typography variant="h1">Swap</Typography> */}
            <iframe
              src="https://quickswap.exchange/#/swap?outputCurrency=0x831753dd7087cac61ab5644b308642cc1c33dc13"
              width="660"
              height="650"
              frameBorder="0"
            ></iframe>
          </Box>
        )}
        {/* </TabPanel> */}
        {/* </SwipeableViews> */}
      </Box>

      {/* <Container maxWidth="sm"> */}
      {/* <Box className={classes.swapBox} mt={5}>
          <label> Pay</label>
          <Box className="paybox" mt={1}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography
                  variant="h6"
                  style={{ cursor: "pointer" }}
                  onClick={handleClickOpen}
                >
                  {" "}
                  <img src="images/Layer1.png" alt="token" /> Satellite{" "}
                  <BiChevronDown />
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <input type="number" placeholder="0.21" />
              </Grid>
              <Grid item xs={12} align="right">
                <Typography variant="body2">~$1.2547</Typography>
              </Grid>
            </Grid>
          </Box>
          <Box mt={1}>
            <ToggleButtonGroup
              value={alignment}
              exclusive
              onChange={handleAlignment}
              aria-label="text alignment"
            >
              <ToggleButton value="left" aria-label="left aligned">
                25%
              </ToggleButton>
              <ToggleButton value="center" aria-label="centered">
                50%
              </ToggleButton>
              <ToggleButton value="right" aria-label="right aligned">
                75%
              </ToggleButton>
              <ToggleButton value="justify" aria-label="justified">
                100%
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <Box mt={2} className="transfer">
            <BiTransfer />
          </Box>
          <label> Receive</label>
          <Box className="paybox" mt={1} mb={2}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography
                  variant="h6"
                  style={{ cursor: "pointer" }}
                  onClick={handleClickOpen}
                >
                  {" "}
                  <img src="images/BNB.png" alt="token" /> BNB <BiChevronDown />
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <input type="number" placeholder="0.21" />
              </Grid>
              <Grid item xs={12} align="right">
                <Typography variant="body2">~$1.2547</Typography>
              </Grid>
            </Grid>
          </Box>
          <button
            className={classes.buttonright}
            variant="contained"
            size="large"
            style={{ color: "#fff !important" }}
          >
            Swap
          </button>
          <Box mt={2}>
            <Grid container spacing={1}>
              <Grid item xs={5}>
                <label>
                  Minimum Received{" "}
                  <Tooltip
                    title="Your transaction will revert if there is a large, unfavorable price movement before it is confirmed."
                    placement="top"
                  >
                    <Button>
                      {" "}
                      <HiOutlineInformationCircle />
                    </Button>
                  </Tooltip>
                </label>
              </Grid>
              <Grid item xs={7} align="right">
                <small>
                  {" "}
                  <strong>0.98781</strong> BUSD
                </small>{" "}
                <br />
                <small>~$0.98781</small>
              </Grid>
              <Grid item xs={5}>
                <label>
                  Transaction Cost{" "}
                  <Tooltip
                    title="The shown cost is an approximate fee required to successfully submit the transaction."
                    placement="top"
                  >
                    <Button>
                      {" "}
                      <HiOutlineInformationCircle />
                    </Button>
                  </Tooltip>
                </label>
              </Grid>
              <Grid item xs={7} align="right">
                <small>
                  {" "}
                  <strong>0.98781</strong> BNB
                </small>{" "}
                <br />
                <small>~$0.98781</small>
              </Grid>
              <Grid item xs={5}>
                <label>
                  Gas Optimization{" "}
                  <Tooltip
                    title="Gas Optimization Mode helps minimize the transaction fees to save cost and enable more chances to maximize trading profit."
                    placement="top"
                  >
                    <Button>
                      {" "}
                      <HiOutlineInformationCircle />
                    </Button>
                  </Tooltip>
                </label>
              </Grid>
              <Grid item xs={7} align="right">
                <FormControlLabel
                  style={{ justifyContent: "end" }}
                  control={
                    <Switch
                      checked={checked}
                      color="Primary"
                      onChange={toggleChecked}
                    />
                  }
                />
              </Grid>
            </Grid>
          </Box>
        </Box> */}
      {/* </Container> */}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableScrollLock={true}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title">{"Select Token"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Box mb={2}>
              <FormControl
                className={clsx(classes.margin, classes.textField)}
                variant="outlined"
                fullWidth
              >
                <OutlinedInput
                  id="outlined-adornment-weight"
                  placeholder="Search Token"
                  endAdornment={
                    <InputAdornment position="end">
                      <BsSearch />
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Box>
            <label style={{ color: "#fff" }}>Token(s)</label>
            <Box className={classes.tokenBox} mt={2}>
              {SolutionCard.map((data, i) => {
                return (
                  <Box
                    key={i}
                    className={classes.solutionBox}
                    data={data}
                    type="card"
                    index={i}
                    onClick={handleClose}
                  >
                    <figure>
                      {" "}
                      <img src={data.image} alt="icons" />
                    </figure>
                    <Box>
                      <Typography variant="h4"> {data.heading}</Typography>
                      <Typography variant="body2">
                        {" "}
                        {data.discription}
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
