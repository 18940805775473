import React, { useContext } from "react";
import {
  Box,
  makeStyles,
  Button,
  Grid,
  List,
  Typography,
  ListItem,
  Slider,
  Container,
} from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  mainBox: {
    backgroundColor: " #fff",
    padding: "20px",
    borderRadius: "20px",
    overflow: "hidden",
    position: "relative",
    boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
    marginBottom: "20px",
    "& .tag": {
      backgroundColor: "#f48e0f",
      padding: "10px",
      textAlign: "center",
      fontSize: "20px",
      color: " #fff",
      position: "absolute",
      width: "200px",
      transform: "rotate(-35deg)",
      top: "10px",
      left: "-55px",
    },
    "& .heading": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingBottom: "10px",
      borderBottom: "1px solid #ccc",
      marginBottom: "30px",
      [theme.breakpoints.down("xs")]: {
        display: "block",
      },
    },
    "& h2": {
      color: "#0e2137",
    },
    "& h5": {
      fontSize: "15px",
      color: "#7a7979",
    },
    "& p": {
      fontSize: "14px",
    },
    "& h6": {
      fontSize: "20px",
      marginTop: "20px",
      color: "#0e2137",
    },
    "& ul": {
      marginTop: "10px",
      paddingLeft: "20px",
      fontSize: "14px",
      "& li": {
        position: "relative",
        display: "inline-block",
        "&::after": {
          height: "7px",
          width: " 7px",
          backgroundColor: "#0e2137",
          borderRadius: "50%",
          content: "''",
          position: "absolute",
          left: "-15px",
          top: "7px",
        },
      },
    },
    "& .stakingNumber": {
      borderBottom: "1px solid #ccc",
      paddingBottom: "10px",
      "& label": {
        fontSize: " 14px",
        fontWeight: 500,
      },
      "& input": {
        height: "30px",
        width: "100%",
        border: "1px solid #ccc",
        borderRadius: "4px",
        "&:focus-visible": {
          outline: "none",
          border: "1px solid #ccc",
        },
      },
    },
    "& .coinIcon": {
      "& img": {
        width: "100px",
        height: "100px",
        borderRadius: "50%",
        objectFit: "cover",
      },
    },
    "& .footerBox": {
      justifyContent: "flex-end",
      display: "flex",
      alignItems: "center",
      marginTop: "20px",
      "& h4": {
        marginRight: "30px",
        fontWeight: 600,
        color: "#0e2137",
        "& span": {
          display: "block",
          fontWeight: 400,
          fontSize: "15px",
        },
      },
    },
    "& .imgImage": {
      position: "absolute",
      bottom: "-100px",
      left: "-100px",
      opacity: "0.2",
    },
  },
}));
function Index({
  getStakingDataHandler,
  harvestInterval,
  setLiteAmount,
  setsetLiteAmount,
  setLiteUnstakeAmount,
  setsetLiteUnstakeAmount,
  getUnStakingDataHandler,
  stakeloader,
  unStakeloader,
  withdrawInterval,
  erwardperBlock,
  userStakedTokens,
  nextHarvestUntil,
  nextWithdrawUntil,
  getClaimDataHandler,
  pendingRewards,
  minStakeAmount,
}) {
  const classes = useStyles();

  //   const { account, library } = useWeb3React();
  //   const user = useContext(UserContext);
  return (
    <Box mt={5} style={{ paddingBottom: "50px" }}>
      <Container>
        <Box className={classes.mainBox}>
          <img src="imagess/imagesblack.svg" className="imgImage" alt="" />
          <Box className="tag">New</Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <Box align="center" className="coinIcon">
                <img src="images/Layer1.png" alt="icon" />
              </Box>
            </Grid>
            <Grid item xs={12} sm={9}>
              <Box className="heading">
                <Typography variant="h2">Satellite Token</Typography>
                {/* <Typography variant="h5">ROI of 13.88%*.</Typography> */}
              </Box>
              {/* <Typography variant='body2'>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </Typography> */}
              <Typography variant="h6">Staking information</Typography>
              <List>
                {/* <ListItem>
                Minimum <strong>1000 CAPS</strong>
              </ListItem> */}
                {/* <ListItem>
                Interest credited (in <strong>CAPS) every minute</strong>
              </ListItem> */}
                <ListItem>
                  Tokens locked for{" "}
                  <strong>{Number(harvestInterval) / (60 * 60 * 24)}</strong>{" "}
                  days for claim
                </ListItem>
                <ListItem>
                  Tokens locked for{" "}
                  <strong>{Number(withdrawInterval) / (60 * 60 * 24)}</strong>{" "}
                  days for withdraw
                </ListItem>
                {/* <ListItem>Duration of contract: indefinite</ListItem> */}
                <ListItem>
                  Rewards per block <strong>{erwardperBlock} </strong>
                </ListItem>
                <ListItem>
                  Minimum stake amount <strong>{minStakeAmount} </strong>
                </ListItem>
              </List>
              <Box mt={3}>
                <Slider aria-label="Temperature" defaultValue={100} disabled />
              </Box>
              <Box className="stakingNumber">
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={7} sm={9} align="right">
                    <lable>Number of tokens:</lable>
                  </Grid>
                  <Grid item xs={5} sm={3}>
                    <input
                      type="number"
                      placeholder="20"
                      value={setLiteAmount}
                      // onChange={(e) => setsetLiteAmount(e.target.value)}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box align="right" className="footerBox">
                {/* {account && (
                <Typography variant='h4'>
                  Your Staked <span>{userStakedTokens}</span>
                </Typography>
              )} */}
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  // onClick={getStakingDataHandler}
                  // disabled={
                  //   !account ||
                  //   !user?.isLogin ||
                  //   !user?.isLogin1 ||
                  //   unStakeloader ||
                  //   stakeloader
                  // }
                >
                  Stake
                </Button>
                &nbsp;
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box className={classes.mainBox} mt={3} mb={5}>
          <img src="imagess/imagesblack.svg" className="imgImage" />

          <Box className="stakingNumber">
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={7} sm={9} align="right">
                <lable>Number of tokens:</lable>
              </Grid>
              <Grid item xs={5} sm={3}>
                <input
                  type="number"
                  placeholder="20"
                  value={setLiteUnstakeAmount}
                  // onChange={(e) => setsetLiteUnstakeAmount(e.target.value)}
                />
              </Grid>
            </Grid>
          </Box>
          <Box align="right" className="footerBox">
            {/* {nextHarvestUntil != 0 && (
            <Typography variant='h4'>
              Next Withdraw Until{" "}
              <span>
                {moment(new Date(Number(nextWithdrawUntil) * 1000)).format(
                  "DD/MM/YYYY hh:mm"
                )}
              </span>
            </Typography>
          )} */}
            <Button
              variant="contained"
              color="primary"
              size="large"
              // onClick={getUnStakingDataHandler}
              // disabled={
              //   !account ||
              //   !user?.isLogin ||
              //   !user?.isLogin1 ||
              //   unStakeloader ||
              //   stakeloader
              // }

              //   component={Link}
              //   to="/app/verifyed-presell"
            >
              UnStake
            </Button>
          </Box>
          <Box align="right" className="footerBox">
            {/* {nextHarvestUntil != 0 && (
            <Typography variant='h4'>
              Next Harvest Until{" "}
              <span>
                {moment(new Date(Number(nextHarvestUntil) * 1000)).format(
                  "DD/MM/YYYY hh:mm"
                )}
              </span>
            </Typography>
          )} */}
            {/* {pendingRewards && (
            <Typography variant='h4'>
              Rewards <span>{pendingRewards}</span>
            </Typography>
          )} */}
            <Button
              variant="contained"
              color="primary"
              size="large"

              //   component={Link}
              //   to="/app/verifyed-presell"
            >
              Claim
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default Index;
