import React from "react";
import {
  Box,
  makeStyles,
  Typography,
  Grid,
  Button,
  Accordion,
  Container,
  CircularProgress,
} from "@material-ui/core";
import Web3 from "web3";
import { IoMdRefresh } from "react-icons/io";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
// import BasicSelect from "./BasicSelect";
import { Link } from "react-router-dom";
// import BasicSelect from "../../pages/dashboard/BasicSelect";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { BsCheckCircle } from "react-icons/bs";
import { AiOutlineArrowDown } from "react-icons/ai";
import { BsFillPencilFill } from "react-icons/bs";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { BiTimer } from "react-icons/bi";
import { AiOutlineCalculator } from "react-icons/ai";
import { MdContentCopy } from "react-icons/md";
import { StatelliteStakingAddress, getNetworkDetails } from "../../constants";
// import Drag from "./Drag";
import { withStyles } from "@material-ui/core/styles";
// import Button from '@material-ui/core/Button';
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import StakingCards from "../../components/StakingCards";
import axios from "axios";
import apiConfig from "../../config/ApiConfig";
import IERC20ABI from "../../abis/IERC20ABI.json";
import StatelliteStakingABI from "../../abis/StatelliteStakingABI.json";
import StatelliteStakingFixedABI from "../../abis/StatelliteStakingFixedABI.json";
import StatelliteStakingDynamicABI from "../../abis/StatelliteStakingDynamicABI.json";
import StatelliteStakingDividentABI from "../../abis/StatelliteStakingDividentABI.json";
import { useWeb3React } from "@web3-react/core";
import { getContract, getWeb3Obj, getWeb3ContractObject } from "../../utils";
import { toast } from "react-toastify";
import { UserContext } from "../../context/User";
import LoadingButton from "../../components/Loaders/ButtonCircularProgress";
import NoDataFound from "../../components/NoDataFound/NoDataFound";

const Datamap = [
  {
    recent1: " Recent Staking pools",
    lorem1:
      "  Lorem Ipsum is simply dummy text of the printing and   typesetting industry. Lorem Ipsum has been the industry's  standard dummy text ever since the 1500s, when an unknown    printer took a galley of type and scrambled it to make a type  specimen book.",
    layer1: "images/Layer1.png",
    Earn: "    Earn Satellite",
    Stake: "  Stake Satellite",
    Refl: "   Refl. BUSD",
    Satellite: " Satellite Earned",
    h0: " 0.0",
    USD: "   0 USD",
    APR: "  APR",
    h15: "  15.58%",
    Total: "   Total staked",
    Satrllite: " 128,471,558 Satellite",
    Ends: "  Ends in",
    blocks: "  7,290,724 blocks",
    Details: "Details",
    h22: "   2.00 %",
    ConnectWallet: "Connect Wallet",
    STARTSTAKING: "STARTSTAKING",
    WITHDRAWFEE: "WITHDRAW FEE",
    DEPOSITFEE: "DEPOSIT FEE",
    BUSD: "BUSD",
    REFLECTED: "REFLECTED",
    EARNED: "EARNED",
    Satellite: "Satellite",
    SeeTokenInfo: "See Token Info",
    ViewProjectSite: "View Project Site",
    Manual: "Manual",
  },
  {
    // recent1: " Recent Staking pools",
    // lorem1:
    //   "  Lorem Ipsum is simply dummy text of the printing and   typesetting industry. Lorem Ipsum has been the industry's  standard dummy text ever since the 1500s, when an unknown    printer took a galley of type and scrambled it to make a type  specimen book.",
    layer1: "images/Layer1.png",
    Earn: "    Earn Satellite",
    Stake: "  Stake Satellite",
    Refl: "   Refl. BUSD",
    Satellite: " Satellite Earned",
    h0: " 0.0",
    USD: "   0 USD",
    APR: "  APR",
    h15: "  15.58%",
    Total: "   Total staked",
    Satrllite: " 128,471,558 Satellite",
    Ends: "  Ends in",
    blocks: "  7,290,724 blocks",
    Details: "Details",
    h22: "   2.00 %",
    ConnectWallet: "Connect Wallet",
    STARTSTAKING: "STARTSTAKING",
    WITHDRAWFEE: "WITHDRAW FEE",
    DEPOSITFEE: "DEPOSIT FEE",
    BUSD: "BUSD",
    REFLECTED: "REFLECTED",
    EARNED: "EARNED",
    Satellite: "Satellite",
    SeeTokenInfo: "See Token Info",
    ViewProjectSite: "View Project Site",
    Manual: "Manual",
  },
  {
    // recent1: " Recent Staking pools",
    // lorem1:
    //   "  Lorem Ipsum is simply dummy text of the printing and   typesetting industry. Lorem Ipsum has been the industry's  standard dummy text ever since the 1500s, when an unknown    printer took a galley of type and scrambled it to make a type  specimen book.",
    layer1: "images/Layer1.png",
    Earn: "    Earn Satellite",
    Stake: "  Stake Satellite",
    Refl: "   Refl. BUSD",
    Satellite: " Satellite Earned",
    h0: " 0.0",
    USD: "   0 USD",
    APR: "  APR",
    h15: "  15.58%",
    Total: "   Total staked",
    Satrllite: " 128,471,558 Satellite",
    Ends: "  Ends in",
    blocks: "  7,290,724 blocks",
    Details: "Details",
    h22: "   2.00 %",
    ConnectWallet: "Connect Wallet",
    STARTSTAKING: "STARTSTAKING",
    WITHDRAWFEE: "WITHDRAW FEE",
    DEPOSITFEE: "DEPOSIT FEE",
    BUSD: "BUSD",
    REFLECTED: "REFLECTED",
    EARNED: "EARNED",
    Satellite: "Satellite",
    SeeTokenInfo: "See Token Info",
    ViewProjectSite: "View Project Site",
    Manual: "Manual",
  },
  {
    // recent1: " Recent Staking pools",
    // lorem1:
    //   "  Lorem Ipsum is simply dummy text of the printing and   typesetting industry. Lorem Ipsum has been the industry's  standard dummy text ever since the 1500s, when an unknown    printer took a galley of type and scrambled it to make a type  specimen book.",
    layer1: "images/Layer1.png",
    Earn: "    Earn Satellite",
    Stake: "  Stake Satellite",
    Refl: "   Refl. BUSD",
    Satellite: " Satellite Earned",
    h0: " 0.0",
    USD: "   0 USD",
    APR: "  APR",
    h15: "  15.58%",
    Total: "   Total staked",
    Satrllite: " 128,471,558 Satellite",
    Ends: "  Ends in",
    blocks: "  7,290,724 blocks",
    Details: "Details",
    h22: "   2.00 %",
    ConnectWallet: "Connect Wallet",
    STARTSTAKING: "STARTSTAKING",
    WITHDRAWFEE: "WITHDRAW FEE",
    DEPOSITFEE: "DEPOSIT FEE",
    BUSD: "BUSD",
    REFLECTED: "REFLECTED",
    EARNED: "EARNED",
    Satellite: "Satellite",
    SeeTokenInfo: "See Token Info",
    ViewProjectSite: "View Project Site",
    Manual: "Manual",
  },
];
const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const useStyles = makeStyles((theme) => ({
  Dialogbox: {
    color: "white",
    maxWidth: "100%",
    "& h3": {
      // padding: "12px",
      marginTop: "14px",
      color: "white",
    },
  },
  box1: {
    // marginTop: "-40px",
    // paddingBottom: "50px",
    // "@media(max-width:910px)": {
    //   marginTop: "4rem",
    // },

    "& h1": {
      textAlign: "center",
      fontFamily: "'Lexend Deca'",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "65px",
      color: "#0E2137",
      lineHeight: "55px",
      "@media(max-width:415px)": {
        marginTop: "10rem",
        fontSize: "40px",
      },
      "@media(max-width:375px)": {
        fontSize: "35px",
      },
    },
  },
  root1: {
    width: "100%",
  },
  accordion: {
    marginTop: "20px",
    background: "#E2F6FF",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "10px",
  },
  heading: {
    fontFamily: "'Lexend Deca'",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "21px",
    color: "#0E2137",
  },
  heading0: {
    fontFamily: "'Lexend Deca'",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "22px",
    lineHeight: "21px",
    color: "#0E2137",
  },
  heading1: {
    fontFamily: "'Lexend Deca'",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "24px",
    color: "#5F5F5F",
  },
  box22: {
    display: "flex",
    justifyContent: "end",
    marginTop: "25px",
  },
  btn: {
    background: " #D6F3FF ",
    borderRadius: "5px",
    "@media(max-width:960px)  ": {
      marginTop: "10px",
    },
  },
  btn1b: {
    marginTop: "10px",
    background: " #D6F3FF ",
    background: " #D6F3FF ",
    borderRadius: "5px",
  },
  heading2: {
    fontFamily: "'Lexend Deca'",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "24px",
    color: "#31B0E3",
  },
  heading3: {
    marginTop: "10px",
    fontFamily: "'Lexend Deca'",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "24px",
    color: "#31B0E3",
  },
  heading4: {
    fontFamily: "'Lexend Deca'",
    // fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "21px",
    color: "#0E2137",
    marginTop: "10px",
  },
  btn2: {
    color: "white",
    background: "#0E2137",
    borderRadius: "10px",
    borderRadius: "10px",
    padding: "15px",
    paddingLeft: "20px",
    paddingRigth: "20px",
    "&:hover": {
      background: "#3cb4e4",
    },
  },
  box2: {
    marginTop: "20px",
    display: "flex",
    // justifyContent: "end",
  },
  btnDetails: {
    color: "#31B0E3",
    fontFamily: "'Lexend Deca'",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "24px",
    "@media(max-width:960px)": {
      display: "none  ",
    },
  },

  btn3: {
    background: "#0E2137",
    borderRadius: "10px",
    width: "21rem",
    height: "50px",
    marginTop: "34px",
    color: " #FFFFFF",
    fontWeight: "600",
    "&:hover": {
      background: "#3cb4e4",
    },
    "@media(max-width:1280px)": {
      width: "11rem",
    },
    "@media(max-width:960px)": {
      display: "none",
    },
  },
  btn4: {
    "@media(min-width:960px)": {
      display: "none",
    },

    // "@media(max-width:960px)": {

    //   width: "200%",
    //   background: "#0E2137",
    //   borderRadius: "10px",
    //   // width: "21rem",
    //   height: "50px",
    //   marginTop: "34px",
    //   color: " #FFFFFF",
    //   fontWeight: "600",
    // },
  },
  radiusbox: {
    boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
    borderRadius: "20px",
    // border: "1px solid black",
    backgroundColor: " #89d5f5",
    padding: "10px",
    textAlign: "end",
    color: "white",
    "& input": {
      backgroundColor: "transparent",
      border: "none",
      textAlign: "right",
      color: "#0c2739",
      "&:focus-visible": {
        outline: "none",
      },
    },
  },
  Dcontent: {
    padding: "34px",
    marginTop: "6px",
    "& h6": {
      color: "white",
      fontSize: "13px",
    },
  },
  box0: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "50px",
    "& h5": {
      marginTop: "30px",
      maxWidth: "56rem",
      fontFamily: "'Lexend Deca'",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "24px",
      textAlign: "center",
      color: " #626262",
    },
  },
  textbtn: {
    fontFamily: "'Lexend Deca'",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "13px",
    lineHeight: "20px",
    color: "#31B0E3",
  },
  btn41: {
    display: "none",
    "@media(max-width:960px)": {
      display: "initial",
      width: "200%",
      background: "#0E2137",
      borderRadius: "10px",
      // width: "21rem",
      height: "50px",
      marginTop: "34px",
      color: " #FFFFFF",
      fontWeight: "600",
    },
  },
  threebtn: {
    marginTop: "10px",
    display: "flex",
  },
  btnb: {
    // background: " #D6F3FF ",
    borderRadius: "5px",
    "@media(max-width:960px)  ": {
      marginTop: "10px",
    },
  },
  btn1b: {
    // marginTop: "10px",
    // background: " #D6F3FF ",
    // background: " #D6F3FF ",
    borderRadius: "5px",
  },
  boxday2: {
    marginTop: "20px",
  },
  lstbox: {
    marginTop: "30px",
    padding: "20px",
    marginTop: "30px",
    borderRadius: "30px",
    boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
    backgroundColor: "#89d5f5",
    // border: "1px solid black",
    "& input": {
      backgroundColor: "transparent",
      border: "none",
      textAlign: "left",
      color: "#0c2739",
      "&:focus-visible": {
        outline: "none",
      },
    },
    "& h6": {
      fontSize: "13px",
      color: "#0c2739",
    },
    "& h5": {
      fontSize: "10px",
      color: "#0c2739",
      marginTop: "5px",
    },
    "& h2": {
      fontSize: "25px",
      fontWeight: "500",
      color: "#0c2739",
    },
  },
  detailsbox: {
    display: "flex",
    justifyContent: "center",
    marginTop: "30px",
  },
  btngroup5: {
    paddingBottom: "5px",
    paddingTop: "5px",
    // border: "1px solid black",
    borderRadius: "30px",
    color: "white",
    display: "flex",
    justifyContent: "space-between",
  },
}));
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
export default function Recent({ home, status, ownerCheck }) {
  const [alignment, setAlignment] = React.useState("left");
  const { chainId, library, account } = useWeb3React();
  const user = React.useContext(UserContext);
  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  // console.log("ownerCheck", ownerCheck);
  const [open, setOpen] = React.useState(false);
  const [loaderStart, setloaderStart] = React.useState(false);
  const [buttonLoader, setbuttonLoader] = React.useState(false);
  const [arrayData, setArrayData] = React.useState("");
  const [arrayData1, setArrayData1] = React.useState("");
  const [stakingList, setStakingList] = React.useState([]);
  const [enableButtonLoader, setEnableButtonLoader] = React.useState(false);
  const [harvestLoader, setharvestLoader] = React.useState(false);
  const [errorText, setErrorText] = React.useState(false);

  // const [pendingRewards, setPendingRewards] = useState();
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();

  React.useEffect(() => {
    setImagesTo();
  }, []);

  // React.useEffect(() => {
  //   setImagesTo();
  // }, [account]);
  const setImagesTo = async () => {
    setloaderStart(true);
    try {
      const res = await axios({
        method: "GET",
        url: apiConfig.listStacking,
      });
      // console.log("res", res);
      if (res.data.statusCode === 200) {
        // user.setDashboardData(res.data.result);
        setStakingList(res.data.result);
        setloaderStart(false);
        // console.log(res);
      }
    } catch (error) {
      console.log("EROR", error);
      setloaderStart(false);
    }
  };
  const default_RPC_URL_BNB_BNB = "https://bsc-dataseed1.ninicoin.io";
  const default_RPC_URL_ETH = "https://mainnet.infura.io/v3/";
  const default_RPC_URL_MATIC = "https://polygon-rpc.com/";
  const httpProvider = new Web3.providers.HttpProvider(default_RPC_URL_BNB_BNB);
  const httpProvider_ETH = new Web3.providers.HttpProvider(default_RPC_URL_ETH);
  const httpProvider_MATIC = new Web3.providers.HttpProvider(
    default_RPC_URL_MATIC
  );

  const web3_BNB = new Web3(httpProvider);
  const web3_ETH = new Web3(httpProvider_ETH);
  const web3_MATIC = new Web3(httpProvider_MATIC);

  React.useEffect(() => {
    if (stakingList && stakingList.length > 0) {
      StakingFunction();
    }
  }, [stakingList, account, chainId]);

  const StakingFunction = async () => {
    try {
      let obj = {};

      const ListData = await Promise.all(
        stakingList.map(async (stakingData) => {
          let stakingContract;
          // let contract;
          let web3;
          let rpc;
          if (stakingData.coinName == 56) {
            // web3 = getWeb3Obj(default_RPC_URL_BNB_BNB);
            web3 = web3_BNB;
            rpc = default_RPC_URL_BNB_BNB;
          }
          if (stakingData.coinName == 1) {
            web3 = web3_ETH;
            // web3 = getWeb3Obj(default_RPC_URL_ETH);
            rpc = default_RPC_URL_ETH;
          }
          if (stakingData.coinName == 137) {
            web3 = web3_MATIC;
            // web3 = getWeb3Obj(default_RPC_URL_MATIC);
            rpc = default_RPC_URL_MATIC;
          }

          // console.log("stakingData1", stakingData);

          if (stakingData?.fix) {
            stakingContract = await getWeb3ContractObject(
              StatelliteStakingFixedABI,
              stakingData?.contractId,
              rpc
            );
          }
          if (stakingData?.reflection) {
            // console.log(rpc);
            stakingContract = await getWeb3ContractObject(
              StatelliteStakingDividentABI,
              stakingData?.contractId,
              rpc
            );
          }
          if (stakingData?.dynamic) {
            stakingContract = await getWeb3ContractObject(
              StatelliteStakingDynamicABI,
              stakingData?.contractId,
              rpc
            );
          }
          console.log("stakingData2", stakingContract);
          let dividendToken;
          let dividendTokensymbol;
          let earnedToken;
          let earnTokensymbol;
          let pendingDividends = 0;
          let pendingReward = 0;
          if (stakingData?.reflection) {
            dividendToken = await stakingContract.methods
              .dividendToken()
              .call();
            if (account) {
              const pendingDividends1 = await stakingContract.methods
                .pendingDividends(account)
                .call();
              pendingDividends = pendingDividends1.toString();
              const pendingReward1 = await stakingContract.methods
                .pendingReward(account)
                .call();
              pendingReward = pendingReward1.toString();
            }
            const dividendTokenObj = await getWeb3ContractObject(
              IERC20ABI,
              dividendToken,
              rpc
            );
            dividendTokensymbol = await dividendTokenObj.methods
              .symbol()
              .call();

            earnedToken = await stakingContract.methods.earnedToken().call();
            const earnedTokenObj = await getWeb3ContractObject(
              IERC20ABI,
              earnedToken,
              rpc
            );
            earnTokensymbol = await earnedTokenObj.methods.symbol().call();
          }
          const stakingContractStakeToken = await getWeb3ContractObject(
            IERC20ABI,
            stakingData?.tokenAddress?.contractAddress,
            rpc
          );
          const nameStakeToken = await stakingContractStakeToken.methods
            .name()
            .call();
          const symbolStakeToken = await stakingContractStakeToken.methods
            .symbol()
            .call();

          let owner;
          owner = await stakingContract.methods.owner().call();
          let userInfo = "";
          let pendingRewardsETH = "";
          let balanceOf = 0;
          let balanceOfFixed = 0;

          // console.log(owner);
          console.log(account);
          if (account) {
            const contract12 = await getWeb3ContractObject(
              IERC20ABI,
              stakingData?.tokenAddress?.contractAddress,
              rpc
            );

            userInfo = await stakingContract.methods.userInfo(account).call();
            console.log("userInfo", userInfo);

            let pendingRewards;

            if (!stakingData?.reflection) {
              pendingRewards = await stakingContract.methods
                .pendingRewards(account)
                .call();
            } else {
              pendingRewards = await stakingContract.methods
                .pendingReward(account)
                .call();
            }
            balanceOf = await contract12.methods.balanceOf(account).call();

            balanceOfFixed = web3.utils.fromWei(balanceOf.toString());

            pendingRewardsETH = web3.utils.fromWei(pendingRewards.toString());
          }
          console.log("userInfouserInfouserInfouserInfo", userInfo);
          console.log("--- pendingRewards -----", pendingRewardsETH);
          let totalStaked1;
          if (!stakingData?.reflection) {
            totalStaked1 = await stakingContract.methods.totalStaked().call();
          } else {
            totalStaked1 = await stakingContract.methods.totalStaked().call();
          }
          let rewardPerBlock;
          if (!stakingData?.reflection) {
            rewardPerBlock = await stakingContract.methods
              .rewardPerBlock()
              .call();
          } else {
            rewardPerBlock = await stakingContract.methods
              .rewardPerBlock()
              .call();
          }
          // console.log("totalStaked1", totalStaked1);
          // console.log("rewardPerBlock", rewardPerBlock);
          obj = {
            rewardPerBlock:
              (parseFloat(web3.utils.fromWei(rewardPerBlock.toString())) *
                1000000) /
              9.6450617,
            nameStakeToken: nameStakeToken,
            symbolStakeToken: symbolStakeToken,
            userInfo: userInfo,
            // usernEarn: parseFloat(web3.utils.fromWei(userInfo.amount.toString())),
            totalStaked: web3.utils.fromWei(totalStaked1.toString()),
            pendingRewardsETH: pendingRewardsETH,
            balanceOf: parseFloat(balanceOfFixed).toFixed(2),
            ownerAddress: owner,
            tokenAddress: stakingData?.tokenAddress,
            contractId: stakingData?.contractId,
            rewardAddress: stakingData?.rewardAddress,
            image: stakingData?.image,
            coinName: stakingData?.coinName,
            dynamic: stakingData?.dynamic,
            fix: stakingData?.fix,
            reflection: stakingData?.reflection,
            status: stakingData?.status,
            dividendTokensymbol: dividendTokensymbol,
            earnTokensymbol: earnTokensymbol,
            pendingDividends: pendingDividends,
            pendingReward: pendingReward,
          };
          return obj;
        })
      );
      console.log("_______---------------------_________", ListData);
      setArrayData(ListData);
      setArrayData1(ListData);

      // let temp;

      // stakingList?.map((map, i) => {
      //   obj = {
      //     tokenAddress: map?.tokenAddress,
      //     rewardAddress: map?.rewardAddress,
      //     image: map?.image,
      //     coinName: map?.coinName,
      //     dynamic: map?.dynamic,
      //     fix: map?.fix,
      //   };
      //   // temp = { ...arrayData1, [i]: obj };
      //   // array.push(obj);
      //   console.log("====================================");
      //   // console.log(temp);
      //   // setArrayData1(temp);
      //   console.log("====================================", array);
      // });

      // console.log(
      //   "array---------------------------------------------------------------",
      //   array
      // );
    } catch (err) {
      console.log(err);
    }
  };
  const getUnStakingDataHandler = async (i, amount) => {
    const web3 = (window.web3 = new Web3(window.ethereum));
    console.log(
      web3.utils.fromWei(arrayData[i]?.userInfo.amount?.toString()),
      "amount",
      amount
    );
    if (
      amount &&
      amount != "" &&
      Number(amount) <=
        Number(web3.utils.fromWei(arrayData[i]?.userInfo.amount?.toString()))
    ) {
      // console.log("amount", amount);
      const amount1 = amount;
      if (stakingList[i]?.coinName == chainId) {
        setbuttonLoader(true);
        try {
          // setUnStakeloader("withdraw");
          if (account) {
            // if (tabview === "Satellite") {
            // const contract = getContract(
            //   // StatelliteStakingAddress,
            //   stakingList[i]?.contractId,
            //   StatelliteStakingABI,
            //   library,
            //   account
            // );

            let contract;
            if (stakingList[i]?.fix) {
              contract = getContract(
                stakingList[i]?.contractId,
                StatelliteStakingFixedABI,
                library,
                account
              );
            }
            if (stakingList[i]?.reflection) {
              contract = getContract(
                stakingList[i]?.contractId,
                StatelliteStakingDividentABI,
                library,
                account
              );
            }
            if (stakingList[i]?.dynamic) {
              contract = getContract(
                stakingList[i]?.contractId,
                StatelliteStakingDynamicABI,
                library,
                account
              );
            }
            console.log(contract);
            // const canWithdraw = await contract.canWithdraw(account);

            // if (canWithdraw) {
            const amountToSend = web3.utils.toWei(amount1.toString());
            // console.log("amountToSend", amountToSend);
            const ownerFun = await contract.withdraw(amountToSend);
            await ownerFun.wait();

            // const amount = amountToSend;
            // const stakeName = "Satellite";
            setbuttonLoader(false);
            // SaveUnStakeDetails(amount, stakeName);
            toast.success("withdraw success");
            StakingFunction();
            // } else {
            //   setbuttonLoader(false);
            //   toast.error("Not enough withdraw time");
            // }
            // } else {
            //   toast.error("!");
            // }
          } else {
            // setUnStakeloader();
            setbuttonLoader(false);
            toast.error("Please connect your wallet first!");
          }
          // getPoolStakeHandler();
          // setUnStakeloader();
        } catch (error) {
          // setUnStakeloader();
          setbuttonLoader(false);
          console.log(error);
          if (error.data) {
            toast.error(error.data.message);
          } else {
            toast.error(error.message);
          }
        }
      } else {
        toast.error(
          `switch wallet connect to ${
            stakingList[i]?.coinName == 1
              ? "Ethereum Mainnet"
              : stakingList[i]?.coinName == 56
              ? "BNB Smart Chain Mainnet"
              : "Matic"
          } network`
        );
        swichNetworkHandler(stakingList[i]?.coinName);
      }
    } else {
      toast.error("Please Enter valid data");
      setbuttonLoader(false);
      setErrorText(true);
    }
    // getAllCoinsData();
    // getCoinBalanceAccount();
  };

  const getClaimDataHandler = async (i) => {
    // if (isActiveSubsription) {
    // const contract = getContract(
    //   stakingList[i]?.contractId,
    //   StatelliteStakingABI,
    //   library,
    //   account
    // );
    let contract;
    if (stakingList[i]?.fix) {
      contract = getContract(
        stakingList[i]?.contractId,
        StatelliteStakingFixedABI,
        library,
        account
      );
    }
    if (stakingList[i]?.reflection) {
      contract = getContract(
        stakingList[i]?.contractId,
        StatelliteStakingDividentABI,
        library,
        account
      );
    }
    if (stakingList[i]?.dynamic) {
      contract = getContract(
        stakingList[i]?.contractId,
        StatelliteStakingDynamicABI,
        library,
        account
      );
    }
    const pendingRewardsETH = arrayData[i]?.pendingRewardsETH;
    if (pendingRewardsETH && Number(pendingRewardsETH) > 0) {
      if (stakingList[i]?.coinName == chainId) {
        try {
          // setUnStakeloader("claim");
          if (account) {
            // if (tabview === "Satellite") {

            // const canHarvest = await contract.canHarvest(account);
            // if (canHarvest) {
            const ownerFun = await contract.claim();
            await ownerFun.wait();
            StakingFunction();
            // } else {
            toast.success("claim successfully");
            // }
            // } else {
            //   toast.error("!");
            // }
          } else {
            // setUnStakeloader();
            toast.error("Please connect your wallet first!");
          }
          // getPoolStakeHandler();
          // setUnStakeloader();
        } catch (error) {
          // setUnStakeloader();
          console.log(error);
          if (error.data) {
            toast.error(error.data.message);
          } else {
            toast.error(error.message);
          }
        }
      } else {
        toast.error(
          `switch wallet connect to ${
            stakingList[i]?.coinName == 1
              ? "Ethereum Mainnet"
              : stakingList[i]?.coinName == 56
              ? "BNB Smart Chain Mainnet"
              : "Matic"
          } network`
        );
        swichNetworkHandler(stakingList[i]?.coinName);
      }
    } else {
      toast.error("Not enough balance to claim");
    }
    // getCoinBalanceAccount();
  };
  const [stakeloader, setstakeloader] = React.useState(false);
  const getStakingDataHandler = async (i, amount) => {
    // console.log("amount", amount);
    if (amount && amount != "" && Number(amount) > 0) {
      if (stakingList[i]?.coinName == chainId) {
        try {
          setstakeloader(true);
          setbuttonLoader(true);
          if (account) {
            const web3 = (window.web3 = new Web3(window.ethereum));
            let contract;
            if (stakingList[i]?.fix) {
              contract = getContract(
                stakingList[i]?.contractId,
                StatelliteStakingFixedABI,
                library,
                account
              );
            }
            if (stakingList[i]?.dynamic) {
              contract = getContract(
                stakingList[i]?.contractId,
                StatelliteStakingDynamicABI,
                library,
                account
              );
            }
            if (stakingList[i]?.reflection) {
              contract = getContract(
                stakingList[i]?.contractId,
                StatelliteStakingDividentABI,
                library,
                account
              );
            }

            let erc20address1;

            if (stakingList[i]?.reflection) {
              erc20address1 = await contract.stakingToken();
            } else {
              const erc20address = await contract.pool();
              erc20address1 = erc20address.stake;
            }

            const contract1 = getContract(
              erc20address1,
              IERC20ABI,
              library,
              account
            );
            const amountToSend = web3.utils.toWei(amount.toString());
            // console.log(amountToSend);
            const balanceOfValue = await contract1.balanceOf(account);
            // console.log(balanceOfValue.toString());
            const walletBalance = balanceOfValue.toString();
            // console.log(walletBalance);
            if (Number(walletBalance) >= amountToSend) {
              const approveFun = await contract1.approve(
                stakingList[i]?.contractId,
                amountToSend
              );
              await approveFun.wait();
              const ownerFun = await contract.deposit(amountToSend);
              await ownerFun.wait();
              toast.success("Succesfully stake");
              StakingFunction();
              setbuttonLoader(false);
            } else {
              setbuttonLoader(false);
              setstakeloader(false);
              toast.error("Low balance");
            }
          } else {
            setbuttonLoader(false);
            toast.error("Please connect your wallet first!");
          }
          // getPoolStakeHandler();
          setstakeloader(false);
        } catch (error) {
          // setstakeloader(false);
          setbuttonLoader(false);
          toast.error(error.message);
          console.log("error????", error);
        }
      } else {
        toast.error(
          `switch wallet connect to ${
            stakingList[i]?.coinName == 1
              ? "Ethereum Mainnet"
              : stakingList[i]?.coinName == 56
              ? "BNB Smart Chain Mainnet"
              : "Matic"
          } network`
        );
        swichNetworkHandler(stakingList[i]?.coinName);
      }
    } else {
      toast.error("Please Enter valid data");
      setbuttonLoader(false);
    }
  };

  const [loader, setLoader] = React.useState(false);
  const StakingHarvest = async (i) => {
    setLoader(true);
    const web3 = (window.web3 = new Web3(window.ethereum));
    try {
      setharvestLoader(true);
      if (stakingList[i]?.coinName == chainId) {
        if (stakingList[i]?.reflection) {
          const contract = getContract(
            stakingList[i]?.contractId,
            StatelliteStakingDividentABI,
            library,
            account
          );
          console.log("==============1543", contract);

          // *********************** Owner
          const AdminOwner = await contract.owner();
          await AdminOwner;
          // *********************** using the promise

          const claimReward = await contract.claimReward();
          await claimReward.wait();
          toast.success("Harvest Done");

          setLoader(false);
        }
      } else {
        toast.error(
          `switch wallet connect to ${
            stakingList[i]?.coinName == 1
              ? "Ethereum Mainnet"
              : stakingList[i]?.coinName == 56
              ? "BNB Smart Chain Mainnet"
              : "Matic"
          } network`
        );

        swichNetworkHandler(stakingList[i]?.coinName);
      }
    } catch (error) {
      setLoader(false);
      toast.error(error.message);
      setharvestLoader(false);
      console.log(error);
    }
  };

  const CompoundEarned = async (i) => {
    setLoader(true);
    const web3 = (window.web3 = new Web3(window.ethereum));
    try {
      if (stakingList[i]?.coinName == chainId) {
        if (stakingList[i]?.reflection) {
          const contract = getContract(
            stakingList[i]?.contractId,
            StatelliteStakingDividentABI,
            library,
            account
          );

          const compoundReward = await contract.compoundReward();
          await compoundReward.wait();
          setLoader(false);
          toast.success("Compound earned successfully");
        }
      } else {
        toast.error(
          `switch wallet connect to ${
            stakingList[i]?.coinName == 1
              ? "Ethereum Mainnet"
              : stakingList[i]?.coinName == 56
              ? "BNB Smart Chain Mainnet"
              : "Matic"
          } network`
        );
        swichNetworkHandler(stakingList[i]?.coinName);
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
      toast.error(error.message);
    }
  };

  const CompoundDivident = async (i) => {
    setLoader(true);
    const web3 = (window.web3 = new Web3(window.ethereum));
    try {
      if (stakingList[i]?.coinName == chainId) {
        if (stakingList[i]?.reflection) {
          const contract = getContract(
            stakingList[i]?.contractId,
            StatelliteStakingDividentABI,
            library,
            account
          );

          const compoundReward = await contract.compoundDividend();
          await compoundReward.wait();
          toast.success("Compound earned successfully");

          setLoader(false);
        }
      } else {
        toast.error(
          `switch wallet connect to ${
            stakingList[i]?.coinName == 1
              ? "Ethereum Mainnet"
              : stakingList[i]?.coinName == 56
              ? "BNB Smart Chain Mainnet"
              : "Matic"
          } network`
        );
        swichNetworkHandler(stakingList[i]?.coinName);
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
      toast.error(error.message);
    }
  };

  const harvestclaimDividend = async (i) => {
    setLoader(true);
    const web3 = (window.web3 = new Web3(window.ethereum));
    try {
      if (stakingList[i]?.coinName == chainId) {
        if (stakingList[i]?.reflection) {
          const contract = getContract(
            stakingList[i]?.contractId,
            StatelliteStakingDividentABI,
            library,
            account
          );
          const claim = await contract.claimDividend();
          await claim.wait();
          toast.success("Harvest claim successfully");
          setLoader(false);
        }
      } else {
        toast.error(
          `switch wallet connect to ${
            stakingList[i]?.coinName == 1
              ? "Ethereum Mainnet"
              : stakingList[i]?.coinName == 56
              ? "BNB Smart Chain Mainnet"
              : "Matic"
          } network`
        );
        // console.log(stakingList[i]?.coinName);
        // console.log(typeof stakingList[i]?.coinName);
        swichNetworkHandler(stakingList[i]?.coinName);
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
      toast.error(error.message);
    }
  };

  const swichNetworkHandler = async (id) => {
    if (window.ethereum) {
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: "0x" + parseFloat(id).toString(16) }],
        });
      } catch (error) {
        console.log("ERROR", error);
        // toast.warn(error.message);
        if (error.code === 4902) {
          addNetworkHandler(id);
        }
      }
    }
  };

  const addNetworkHandler = async (id) => {
    const NetworkDetails = getNetworkDetails(id);
    try {
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: NetworkDetails,
      });
    } catch (error) {
      console.log("ERROR", error);
      toast.warn(error.message);
    }
  };
  return (
    <Box>
      <Container>
        {loaderStart && (
          <Box
            style={{
              // width: "100%",
              padding: "30px",
              display: "flex",
              alignItems: "center",
              // justifyContent: "center",
            }}
          >
            {" "}
            <Typography variant="body1">Loading...</Typography>
            <LoadingButton />
          </Box>
        )}
        {!loaderStart && stakingList.length == 0 && (
          <Box
            style={{
              // width: "100%",
              padding: "30px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <NoDataFound />
          </Box>
        )}
        {!loaderStart && (
          <Box className={classes.box1}>
            {home === true && (
              <>
                <Typography variant="h1">Recent Staking pools</Typography>
                <Box style={{ width: "100%" }} className={classes.box0}>
                  {" "}
                  <Typography variant="h5">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book.
                  </Typography>
                </Box>
              </>
            )}
            {arrayData.length === 0 ? (
              <>
                {stakingList &&
                  stakingList?.map((Datamap, i) => {
                    if (status === "UnActive") {
                      if (Datamap.status === "ACTIVE") {
                        return;
                      }
                    }
                    if (ownerCheck === true) {
                      if (Datamap.ownerAddress !== account) {
                        return;
                      }
                    }

                    return (
                      <Box className={classes.root1}>
                        <StakingCards
                          defaultLoader={true}
                          datamap={Datamap}
                          accountTrue={false}
                          i={i}
                          enableButtonLoader={enableButtonLoader}
                          harvestLoader={harvestLoader}
                          buttonLoader={buttonLoader}
                          getStakingDataHandler={getStakingDataHandler}
                          getClaimDataHandler={getClaimDataHandler}
                          getUnStakingDataHandler={getUnStakingDataHandler}
                          StakingHarvest={StakingHarvest}
                          CompoundEarned={CompoundEarned}
                          CompoundDivident={CompoundDivident}
                          harvestclaimDividend={harvestclaimDividend}
                          loader={loader}
                          errorText={errorText}
                          // web3={web3}
                        />
                      </Box>
                    );
                  })}
              </>
            ) : (
              <>
                {arrayData &&
                  arrayData?.map((Datamap, i) => {
                    if (status === "UnActive") {
                      if (Datamap.status === "ACTIVE") {
                        return;
                      }
                    }
                    if (ownerCheck === true) {
                      if (Datamap.ownerAddress !== account) {
                        return;
                      }
                    }
                    return (
                      <Box className={classes.root1}>
                        <StakingCards
                          defaultLoader={false}
                          datamap={Datamap}
                          i={i}
                          accountTrue={true}
                          enableButtonLoader={enableButtonLoader}
                          harvestLoader={harvestLoader}
                          buttonLoader={buttonLoader}
                          getStakingDataHandler={getStakingDataHandler}
                          getClaimDataHandler={getClaimDataHandler}
                          getUnStakingDataHandler={getUnStakingDataHandler}
                          StakingHarvest={StakingHarvest}
                          CompoundEarned={CompoundEarned}
                          CompoundDivident={CompoundDivident}
                          harvestclaimDividend={harvestclaimDividend}
                          loader={loader}
                          errorText={errorText}
                        />
                      </Box>
                    );
                  })}
              </>
            )}
          </Box>
        )}
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          className={classes.Dialogbox1}
          disableScrollLock={true}
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
            className={classes.Dialogbox}
          >
            <Box>
              <Typography variant="h3"> ROI Calculator</Typography>
            </Box>
          </DialogTitle>
          <DialogContent dividers className={classes.Dcontent}>
            <Box className={classes.box3}>
              <Typography variant="h6"> BREWLABS STAKED</Typography>

              <Box className={classes.radiusbox}>
                {/* <Typography variant="h6" style={{ fontSize: "16px" }}>
                  {" "}
                  <b>0.000 &nbsp; USD</b>
                </Typography> */}
                <input type="text" placeholder="125 USD" />
                <Typography variant="h6"> 0.00 BREWLABS</Typography>
              </Box>
              <Box className={classes.threebtn}>
                <Box>
                  {" "}
                  <Button className={classes.btn1}>$100</Button>&nbsp;
                  <Button className={classes.btn1}>$1000</Button>&nbsp;
                  <Button className={classes.btn1}>My BALANCE</Button>
                </Box>
                &nbsp;
                <AiOutlineQuestionCircle
                  style={{ marginTop: "3px", fontSize: "20px" }}
                />
              </Box>

              <Box className={classes.boxday}>
                <Typography variant="h6">STAKED FOR</Typography>
                <Box
                  mt={1}
                  style={{ backgroundColor: "#89d5f5", borderRadius: " 6px" }}
                >
                  <ToggleButtonGroup
                    value={alignment}
                    exclusive
                    onChange={handleAlignment}
                    aria-label="text alignment"
                  >
                    <ToggleButton value="left" aria-label="left aligned">
                      1D
                    </ToggleButton>
                    <ToggleButton value="center" aria-label="centered">
                      7D
                    </ToggleButton>
                    <ToggleButton value="right" aria-label="right aligned">
                      30D
                    </ToggleButton>
                    <ToggleButton value="justify" aria-label="justified">
                      1Y
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>
                {/* <Box className={classes.btngroup5}>
                  <Typography className={classes.btngroup}>1D</Typography>
                  <Typography className={classes.btngroup}>7D</Typography>
                  <Typography className={classes.btngroup}>30D</Typography>
                  <Typography className={classes.btngroup}> 1Y</Typography>
                  <Typography className={classes.btngroup}>5Y</Typography>
                </Box> */}
              </Box>
              <Box className={classes.boxday2}>
                <Typography variant="h6">COMPOUNDING EVERY</Typography>
                <Box className={classes.btngroup5} alignItems="center">
                  <BsCheckCircle style={{ fontSize: "25px" }} />
                  <Box
                    mt={1}
                    style={{
                      backgroundColor: "#89d5f5",
                      borderRadius: " 6px",
                      width: "90%",
                    }}
                  >
                    <ToggleButtonGroup
                      value={alignment}
                      exclusive
                      onChange={handleAlignment}
                      aria-label="text alignment"
                    >
                      <ToggleButton value="left" aria-label="left aligned">
                        1D
                      </ToggleButton>
                      <ToggleButton value="center" aria-label="centered">
                        7D
                      </ToggleButton>
                      <ToggleButton value="right" aria-label="right aligned">
                        30D
                      </ToggleButton>
                      <ToggleButton value="justify" aria-label="justified">
                        1Y
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Box>
                  {/* <Typography className={classes.btngroup}>7D</Typography>
                  <Typography className={classes.btngroup}>30D</Typography>
                  <Typography className={classes.btngroup}> 1Y</Typography>
                  <Typography className={classes.btngroup}>5Y</Typography> */}
                </Box>
              </Box>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "30px",
                }}
              >
                <AiOutlineArrowDown
                  style={{ fontSize: "20px", color: "white" }}
                />
              </Box>
              <Box className={classes.lstbox}>
                <Box>
                  <Typography variant="h6">ROI AT CURRENT RATES</Typography>
                  <Box
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <input type="text" placeholder="125 USD" />
                  </Box>

                  <Typography variant="h5">~ 0 BREWLABS (0.00%)</Typography>
                </Box>
              </Box>
              <Box className={classes.detailsbox}>
                <Button style={{ color: "white" }}> Details</Button>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
        {home === true && (
          <Box className={classes.box22}>
            <Button
              className={classes.btn2}
              component={Link}
              to="/app/stake-dashboard"
            >
              View More
            </Button>
          </Box>
        )}
      </Container>
    </Box>
  );
}
