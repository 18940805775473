import React, { useState } from "react";
import {
  Box,
  makeStyles,
  Button,
  Grid,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
// import Page from "src/components/Page";
import Unactive from "./Unactive";
import Active from "./Active";
import { withStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Recent from "../../dashboard/Recent";

const useStyles = makeStyles((theme) => ({
  PageHeading: {
    fontWeight: "500",
    fontSize: "32px",
    lineHeight: "39px",
    color: "#000",
    paddingBottom: "10px",
  },
  walletPage: {
    position: "relative",
    zIndex: "9",
    "& h4": {
      fontSize: "50px",
      fontWeight: "600",
      color: "#231F20",
      textShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
      marginBottom: "30px",
      [theme.breakpoints.down("lg")]: {
        fontSize: "40px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "30px",
      },
    },
    "& p": {
      fontWeight: "400",
      width: "100%",
      maxWidth: "700px",
      margin: "0 auto",
      marginBottom: "20px",
      fontSize: "16px",
      lineHeight: "27px",
      color: "#707070",
    },
  },

  mainBox: {
    marginTop: "40px",
    overflow: "hidden",
    position: "relative",
  },
}));
const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);
function Index(props) {
  const classes = useStyles();
  const [tabview, setTabView] = useState("Active");
  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: false,
    checkedF: true,
    checkedG: true,
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  return (
    <Box className={classes.mainBox} mt={5}>
      <Box className={classes.Padding_Top}>
        <Box className={classes.walletPage} mb={5}>
          <Grid container alignItems="center">
            <Grid item xs={12} sm={7}>
              <Box mt={3} className="tabButtons">
                <Button
                  className={tabview === "Active" ? "active" : " "}
                  onClick={() => setTabView("Active")}
                >
                  Active
                </Button>
                <Button
                  className={tabview === "Unactive" ? "active" : " "}
                  onClick={() => setTabView("Unactive")}
                >
                  {" "}
                  Inactive
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} sm={5} align="right">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.checkedB}
                    onChange={handleChange}
                    name="checkedB"
                    color="primary"
                  />
                }
                label="My Pools"
              />
            </Grid>
          </Grid>
        </Box>

        <Grid item xs={12} md={12} lg={12}>
          <Box className="TabButtonsContant">
            {console.log("state.checkedB", state.checkedB)}
            {tabview === "Active" ? (
              <Recent
                home={false}
                status={"Active"}
                ownerCheck={state.checkedB}
              />
            ) : (
              ""
            )}
            {tabview === "Unactive" ? (
              <Recent
                home={false}
                status={"UnActive"}
                ownerCheck={state.checkedB}
              />
            ) : (
              ""
            )}
          </Box>
        </Grid>
      </Box>
    </Box>
  );
}

export default Index;
