import React from "react";
import {
  Box,
  makeStyles,
  Typography,
  Grid,
  Button,
  Container,
  Accordion,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { Opacity } from "@material-ui/icons";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { BsCheckCircle } from "react-icons/bs";
import { AiOutlineArrowDown } from "react-icons/ai";
import { BsFillPencilFill } from "react-icons/bs";
import { IoMdRefresh } from "react-icons/io";
// import ConnectWallet from "../../components/ConnectWalletPopUp";
import { FaCoins } from "react-icons/fa";
// import BasicSelect from "./BasicSelect";
// import BasicSelect from "../../pages/dashboard/BasicSelect";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { BiTimer } from "react-icons/bi";
import { AiOutlineCalculator } from "react-icons/ai";
import { MdContentCopy } from "react-icons/md";
// import Drag from "./Drag";
import { withStyles } from "@material-ui/core/styles";
// import Button from '@material-ui/core/Button';
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { useWeb3React } from "@web3-react/core";
import StakingCards from "../../components/StakingCards";
// import Typography from '@material-ui/core/Typography';
import axios from "axios";
import apiConfig from "../../config/ApiConfig";
import Recent from "../dashboard/Recent";
import UserContex from "../../context/User";
const Datamap = [
  {
    layer1: "images/Layer1.png",
    Earn: "    Earn Satellite",
    Stake: "  Stake Satellite",
    Refl: "   Refl. BUSD",
    Satellite: " Satellite Earned",
    h0: " 0.0",
    USD: "   0 USD",
    APR: "  APR",
    h15: "  15.58%",
    Total: "   Total staked",
    Satrllite: " 128,471,558 Satellite",
    Ends: "  Ends in",
    blocks: "  7,290,724 blocks",
    Details: "Details",
    h22: "   2.00 %",
    ConnectWallet: "Connect Wallet",
    STARTSTAKING: "START STAKING",
    WITHDRAWFEE: "WITHDRAW FEE",
    DEPOSITFEE: "DEPOSIT FEE",
    BUSD: "BUSD",
    REFLECTED: "REFLECTED",
    EARNED: "EARNED",
    Satellite: "Satellite",
    SeeTokenInfo: "See Token Info",
    ViewProjectSite: "View Project Site",
    Manual: "Manual",
  },
  {
    recent1: " Recent Staking pools",
    lorem1:
      "  Lorem Ipsum is simply dummy text of the printing and   typesetting industry. Lorem Ipsum has been the industry's  standard dummy text ever since the 1500s, when an unknown    printer took a galley of type and scrambled it to make a type  specimen book.",
    layer1: "images/Layer1.png",
    Earn: "    Earn Satellite",
    Stake: "  Stake Satellite",
    Refl: "   Refl. BUSD",
    Satellite: " Satellite Earned",
    h0: " 0.0",
    USD: "   0 USD",
    APR: "  APR",
    h15: "  15.58%",
    Total: "   Total staked",
    Satrllite: " 128,471,558 Satellite",
    Ends: "  Ends in",
    blocks: "  7,290,724 blocks",
    Details: "Details",
    h22: "   2.00 %",
    ConnectWallet: "Connect Wallet",
    STARTSTAKING: "STARTSTAKING",
    WITHDRAWFEE: "WITHDRAW FEE",
    DEPOSITFEE: "DEPOSIT FEE",
    BUSD: "BUSD",
    REFLECTED: "REFLECTED",
    EARNED: "EARNED",
    Satellite: "Satellite",
    SeeTokenInfo: "See Token Info",
    ViewProjectSite: "View Project Site",
    Manual: "Manual",
  },
  {
    recent1: " Recent Staking pools",
    lorem1:
      "  Lorem Ipsum is simply dummy text of the printing and   typesetting industry. Lorem Ipsum has been the industry's  standard dummy text ever since the 1500s, when an unknown    printer took a galley of type and scrambled it to make a type  specimen book.",
    layer1: "images/Layer1.png",
    Earn: "    Earn Satellite",
    Stake: "  Stake Satellite",
    Refl: "   Refl. BUSD",
    Satellite: " Satellite Earned",
    h0: " 0.0",
    USD: "   0 USD",
    APR: "  APR",
    h15: "  15.58%",
    Total: "   Total staked",
    Satrllite: " 128,471,558 Satellite",
    Ends: "  Ends in",
    blocks: "  7,290,724 blocks",
    Details: "Details",
    h22: "   2.00 %",
    ConnectWallet: "Connect Wallet",
    STARTSTAKING: "STARTSTAKING",
    WITHDRAWFEE: "WITHDRAW FEE",
    DEPOSITFEE: "DEPOSIT FEE",
    BUSD: "BUSD",
    REFLECTED: "REFLECTED",
    EARNED: "EARNED",
    Satellite: "Satellite",
    SeeTokenInfo: "See Token Info",
    ViewProjectSite: "View Project Site",
    Manual: "Manual",
  },
  {
    recent1: " Recent Staking pools",
    lorem1:
      "  Lorem Ipsum is simply dummy text of the printing and   typesetting industry. Lorem Ipsum has been the industry's  standard dummy text ever since the 1500s, when an unknown    printer took a galley of type and scrambled it to make a type  specimen book.",
    layer1: "images/Layer1.png",
    Earn: "    Earn Satellite",
    Stake: "  Stake Satellite",
    Refl: "   Refl. BUSD",
    Satellite: " Satellite Earned",
    h0: " 0.0",
    USD: "   0 USD",
    APR: "  APR",
    h15: "  15.58%",
    Total: "   Total staked",
    Satrllite: " 128,471,558 Satellite",
    Ends: "  Ends in",
    blocks: "  7,290,724 blocks",
    Details: "Details",
    h22: "   2.00 %",
    ConnectWallet: "Connect Wallet",
    STARTSTAKING: "STARTSTAKING",
    WITHDRAWFEE: "WITHDRAW FEE",
    DEPOSITFEE: "DEPOSIT FEE",
    BUSD: "BUSD",
    REFLECTED: "REFLECTED",
    EARNED: "EARNED",
    Satellite: "Satellite",
    SeeTokenInfo: "See Token Info",
    ViewProjectSite: "View Project Site",
    Manual: "Manual",
  },
];

const useStyles = makeStyles((theme) => ({
  box1: {
    marginTop: "3rem",
    paddingBottom: "50px",
    "& h1": {
      fontFamily: "'Lexend Deca', sans-serif",
      // fontFamily: "'Lexend Deca'",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "45px",
      lineHeight: "55px",
      textTransform: "capitalize",
      color: "#0E2137",
      textAlign: "center",
      [theme.breakpoints.only("xs")]: {
        fontSize: "25px",
      },
    },
  },
  root1: {
    width: "100%",
    // marginTop: "50px",
  },
  accordion: {
    marginTop: "20px",
    background: "#E2F6FF",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "10px",
  },
  heading: {
    fontFamily: "'Lexend Deca'",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "21px",
    color: "#0E2137",
  },
  heading0: {
    fontFamily: "'Lexend Deca'",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "22px",
    lineHeight: "21px",
    color: "#0E2137",
  },
  heading1: {
    fontFamily: "'Lexend Deca'",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "24px",
    color: "#5F5F5F",
  },
  box22: {
    display: "flex",
    justifyContent: "end",
    marginTop: "25px",
  },
  btn: {
    background: " #D6F3FF ",
    borderRadius: "5px",
    "@media(max-width:960px)  ": {
      marginTop: "10px",
    },
  },
  btn1: {
    marginTop: "10px",
    background: " #D6F3FF ",
    background: " #D6F3FF ",
    borderRadius: "5px",
  },
  heading2: {
    fontFamily: "'Lexend Deca'",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "24px",
    color: "#31B0E3",
  },
  heading3: {
    marginTop: "10px",
    fontFamily: "'Lexend Deca'",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "24px",
    color: "#31B0E3",
  },
  heading4: {
    fontFamily: "'Lexend Deca'",
    // fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "21px",
    color: "#0E2137",
    marginTop: "10px",
  },
  btn2: {
    color: "white",
    background: "#0E2137",
    borderRadius: "10px",
    borderRadius: "10px",
    padding: "15px",
    paddingLeft: "20px",
    paddingRigth: "20px",
    "&:hover": {
      background: "#3cb4e4",
    },
  },
  box2: {
    marginTop: "20px",
    display: "flex",
    // justifyContent: "end",
  },
  btnDetails: {
    color: "#31B0E3",
    fontFamily: "'Lexend Deca'",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "24px",
    "@media(max-width:960px)": {
      display: "none  ",
    },
  },
  // tp1: {
  //   "@media(min-width:1280px)": {
  //     textAlign: "center",
  //   },
  // },
  btn3: {
    background: "#0E2137",
    borderRadius: "10px",
    width: "21rem",
    height: "50px",
    marginTop: "34px",
    color: " #FFFFFF",
    fontWeight: "600",
    "&:hover": {
      background: "#3cb4e4",
    },
    "@media(max-width:1280px)": {
      width: "11rem",
    },
    "@media(max-width:960px)": {
      display: "none",
    },
  },
  btn4: {
    "@media(min-width:960px)": {
      display: "none",
    },

    "@media(max-width:960px)": {
      display: "none",
      width: "200%",
      background: "#0E2137",
      borderRadius: "10px",
      // width: "21rem",
      height: "50px",
      marginTop: "34px",
      color: " #FFFFFF",
      fontWeight: "600",
    },
  },
  box0: {
    display: "flex",
    justifyContent: "center",
    "& h5": {
      marginTop: "30px",
      maxWidth: "56rem",
      fontFamily: "'Lexend Deca'",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "24px",
      textAlign: "center",
      color: " #626262",
    },
  },
  textbtn: {
    fontFamily: "'Lexend Deca'",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "13px",
    lineHeight: "20px",
    color: "#31B0E3",
  },
  btn31: {
    display: "none",
    "@media(max-width:960px)": {
      display: "initial",
      width: "200%",
      background: "#0E2137",
      borderRadius: "10px",
      // width: "21rem",
      height: "50px",
      marginTop: "34px",
      color: " #FFFFFF",
      fontWeight: "600",
    },
  },
  Dialogbox: {
    color: "white",
    maxWidth: "100%",
    "& h3": {
      // padding: "12px",
      marginTop: "14px",
      color: "white",
    },
  },
  radiusbox: {
    boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
    borderRadius: "20px",
    // border: "1px solid black",
    backgroundColor: " #89d5f5",
    padding: "10px",
    textAlign: "end",
    color: "white",
    "& input": {
      backgroundColor: "transparent",
      border: "none",
      textAlign: "right",
      color: "#0c2739",
      "&:focus-visible": {
        outline: "none",
      },
    },
  },

  Dcontent: {
    padding: "34px",
    marginTop: "6px",
    "& h6": {
      color: "white",
      fontSize: "13px",
    },
  },
  btn: {
    height: "21px",
    // background: "red",
    paddingLeft: "10px",
    paddingRigth: "10px",
    borderRadius: "50px",
  },
  btn1: {
    height: "21px",
    // background: "red",
    paddingLeft: "10px",
    paddingRigth: "10px",
    borderRadius: "50px",
  },
  threebtn: {
    marginTop: "10px",
    display: "flex",
  },
  boxday: {
    marginTop: "20px",
    "& h6": {
      fontSize: "13px",
      color: "white",
    },
  },
  btngroup5: {
    paddingBottom: "5px",
    paddingTop: "5px",
    // border: "1px solid black",
    borderRadius: "30px",
    color: "white",
    display: "flex",
    justifyContent: "space-between",
  },
  btngroup: {
    width: "44px",
    // background: "red",
    textAlign: "center",
    borderRadius: "30px",
    fontSize: "16px",
  },
  boxday2: {
    marginTop: "20px",
  },
  lstbox: {
    marginTop: "30px",
    padding: "20px",
    marginTop: "30px",
    borderRadius: "30px",
    boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
    backgroundColor: "#89d5f5",
    // border: "1px solid black",
    "& input": {
      backgroundColor: "transparent",
      border: "none",
      textAlign: "left",
      color: "#0c2739",
      "&:focus-visible": {
        outline: "none",
      },
    },
    "& h6": {
      fontSize: "13px",
      color: "#0c2739",
    },
    "& h5": {
      fontSize: "10px",
      color: "#0c2739",
      marginTop: "5px",
    },
    "& h2": {
      fontSize: "25px",
      fontWeight: "500",
      color: "#0c2739",
    },
  },
  detailsbox: {
    display: "flex",
    justifyContent: "center",
    marginTop: "30px",
  },
  dashboardBox: {
    background: " rgb(237,247,247)",
    background:
      "linear-gradient(90deg, rgb(137 213 245) 0%, rgb(4 121 255) 100%)",
    padding: "15px",
    borderRadius: "10px",
    textAlign: "center",
    position: "relative",

    "&:hover": {
      "& svg": {
        transform: "translate(-10px, -50%)",
      },
    },
    "& h5": {
      fontSize: "40px",
      color: "#ffffff",
    },
    "& p": {
      fontSize: "15px",
      color: "#323f50",
    },
    "& svg": {
      transition: "0.3s",
      position: "absolute",
      top: "50%",
      fontSize: "80px",
      transform: "translateY(-50%)",
      right: "10px",
      color: "#ffffff45",
    },
  },
  dashboardmainBox: {
    marginBottom: "50px",
  },
}));
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
export default function Maindashboard(props) {
  var classes = useStyles();
  const [alignment, setAlignment] = React.useState("left");
  const [openWalletConnect, setOpenWalletConnect] = React.useState(false);
  const { account, chainId } = useWeb3React();
  const user = React.useContext(UserContex);
  // console.log("------------------------572");
  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const [open, setOpen] = React.useState(false);
  const [dashboardData, setDashboardData] = React.useState(false);
  const [loaderStart, setLoaderStart] = React.useState(false);
  const [tollActive, setTollActive] = React.useState(0);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  React.useEffect(() => {
    setImagesTo1();
  }, [account]);
  const setImagesTo1 = async () => {
    setLoaderStart(true);
    try {
      const res = await axios({
        method: "GET",
        url: apiConfig.listStacking,
      });
      // console.log("res", res);
      if (res.data.statusCode === 200) {
        setDashboardData(res.data.result);
        const activeData = res.data.result.filter((item) => {
          return item.status === "ACTIVE";
        });
        setLoaderStart(false);
        // console.log("activeData", activeData);
        // setStakingList(res.data.result);
        setTollActive(activeData.length);
        // console.log(res);
      }
    } catch (error) {
      setLoaderStart(false);
      console.log("EROR", error);
    }
  };

  // console.log("dashboard =-------= Data", dashboardData);
  return (
    <Box className={classes.box1}>
      <Container>
        <Box className={classes.dashboardmainBox}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Box className={classes.dashboardBox}>
                <Typography variant="h5">
                  {" "}
                  {dashboardData ? dashboardData.length : 0}
                </Typography>
                <Typography variant="body2"> Total Pools</Typography>
                <FaCoins />
              </Box>
            </Grid>{" "}
            <Grid item xs={12} sm={4}>
              <Box
                className={classes.dashboardBox}
                style={{
                  background:
                    "linear-gradient(90deg, rgb(214, 124, 246) 0%, rgb(29 46 255) 100%)",
                }}
              >
                <Typography variant="h5">{tollActive} </Typography>
                <Typography variant="body2"> Active Pools</Typography>
                <FaCoins />
              </Box>
            </Grid>{" "}
            <Grid item xs={12} sm={4}>
              <Box
                className={classes.dashboardBox}
                style={{
                  background:
                    "linear-gradient(90deg, rgb(246, 124, 163) 0%, rgb(170 4 255) 100%)",
                }}
              >
                <Typography variant="h5">
                  {dashboardData ? dashboardData.length - tollActive : 0}{" "}
                </Typography>
                <Typography variant="body2"> Inactive Pools</Typography>
                <FaCoins />
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box className={classes.CreateBox}>
          <Typography variant="h1">Latest staking pool</Typography>

          <Recent home={false} status={"ACTIVE"} ownerCheck={false} />
          {/* {Datamap.map((Datamap, i) => {
            return (
              <Box>
                <StakingCards Datamap={Datamap} i={i} />
              </Box>
            );
          })} */}
        </Box>

        {/* {openWalletConnect && (
          <ConnectWallet
            open={openWalletConnect}
            handleClose={() => setOpenWalletConnect(false)}
          />
        )} */}
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          className={classes.Dialogbox1}
          disableScrollLock={true}
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
            className={classes.Dialogbox}
          >
            <Box>
              <Typography variant="h3"> ROI Calculator</Typography>
            </Box>
          </DialogTitle>
          <DialogContent dividers className={classes.Dcontent}>
            <Box className={classes.box3}>
              <Typography variant="h6"> BREWLABS STAKED</Typography>

              <Box className={classes.radiusbox}>
                {/* <Typography variant="h6" style={{ fontSize: "16px" }}>
                  {" "}
                  <b>0.000 &nbsp; USD</b>
                </Typography> */}
                <input type="text" placeholder="125 USD" />
                <Typography variant="h6"> 0.00 BREWLABS</Typography>
              </Box>
              <Box className={classes.threebtn}>
                <Box>
                  {" "}
                  <Button className={classes.btn}>$100</Button>&nbsp;
                  <Button className={classes.btn}>$1000</Button>&nbsp;
                  <Button className={classes.btn1}>My BALANCE</Button>
                </Box>
                &nbsp;
                <AiOutlineQuestionCircle
                  style={{ marginTop: "3px", fontSize: "20px" }}
                />
              </Box>

              <Box className={classes.boxday}>
                <Typography variant="h6">STAKED FOR</Typography>
                <Box
                  mt={1}
                  style={{ backgroundColor: "#89d5f5", borderRadius: " 6px" }}
                >
                  <ToggleButtonGroup
                    value={alignment}
                    exclusive
                    onChange={handleAlignment}
                    aria-label="text alignment"
                  >
                    <ToggleButton value="left" aria-label="left aligned">
                      1D
                    </ToggleButton>
                    <ToggleButton value="center" aria-label="centered">
                      7D
                    </ToggleButton>
                    <ToggleButton value="right" aria-label="right aligned">
                      30D
                    </ToggleButton>
                    <ToggleButton value="justify" aria-label="justified">
                      1Y
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>
                {/* <Box className={classes.btngroup5}>
                  <Typography className={classes.btngroup}>1D</Typography>
                  <Typography className={classes.btngroup}>7D</Typography>
                  <Typography className={classes.btngroup}>30D</Typography>
                  <Typography className={classes.btngroup}> 1Y</Typography>
                  <Typography className={classes.btngroup}>5Y</Typography>
                </Box> */}
              </Box>
              <Box className={classes.boxday2}>
                <Typography variant="h6">COMPOUNDING EVERY</Typography>
                <Box className={classes.btngroup5} alignItems="center">
                  <BsCheckCircle style={{ fontSize: "25px" }} />
                  <Box
                    mt={1}
                    style={{
                      backgroundColor: "#89d5f5",
                      borderRadius: " 6px",
                      width: "90%",
                    }}
                  >
                    <ToggleButtonGroup
                      value={alignment}
                      exclusive
                      onChange={handleAlignment}
                      aria-label="text alignment"
                    >
                      <ToggleButton value="left" aria-label="left aligned">
                        1D
                      </ToggleButton>
                      <ToggleButton value="center" aria-label="centered">
                        7D
                      </ToggleButton>
                      <ToggleButton value="right" aria-label="right aligned">
                        30D
                      </ToggleButton>
                      <ToggleButton value="justify" aria-label="justified">
                        1Y
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Box>
                  {/* <Typography className={classes.btngroup}>7D</Typography>
                  <Typography className={classes.btngroup}>30D</Typography>
                  <Typography className={classes.btngroup}> 1Y</Typography>
                  <Typography className={classes.btngroup}>5Y</Typography> */}
                </Box>
              </Box>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "30px",
                }}
              >
                <AiOutlineArrowDown
                  style={{ fontSize: "20px", color: "white" }}
                />
              </Box>
              <Box className={classes.lstbox}>
                <Box>
                  <Typography variant="h6">ROI AT CURRENT RATES</Typography>
                  <Box
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <input type="text" placeholder="125 USD" />
                  </Box>

                  <Typography variant="h5">~ 0 BREWLABS (0.00%)</Typography>
                </Box>
              </Box>
              <Box className={classes.detailsbox}>
                <Button style={{ color: "white" }}> Details</Button>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
      </Container>
    </Box>
  );
}
