import React from "react";
import {
  Box,
  makeStyles,
  Typography,
  Grid,
  Button,
  Container,
} from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  box0: {
    display: "flex",
    justifyContent: "center",
    "& h5": {
      maxWidth: "56rem",
      lineHeight: "24px",
      textAlign: "center",
      color: " #626262",
      marginTop: "25px",
      // maxWidth: "74rem",
      fontFamily: "'Lexend Deca'",
      fontSize: "16px",
      //   lineHeight: "20px",
    },
  },
  box1: {
    textAlign: "center",
    // paddingTop: "110px",
    paddingBottom: "80px",
    marginTop: "4rem",

    "& h1": {
      fontFamily: "'Lexend Deca'",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "65px",
      lineHeight: "55px",
      color: "#0E2137",
      "@media(max-width:414px)": {
        marginTop: "4rem",
        fontSize: "35px",
      },
    },
  },
  GroupBox: {
    // marginTop: "18rem",
    width: "100%",
    position: "absolute",
  },
  GroupBox1: {
    display: "flex",
    justifyContent: "end",
    marginTop: "-17rem",
  },
  GroupBox4: {
    marginTop: "8rem",
    width: "100%",
  },
}));

export default function Tokenomics() {
  const classes = useStyles();
  return (
    <Box>
      <Box className={classes.GroupBox}>
        {" "}
        <img style={{ width: "20%" }} src="./images/Group2.png"></img>
      </Box>
      <Container>
        <Box className={classes.box1}>
          {" "}
          <Typography variant="h1">Tokenomics</Typography>
          <Box className={classes.box0}>
            {" "}
            <Typography variant="h5">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book.{" "}
            </Typography>
          </Box>
          <Container maxWidth="md">
            {" "}
            <Box className={classes.GroupBox4}>
              <img style={{ width: "100%" }} src="images/Group4.png"></img>
            </Box>
          </Container>
        </Box>
      </Container>

      <Box className={classes.GroupBox1}>
        {" "}
        <img style={{ width: "20%" }} src="./images/Group3.svg"></img>
      </Box>
    </Box>
  );
}
