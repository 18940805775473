import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import StakeCard from "../../../components/StakeCard";

const useStyles = makeStyles((theme) => ({}));
function Active(props) {
  const classes = useStyles();
  return (
    <Box>
      {" "}
      <StakeCard />
    </Box>
  );
}

export default Active;
