import React from "react";

import {
  Box,
  Container,
  makeStyles,
  Typography,
  Grid,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { IoMdRefresh } from "react-icons/io";
import { BiChevronDown } from "react-icons/bi";
import { HiOutlineExternalLink } from "react-icons/hi";
import KingstakeCard from "../../components/KingstakeCard";
const useStyles = makeStyles((theme) => ({
  bannerBox: {
    position: "relative",
    padding: " 50px 0px",
    zIndex: " 1",
  },
  textbox: {
    "& h1": {
      fontSize: "40px",
      fontWeight: "bold",
      lineHeight: "45px",
      color: "#392472",
    },
    "& p": {
      fontSize: "18px",
      color: "#535059",
      maxWidth: "500px",
    },
  },
  viewLink: {
    paddingTop: "10px",
    textDecoration: "underline",
    color: "#3C2776",
    display: "flex",
    alignItems: "center",
    fontSize: "20px",
    fontWeight: "bold",
    justifyContent: "flex-end",
    "& link": {
      marginLeft: "2px",
    },
  },
}));
const Token = [
  {
    icon: "images/icon.png",
    name: "Fees",
    discription:
      "$300 USD in King Shiba token to deploy pre sale contract 0% Token tax to ensure no malicious token dumping like Pink Sale and Dx-Sale   3% Contribution token Fee on each pre sale",
  },
  {
    icon: "images/icons/1.svg",
    name: "King Swap",
    discription:
      "Instant access to claim and swap your tokens conveniently through our pre sale page",
  },
  {
    icon: "images/icons/1.svg",
    name: "Token distribution",
    discription: "Token distribution checker",
  },
  {
    icon: "images/icons/1.svg",
    name: "Community",
    discription: "Community vote on project safety",
  },
];
export default function Dashboard(props) {
  var classes = useStyles();
  return (
    <>
      <Box className={classes.bannerBox}>
        <Container maxWidth="md" align="center">
          <Box className={classes.textbox}>
            <Typography variant="h1">Satellite Pools</Typography>
            <Typography variant="body2">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </Typography>
          </Box>
        </Container>
        <Box mt={5}>
          <Grid container spacing={5} justifyContent="center">
            {Token.map((data, i) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={3}
                  key={i}
                  className="walletSet"
                >
                  <KingstakeCard data={data} type="card" index={i} />
                </Grid>
              );
            })}
          </Grid>
          <Grid container spacing={5} justifyContent="center">
            <Grid item xs={12} lg={12} md={12}>
              <Box>
                {" "}
                <Link href="#" className={classes.viewLink}>
                  See All{" "}
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}
