import React from 'react'
import {
  Grid,
  Box,
  Container,
  Typography,
  makeStyles,
  Button,
  withStyles,
} from '@material-ui/core'
import Chart from "react-apexcharts";
import { FaThumbsUp } from "react-icons/fa";
import { FaThumbsDown } from "react-icons/fa";
import TextField from '@material-ui/core/TextField'
import Slider from "@material-ui/core/Slider";
import Index from "./Tab/Index";
import { GoVerified } from "react-icons/go";
import { RiTelegramLine } from "react-icons/ri";
import { TiSocialTwitterCircular } from "react-icons/ti";
const iOSBoxShadow =
  "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)";
const marks = [
  {
    value: 0,
  },
  {
    value: 2500,
  },
  {
    value: 7500,
  },
  {
    value: 10000,
  },
];

const IOSSlider = withStyles({
  root: {
    color: "#FABE25!important",
    height: 12,
    padding: "15px 0",
  },
  thumb: {
    height: 20,
    width: 20,
    backgroundColor: "#fff",
    boxShadow: iOSBoxShadow,
    marginTop: -8,
    marginLeft: -14,
    "&:focus, &:hover, &$active": {
      boxShadow:
        "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        boxShadow: iOSBoxShadow,
      },
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 12px)",
    top: -22,
    "& *": {
      background: "transparent",
      color: "#fff",
    },
  },
  track: {
    height: 9,
  },
  rail: {
    height: 9,
    opacity: 0.5,
    backgroundColor: "#bfbfbf",
  },
  mark: {
    backgroundColor: "#bfbfbf",
    height: 20,
    width: 1,
    marginTop: -3,
  },
  markActive: {
    opacity: 1,
    backgroundColor: "currentColor",
  },
})(Slider);
const useStyles = makeStyles((theme) => ({

  textbox: {
    mint: {
      fontSize: "14px ",
      border: "1px solid #E8424C",
      background: "#E8424C",
      fontWeight: 600,
      height: "44px ",
      color: "#FFFFFF",
      minWidth: "150px ",
      borderRadius: "50px",
      boxShadow: "none ",
      cursor: "pointer",
      [theme.breakpoints.down("xs")]: {
        height: "45px ",
        minWidth: "120px ",
      },
      "&:hover": {
        borderColor: "#E8424C",
        background: "#E8424C",
      },
    },
    "& h1": {
      fontSize: "25px",
      fontWeight: "bold",
      lineHeight: "55px",
      color: "#FABE25",
    },
    "& h2": {
      fontSize: "45px",
      fontWeight: "bold",
      lineHeight: "55px",
      color: "#FABE25",
      "@media (max-width: 767px)": {
        fontSize: "30px",

      },
    },
    "& h3": {
      fontSize: "35px",
      fontWeight: "bold",
      lineHeight: "55px",
      color: "#fff",
      "@media (max-width: 767px)": {
        fontSize: "23px",
        lineHeight: "30px",

      },
    },
    "& h5": {
      fontSize: "30px",
      fontWeight: "500",
      color: "#fff",
      marginBottom: "10px",
      marginTop: "15px",
    },
    "& h6": {
      color: "#9F9F9F",
      marginBottom: "10px",
    },
    "& p": {
      fontSize: "14px",
      color: "#9F9F9F",
      width: "100%",
      // maxWidth: "600px",
    },
    "& label": {
      fontSize: "16px",
      color: "#fff",
      // maxWidth: "600px",
    },
    "& div": {
      "& button": {
        "&:last-child": {
          //   marginLeft: "20px",
        },
      },
    },
  },
  technologies: {
    background: '#ECECEC',
    borderRadius: '10px',
    maxHeight: '300px',
    '& img': {
      maxHeight: '300px',
    },
  },

  amount: {
    '& label': {
      color: '#353840',
      fontSize: '18px',
      fontWeight: '400',
      lineHeight: '33px',
    },
  },
  amountdiv: {
    maxWidth: '100%',
    height: '60px',
    border: '1px solid #00ffab',
    borderRadius: ' 5px',
    display: 'flex',
    padding: '0 20px',
    alignItems: 'center',
    fontSize: '45px',
  },

  inputfile: {
    background: '#ECECEC',
    borderRadius: '10px',
    position: 'relative',
    border: '2px dashed #DDD9D9',
    boxSizing: 'border-box',
    cursor: 'pointer',
    padding: "10px",

    '& input': {
      width: '100%',
      height: '100%',
      top: '0',
      left: '0',
      opacity: '0',
      position: 'absolute',
      cursor: 'pointer',
    },

    '& img': {
      padding: '26px',
    },

    '& p': {
      fontSize: '9px',
      fontWeight: 'normal',
      padding: '9px',
      lineHeight: '17px',
      textAlign: 'center',
      color: '#595C62',
      marginTop: '-17px',
    },
  },

  mainBox: {
    padding: "30px 30px 30px",
    overflow: "hidden",
    position: "relative",
    background: "linear-gradient( 152.97deg, rgba(255, 255, 255, 0.08) 0%, rgba(232, 66, 76, 0.062) 100%)",
    transition: "0.5s",
    backdropFilter: "blur(42px)",
    borderRadius: "24px",

    "& h1": {
      fontSize: "25px",
      fontWeight: "bold",
      lineHeight: "55px",
      color: "#fff",
    },
    "& h5": {
      fontSize: "14px",
      color: "#9F9F9F",
    },
    "& h6": {
      fontSize: "14px",
      lineHeight: "22px",
      color: "#FABE25",
    },
    "& p": {
      color: "#fff",
      width: "100%",
      fontSize: "16px",
      maxWidth: "600px",
      // maxWidth: "600px",
    },
    "& small": {
      fontSize: "12px",
      color: "#6c757d!important",
      // maxWidth: "600px",
    },
    "& label": {
      color: "#9F9F9F",
      padding: "0",
      fontSize: "14px",
      transition: "opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    },
  },
  selectbox: {
    width: "100%",
    border: "2px solid #FABE25",
    height: "37px",
    borderRadius: "5px",
    background: "#18131d",
    color: "#9F9F9F",
    fontSize: "14px",
    padding: "5px",
  },

  buttonright: {
    fontSize: "14px ",
    border: "1px solid #E8424C",
    background: "#E8424C",
    fontWeight: 600,
    height: "44px ",
    borderRadius: "50px",
    color: "#FFFFFF",
    minWidth: "150px ",
    boxShadow: "none ",
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      height: "45px ",
      minWidth: "120px ",
    },
    "&:hover": {
      borderColor: "#E8424C",
      backgroundColor: "#E8424C",
      border: "2px solid #fff",
    },
  },
  nameBox: {
    // display: "flex",
    // justifyContent: "space-between",
    // padding: "20px 20px 30px",
    // borderBottom: "1px solid rgba(0, 0, 0, 0.25)",
    "& h4": {
      fontSize: "25px",
      fontWeight: "bold",
      lineHeight: "55px",
      color: "#fff",
    },
    // "& p": {
    //     fontSize: "12px",
    //     color: "#fff",
    // },
    "& img": {
      width: "50px",
      height: "50px",
      borderRadius: "50%",
    },
  },
  inputBox: {
    width: "100%",
    height: "40px",
    borderRadius: "50px",
    background: "#090c16",
    border: "1px solid #fff",
    color: "#fff",
    padding: "7px",
  },

  copy: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    boxShadow: " 0px 4px 8px rgb(0 0 0 / 12%)",
    border: "1px solid #fabe25",
    color: "#fff",
    paddingTop: "13px",
    cursor: "pointer",
    padding: "10px",
    borderRadius: "50px",
    bottom: "-26px",
    [theme.breakpoints.down("xs")]: {
      right: "10px",
      width: "90%",
    },
    '& svg': {
      fontSize: "30px"
    },

    '& p': {
      color: "#fff",
      fontSize: "14px",
      [theme.breakpoints.down("md")]: {
        fontSize: "7px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "8px",
      },
    },
  },
  dexBox:{
    "& span":{
      color:"#fff !important",
    },
  },
  upvote:{
    height: "40px",
    display: "flex",
    alignItems: "center",
    color: "#fff",
    border: "1px solid green",
    "&:hover":{
      backgroundColor:"green",
    },
    "& svg":{
      marginRight:"10px",
    },
  },
  downvote:{
    height: "40px",
    display: "flex",
    alignItems: "center",
    color: "#fff",
    border: "1px solid red",
    "&:hover":{
      backgroundColor:"red",
    },
    "& svg":{
      marginRight:"10px",
    },
  },
  voteBox:{
  backgroundColor:" #090b16",
  border: "1px solid #3c3b3b",
  padding: "20px",
  textAlign: "center",
  margin: "20px 0",
  "& h2":{
    fontWeight:"600",
    color: "#a09c9c",
  },
},

}))

export default function BestSeller() {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const options = {
    chart: {
        height: "100%",
        width: "100%",
        type: "line",
        id: "basic-bar",
        plotOptions: {
            pie: {
              startAngle: -90,
              endAngle: 270
            }
          },
          
        toolbar: {
          show: false,
        },
        labels:{
          color:"#fff",
        },
       
      },
       labels: ["ETH", "BTC", "XRP", "LTC"] };
const series = [10, 50, 20, 20]; //our data
  return (
    <Box className={classes.bannerBox}>

      <Box className={classes.textbox} mt={5} mb={5}>
        <Typography variant="h2" align="center">Launchpad Detail</Typography>
      </Box>
      <Box mt={5} className={classes.amount}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={7}>
            <Box my={3} className="mainBox">

              <Box mt={3} mb={3}>
                <Box display="flex" alignItems="center" justifyContent="flex-start">
                  <figure style={{ marginLeft: "0" }}>
                    <img src="images/logo_2.png" alt=" " style={{ width: "100px" }} />

                  </figure>
                  <Box>
                    <Typography variant="h2"> UNIMOON <GoVerified style={{    color: "#349c13",}} /></Typography>
                    <a href="https://twitter.com/login?lang=en"> <TiSocialTwitterCircular style={{ fontSize: "25px", color: "#fff", margin: "0 5px" }} />   </a>
                    <a href="https://web.telegram.org/"> <RiTelegramLine style={{ fontSize: "25px", color: "#fff", margin: "0 15px 0 5px" }} /> </a>
                    <a href="#https://poocoin.app/"> <img src="images/poocoin.png" style={{ width: "22px" }} /> </a>
                    <Typography variant="body2"> UNIMOON: The First L1 Blockchain Built to Decentralize Social Media For Everyone. 13% Taxes on PCS Launch.Join The UNIMOON Revolution Want to protect your data, your freedom, and your control? We are breaking the barriers of tech, creating a free-feeling social media app that will change digital content forever. You a single user have the power to help us transform the digital world into a fair, free place where people are valued as much as the content that they create. Are you ready to join us? </Typography>

                  </Box>
                </Box>
              </Box>
              <table>
                <tbody>
                  <tr>
                    <td>Presale Address</td>
                    <td class="has-text-right">
                      <a href="https://bscscan.com/address/0x5B16a1093D551Dc84A2256394dE1A00e334eB74e" target="_blank" rel="noreferrer nofollow">0x5B16a1093D551Dc84A2256394dE1A00e334eB74e</a>
                    </td>
                  </tr>
                  <tr>
                    <td>Token Name</td>
                    <td class="has-text-right">UNIMOON</td>
                  </tr>
                  <tr>
                    <td>Token Symbol</td>
                    <td class="has-text-right">UNIMOON</td>
                  </tr>
                  <tr>
                    <td>Token Decimals</td>
                    <td class="has-text-right">9</td>
                  </tr>
                  <tr>
                    <td>Token Address</td>
                    <td class="has-text-right">
                      <a class="mr-1" href="#" target="_blank" rel="noreferrer nofollow">0x893e14FAfa8df32D9bE7eab810027eeA8828A98c</a>

                    </td>
                  </tr>
                  <tr>
                    <td>Total Supply</td>
                    <td class="has-text-right">2,000,000,000,000,000 UNIMOON</td>
                  </tr>
                  <tr>
                    <td>Tokens For Presale</td>
                    <td class="has-text-right">216,000,000,000,000 UNIMOON</td>
                  </tr>
                  <tr>
                    <td>Tokens For Liquidity</td>
                    <td class="has-text-right">151,200,000,000,000 UNIMOON</td>
                  </tr>
                  <tr>
                    <td>Presale Rate</td>
                    <td class="has-text-right">1 BNB = 90,000,000,000 UNIMOON</td>
                  </tr>
                  <tr>
                    <td>Listing Rate</td>
                    <td class="has-text-right">1 BNB = 90,000,000,000 UNIMOON</td>
                  </tr>
                  <tr>
                    <td>Soft Cap</td>
                    <td class="has-text-right">1200 BNB</td>
                  </tr>
                  <tr>
                    <td>Hard Cap</td>
                    <td class="has-text-right">2400 BNB</td>
                  </tr>
                  <tr>
                    <td>Unsold Tokens</td>
                    <td class="has-text-right">Burn</td>
                  </tr>
                  <tr>
                    <td>Presale Start Time</td>
                    <td class="has-text-right">2021.11.08 18:00 (UTC)</td>
                  </tr>
                  <tr>
                    <td>Presale End Time</td>
                    <td class="has-text-right">2021.11.10 18:00 (UTC)</td>
                  </tr>
                  {/* <tr>
       <td>Listing On</td>
       <td class="has-text-right">
         <a class="mr-1" href="https://pancakeswap.finance/swap?outputCurrency=0x893e14FAfa8df32D9bE7eab810027eeA8828A98c" target="_blank" rel="noreferrer nofollow">Pancakeswap</a>
       </td>
     </tr> */}
                  <tr>
                    <td>Liquidity Percent</td>
                    <td class="has-text-right">70%</td>
                  </tr>
                  <tr>
                    <td>Liquidity Unlocked Time</td>
                    <td class="has-text-right">
                      <a target="_blank" rel="noopener noreferrer" href="#/pinklock/record/1847?chain=BSC">2022.03.10 17:57 UTC (in 2 months)</a>
                    </td>
                  </tr>
                  <tr>
                    <td>Total Team Vesting Tokens</td>
                    <td class="has-text-right">100,000,000,000,000 UNIMOON</td>
                  </tr>
                  <tr>
                    <td>Team Vesting Release Time</td>
                    <td class="has-text-right">2021.12.25 17:57 UTC</td>
                  </tr>
                  <tr>
                    <td>First Release After Listing (days)</td>
                    <td class="has-text-right">45 days</td>
                  </tr>
                  <tr>
                    <td>First Release For Team</td>
                    <td class="has-text-right">10%</td>
                  </tr>
                  <tr>
                    <td>Tokens release each cycle</td>
                    <td class="has-text-right">15% each 15 days</td>
                  </tr>
                </tbody>
              </table>


            </Box>
          </Grid>

          <Grid item xs={12} sm={5}>
            <Box className={classes.mainBox} mt={3}>
            <Box className={classes.dexBox} pt={4}>
             <Chart options={options} series={series} type="donut" width="100%" />
        </Box>
            </Box>

            <Box className={classes.mainBox} mt={3}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12}>
                  <Box>
                    <label>* Amount (max 0.9 BNB)</label>
                    <TextField

                      id="outlined-basic"
                      variant="outlined"

                      fullWidth
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box align="center">
                    <Button variant="contained" color="primary">
                      Buy
                    </Button>

                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h5" className={classes.textBox}>Status</Typography>
                </Grid>
                <Grid item xs={6} align="right">
                  <Typography variant="h6" className={classes.textBox1}>Inprogress</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h5" className={classes.textBox}>Sale type</Typography>
                </Grid>
                <Grid item xs={6} align="right">
                  <Typography variant="h6" className={classes.textBox}>Public</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h5" className={classes.textBox}>Minimum Buy</Typography>
                </Grid>
                <Grid item xs={6} align="right">
                  <Typography variant="h6" className={classes.textBox1}>0.01 BNB</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h5" className={classes.textBox}>Maximum Buy</Typography>
                </Grid>
                <Grid item xs={6} align="right">
                  <Typography variant="h6" className={classes.textBox1}>0.09 BNB</Typography>
                </Grid>


              </Grid>
            </Box>
         
         
            <Box className={classes.mainBox} mt={3}>
          <Typography variant="h4" align="center" style={{    fontWeight: "600",
    color:" #fff",}}>Validation</Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box className={classes.voteBox}>
                <label>Confidence</label>
                <Typography variant="h2">0%</Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box className={classes.voteBox}>
                <label>Vote</label>
                <Typography variant="h2">0%</Typography>
              </Box>
            </Grid>
            <Grid item xs={6} align="center"> <Button className={classes.upvote}> <FaThumbsUp/>Up</Button></Grid>
            <Grid item xs={6} align="center"> <Button className={classes.downvote}> <FaThumbsDown/> Down</Button></Grid>
          </Grid>
            </Box>

          </Grid>


          <Grid>

          </Grid>
        </Grid>
      </Box>



    </Box>
  )
}
