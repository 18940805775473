import React from "react";
import {
  makeStyles,
  Grid,
  Typography,
  Box,
  Card,
  CardContent,
  CardActions,
  Button,
  Accordion,
  Container,
} from "@material-ui/core";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

const useStyles = makeStyles((theme) => ({
  bannerBox: {
position:"relative",
padding: "130px 0 50px",
  },
  root: {
    borderRadius: "5px",
    // background: "#0CA9CB",
    background: "rgba(12, 42, 48, 0.3)",
    border: "1px solid #A6A6A6",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: "48px",
    color: "#FFFFFF",
    textAlign: "center",
    fontFamily: "Montserrat",
    lineHeight: "60px",
    display: "flex",
    justifyContent: "center",
  },
  title1: {
    fontSize: "14px",
    color: "#FFFFFF",
    lineHeight: "30px",
    marginTop: "50px",
  },
  pos: {
    marginBottom: 12,
  },
  root1: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
    color: "#FFFFFF",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "30px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
    },
  },
  accordion: {
    backgroundColor: "#071426",
    color: "#FFFFFF",
    marginTop: "0px",
    marginBottom: "0px",
  },
  accordion1: {
    backgroundColor: "#071426",
    color: "#FFFFFF",
    fontSize: "14px",
    fontStyle: "normal",
    lineHeight: "30px",
  },

  textbox: {
    "& h1": {
      fontSize: "50px",
      fontWeight: "bold",
      color: "white",
      marginTop: "-81px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "30px",
        marginTop: "-29px",
      },
      "& span": {
        color: "#392472",
      },
    },
    "& p": {
      color: "white", 
      fontSize: "14px", 
      maxWidth: "767px", 
      lineHeight: "25px",
    },
  
    "& small": {
      justifyContent: "center",
      "& div": {
        width: "50px",
        height: "1px",
        backgroundColor: "white",
        marginRight: "10px",
      },
      fontSize: "25px",
      color: "#392472",
      textShadow: "0px 0px 29px #392472",
      display: "flex",
      marginTop: "10px",
      alignItems: "center",
    },
  },
  cardBox:{
    border: "1px solid #A6A6A6", 
    background: "rgba(12, 42, 48, 0.3)", 
    borderRadius: "5px",
    padding:"20px",
  },
}));

function Faq() {
  const classes = useStyles();
  return (
    <Box className={classes.bannerBox}>
      <Container>
      <Grid container lg={12} xs={12}>
      <Grid container spacing={3}>
            <Grid item xs={12} sm={2}></Grid>
            <Grid item xs={12} sm={8}>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src="./images/Faq.png" style={{ maxWidth: "70%" }} />
              </Box>
              <Box className={classes.textbox} align="center">
                <Typography variant="h1">FAQ</Typography>
              </Box>
              <Box className={classes.textbox} align="center" mt={2}>
                <Typography variant="body2">
                  A suit of tools were build to help you to create your own
                  tokens and launchpads in a fast, simple and cheap way, with no
                  prior code knowledge required and 100% decentralized!
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={2}></Grid>
          </Grid>

          <Box mt={7}>
          <Grid container spacing={3}>
        <Grid item lg={4} md={6} sm={6} xs={12} >
          <Box className={classes.cardBox}>
           <Box className={classes.textbox} align="center">
                <Typography variant="h1" style={{marginTop:"0px"}}>Frequently  Asked Questions</Typography>
              </Box>
                <Typography
                  className={classes.title1}
                  //   color="textSecondary"
                  gutterBottom
                >
                  A suite of tools were built to help you create your own tokens
                  and launchpads in a fast, simple and cheap way, with no prior
                  code knowledge required and 100% decentraliz suite of tools
                  were built to help you create your own tokens and launchpads
                  in a fast, simple and cheap way,
                </Typography>
       
          </Box>
        </Grid>
        <Grid item lg={8} md={6} sm={6} xs={12} className={classes.items3}>
          <div className={classes.root1}>
            <Accordion className={classes.accordion}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  A suite of tools were built to help you create your own tokens
                  ?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className={classes.accordion1}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                  eget.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className={classes.accordion}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  A suite of tools were built to help you create your own tokens
                  ?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className={classes.accordion1}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                  eget.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className={classes.accordion}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  A suite of tools were built to help you create your own tokens
                  ?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className={classes.accordion1}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                  eget.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className={classes.accordion}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  A suite of tools were built to help you create your own tokens
                  ?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className={classes.accordion1}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                  eget.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className={classes.accordion}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  A suite of tools were built to help you create your own tokens
                  ?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className={classes.accordion1}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                  eget.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion className={classes.accordion}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  A suite of tools were built to help you create your own tokens
                  ?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className={classes.accordion1}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                  eget.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion className={classes.accordion}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  A suite of tools were built to help you create your own tokens
                  ?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className={classes.accordion1}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                  eget.
                </Typography>
              </AccordionDetails>
            </Accordion>
    
          </div>
        </Grid>

        </Grid>
        </Box>
      </Grid>
      </Container>
      </Box>
  );
}

export default Faq;
