import React from "react";
import {
  Box,
  // Container,
  Grid,
  makeStyles,
  Typography,
  Button,
  // withStyles,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
// import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableContainer from '@material-ui/core/TableContainer';
// import TableRow from '@material-ui/core/TableRow';
const useStyles = makeStyles((theme) => ({
  dialogBox: {
    padding: "30px",
  },
}));

// function createData(name, calories) {
//   return { name, calories };
// }
function Roadmap(props) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
    checkedF: true,
    checkedG: true,
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    // <Page title="GainPool">
    <Box pt={3} pb={6}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12}>
          <Box mt={2}>
            <Typography variant="h1"> Mint your own token!</Typography>
            <Grid spacing={4} container>
              <Grid item xs={12} sm={4}>
                <Box mt={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.checkedA}
                        onChange={handleChange}
                        name="checkedA"
                      />
                    }
                    label="Minting"
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={4}>
                <Box mt={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.checkedA}
                        onChange={handleChange}
                        name="checkedA"
                      />
                    }
                    label="Minting Burnable"
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={4}>
                <Box mt={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.checkedA}
                        onChange={handleChange}
                        name="checkedA"
                      />
                    }
                    label="Renounce Owner"
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box>
                  <label>Token Name</label>
                  <TextField id="outlined-basic" variant="outlined" fullWidth />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box>
                  <label>Token Symbol</label>
                  <TextField id="outlined-basic" variant="outlined" fullWidth />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box>
                  <label>
                    Total supply (excluding decimals e.g. 100 tokens)
                  </label>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="Total supply"
                    fullWidth
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box>
                  <label>Decimals (18 reccomended)</label>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="Total supply"
                    fullWidth
                  />
                </Box>
                <span>Total supply (including decimals - raw amount) = 0</span>
              </Grid>
            </Grid>
            <Box>
              {" "}
              <label>Total supply (including decimals - raw amount) = 0</label>
            </Box>
            <Box mt={1}>
              {" "}
              <label>Fee: 0.2 ETH</label>
            </Box>
            <Box mt={1} mb={3}>
              {" "}
              <label>+ 0.2% total supply</label>
            </Box>
            <Box align="center">
              <Button
                className={classes.mint}
                variant="contained"
                size="large"
                color="secondary"
              >
                MINT A NEW TOKEN
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
    // </Page>
  );
}

export default Roadmap;
