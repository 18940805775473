import React, { useState, useEffect } from "react";
import ERC20ABI from "../../abis/IERC20ABI.json";
import {
  Box,
  makeStyles,
  Typography,
  Grid,
  Button,
  Container,
  TextField,
  Tab,
  FormHelperText,
  CircularProgress,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
} from "@material-ui/core";
import Tabbar1 from "./Tabbar1";
// impoprt networkList
import { Link, useHistory } from "react-router-dom";
import { withStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import ConnectWallet from "../../components/ConnectWalletPopUp/index";
// import Tab from "@material-ui/core/Tab";

import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import { green } from "@material-ui/core/colors";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { useWeb3React } from "@web3-react/core";
import { getContract, getWeb3ContractObject, getWeb3Obj } from "../../utils";
import IERC20ABI from "../../abis/IERC20ABI.json";
import { toast } from "react-toastify";
import Web3 from "web3";
import StatelliteStakingABI from "../../abis/StatelliteStakingABI.json";
import StatelliteStakingDynamicABI from "../../abis/StatelliteStakingDynamicABI.json";
import StatelliteStakingFixedABI from "../../abis/StatelliteStakingFixedABI.json";
import StatelliteStakingDividentABI from "../../abis/StatelliteStakingDividentABI.json";
import moment from "moment";
import axios from "axios";
import apiConfig from "../../config/ApiConfig";
// import StakingTab form "./"
import {
  AdminOwner,
  ACTIVE_NETWORK,
  explorerURL,
  FixedDataCreateStake,
  DividentDataCreateStake,
  DynamicDataCreateStake,
  StatelliteStakingAddress,
  networkList,
  getNetworkDetails,
  deadAddress1,
  BinanceRouter,
  ETHRouter,
  MaticRouter,
  ETHWeth,
  BinanceWeth,
  MaticWeth,
} from "../../constants";
import LoadingButton from "../../components/LoadingButton";
import StakingTab from "../Stake/StakingTab";
import { getBase64 } from "../../services/index";
import Radio from "@material-ui/core/Radio";

import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { UserContext } from "../../context/User";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export const uploadContractHandler = async (account, cb) => {
  const web3 = (window.web3 = new Web3(window.ethereum));
  var nfttokenContract = new web3.eth.Contract(StatelliteStakingFixedABI);
  await nfttokenContract
    .deploy({
      data: FixedDataCreateStake,
      arguments: [],
    })
    .send(
      {
        from: account,
        gas: "4700000",
      },
      function (e, contract) {
        if (
          contract &&
          contract.address &&
          typeof contract.address !== "undefined"
        ) {
        }
      }
    )
    .on("error", function (error) {
      console.log("ERROR", error);
      toast.error(error.message);
      cb({
        status: false,
        address: null,
      });
    })
    .on("transactionHash", function (transactionHash) {})
    .on("receipt", async function (receipt) {
      cb({
        status: true,
        address: receipt.contractAddress,
      });
    })
    .catch((error) => {
      console.log("ERROR", error);
      // toast.error(error.message);
      cb({
        status: false,
        address: null,
      });
    });
};

export const uploadContractForDynamicHandler = async (account, cb) => {
  const web3 = (window.web3 = new Web3(window.ethereum));
  var nfttokenContract = new web3.eth.Contract(StatelliteStakingDynamicABI);

  await nfttokenContract
    .deploy({
      data: DynamicDataCreateStake,
      arguments: [],
    })
    .send(
      {
        from: account,
        gas: "4700000",
      },
      function (e, contract) {
        if (
          contract &&
          contract.address &&
          typeof contract.address !== "undefined"
        ) {
        }
      }
    )
    .on("error", function (error) {
      console.log("ERROR", error);
      toast.error(error.message);
      cb({
        status: false,
        address: null,
      });
    })
    .on("transactionHash", function (transactionHash) {})
    .on("receipt", async function (receipt) {
      cb({
        status: true,
        address: receipt.contractAddress,
      });
    })
    .catch((error) => {
      console.log("ERROR", error);
      // toast.error(error.message);
      cb({
        status: false,
        address: null,
      });
    });
};

export const uploadContractForDividentHandler = async (account, cb) => {
  const web3 = (window.web3 = new Web3(window.ethereum));
  var nfttokenContract = new web3.eth.Contract(StatelliteStakingDividentABI);

  await nfttokenContract
    .deploy({
      data: DividentDataCreateStake,
      arguments: [],
    })
    .send(
      {
        from: account,
        gas: "4700000",
      },
      function (e, contract) {
        if (
          contract &&
          contract.address &&
          typeof contract.address !== "undefined"
        ) {
        }
      }
    )
    .on("error", function (error) {
      console.log("ERROR", error);
      toast.error(error.message);
      cb({
        status: false,
        address: null,
      });
    })
    .on("transactionHash", function (transactionHash) {})
    .on("receipt", async function (receipt) {
      cb({
        status: true,
        address: receipt.contractAddress,
      });
    })
    .catch((error) => {
      console.log("ERROR", error);
      // toast.error(error.message);
      cb({
        status: false,
        address: null,
      });
    });
};

const useStyles = makeStyles((theme) => ({
  mainbox: {
    background:
      "radial-gradient(48.06% 27.42% at 84.2% 48.18%, rgba(32, 104, 188, 0.45) 0%, rgba(52, 107, 171, 0) 100%) , radial-gradient(39.41% 25.47% at 12.19% 49%, rgb(193 215 224 / 45%) 0%, rgba(221, 237, 254, 0.45) 100%) , #FFFFFF",
    paddingBottom: "100px",
    "& .MuiFormLabel-root.Mui-focused": {
      color: "black",
    },
  },
  formControl: {
    display: "flex",
    justifyContent: "center",
    minWidth: 120,
    "& .MuiInputLabel-shrink": {
      backgroundColor: " #eff7fe",
      zIndex: 1,
    },
  },
  ImgButton: {
    fontSize: "14px ",
    border: "2px solid transparent",
    fontWeight: 600,
    height: "44px ",
    background: "linear-gradient(272.26deg, #0DD0FF 36.78%, #05A4CA 86.13%)",
    color: "#fff",
    minWidth: "125px ",
    borderRadius: "10px",
    boxShadow: "inset 0px 0px 2px rgba(0, 0, 0, 0.4)",
    // boxShadow: "none ",
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      height: "45px ",
      minWidth: "120px ",
    },
    "&:hover": {
      color: "#fff",
      background: "#0c2739",

      border: "2px solid transparent",
      boxSizing: "border-box",
      backgroundClip: "padding-box, border-box",
      backgroundImage:
        "linear-gradient(#0c2739, #0c2739), linear-gradient(#2599fa, #1dcbfa)",
      backgroundOrigin: "padding-box, border-box",
    },
  },
  mainbox1: {
    paddingTop: "50px",
    paddingBottom: "20px",

    "& h1": {
      fontFamily: "'Lexend Deca', sans-serif",
      // fontFamily: "'Lexend Deca'",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "45px",
      lineHeight: "55px",
      textTransform: "capitalize",
      color: "#0E2137",
      textAlign: "center",
    },
  },
  box1: {
    border: "0.25px solid #0E2137",
    display: "flex",
    padding: "24px",
    borderRadius: "10px",
    backgroundColor: "#FFFFFF",
  },
  field1: {
    marginTop: "10px",
    width: "100%",
    color: "#4A4A4A",
    border: "1px solid #0E2137",
    cursor: "text",
    display: "inline-flex",
    borderRadius: "10px",
  },
  border1: {
    border: "1px solid #0E2137",
    padding: "30px",
    // overflow: "hidden",
    // position: "relative",
    // transition: "0.5s",
    borderRadius: "10px",
    backdropFilter: "blur(42px)",
    backgroundColor: "#FFFFFF",
    "& h4": {
      fontSize: "14px",
      fontFamily: "'Lexend Deca'",
      fontWeight: "500",
      lineHeight: "1.6",
      letterSpacing: "0.0075em",
      // color: "#000000",
    },
    "& h6": {
      fontSize: "0.75rem",
      fontFamily: "'Lexend Deca'",
      fontWeight: "400",
      lineHeight: "1.66",
      letterSpacing: "0.03333em",
      paddingTop: "2px",
    },
  },
  box2: {
    marginTop: "50px",
    border: "1px solid #0E2137",
    padding: "30px",

    transition: "0.5s",
    borderRadius: "10px",
    backdropFilter: "blur(42px)",
    backgroundColor: "#FFFFFF",
  },
  box3: {
    color: "#4A4A4A",
    // border: "1px solid #0E2137",

    fontSize: "12px",
    boxSizing: "border-box",
    fontFamily: "'Lexend Deca'",
    fontWeight: "400",
    lineHeight: "1.1876em",
    borderRadius: "5px",
  },
  // box4: {
  //   "& h4": {
  //     paddingTop: "10px",
  //     fontSize: "14px",
  //     fontFamily: "'Lexend Deca'",
  //     fontWeight: "500",
  //     lineHeight: "1.6",
  //     letterSpacing: "0.0075em",
  //     // color: "#000000",
  //   },
  // },
  btn1: {
    color: "#FFFFFF",
    border: "1px solid #fff",
    height: "50px",
    fontSize: "14px",
    minWidth: "160px",
    boxShadow: "none",
    fontWeight: "600",
    borderRadius: "50px",
    backgroundColor: "#0E2137",
    "&:hover": {
      background: "grey",
      color: "white",
    },
  },
  twobutton: {
    display: "flex",
    marginTop: "20px",
  },
  colors: { color: "white" },

  Twobtn: {
    display: "flex",
    justifyContent: "flex-end",
  },
  durationbox: {
    marginTop: "5px",
    "& h3": {
      fontSize: "16px",
      fontFamily: "'Lexend Deca'",
      fontWeight: "500",
      lineHeight: "1.6",
      letterSpacing: "0.0075em",
    },
  },
  tabbtn: {
    "& h2": {
      fontSize: "16px",
      fontFamily: "'Lexend Deca'",
      fontWeight: "500",
      lineHeight: "1.6",
      letterSpacing: "0.0075em",
    },
  },

  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
    marginTop: "30px",
    "& h6": {
      fontSize: "16px",
      fontFamily: "'Lexend Deca'",
      fontWeight: "500",
      lineHeight: "1.6",
      letterSpacing: "0.0075em",
      paddingBottom: "10px",
    },
  },
  // durationbox: {
  //   marginTop: "5px",
  //   "& h3": {
  //     fontSize: "16px",
  //     fontFamily: "'Lexend Deca'",
  //     fontWeight: "500",
  //     lineHeight: "1.6",
  //     letterSpacing: "0.0075em",
  //     // paddingBottom: "10px",
  //   },
  // },
  box4: {
    marginTop: "1rem",
    "& h2": {
      fontSize: "16px",
      fontFamily: "'Lexend Deca'",
      fontWeight: "500",
      lineHeight: "1.6",
      letterSpacing: "0.0075em",
      paddingBottom: "10px",
    },
    "& h4": {
      fontSize: "16px",
      fontFamily: "'Lexend Deca'",
      fontWeight: "500",
      lineHeight: "1.6",
      letterSpacing: "0.0075em",
      paddingTop: "10px",
    },
  },
  Dynamicbox: {
    "& h3": {
      fontSize: "16px",
      fontFamily: "'Lexend Deca'",
      fontWeight: "500",
      lineHeight: "1.6",
      letterSpacing: "0.0075em",
      paddingTop: "10px",
    },
  },
  box30: {
    width: "160px",
    // paddingTop: "15px",
    paddingBottom: "15px",
  },
}));
const styles = (theme) => ({
  colors: { color: "white" },
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  Twobtn: {
    display: "flex",
    justifyContent: "space-between",
  },
});
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);
const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);
export default function Maindashboard(props) {
  var classes = useStyles();
  const history = useHistory();
  const [network, setNetwork] = React.useState("");
  const [month, setMonth] = React.useState("");
  const [isSubmit, setIsSubmit] = useState("");
  const { account, chainId, library } = useWeb3React();
  const user = React.useContext(UserContext);
  // console.log(
  //   user.controlData?.commisionStacking,
  //   "------------------------------chainId---++++++++&*******chainId"
  // );
  const [tokenName, setTokenName] = useState("");
  const [tokenSymbol, setTokenSymbol] = useState("");
  const [totalSupply, settotalSupply] = useState(0);
  const [decimals, setDecimals] = useState(0);
  const [tokenName1, setTokenName1] = useState("");
  const [decimals12, setDecimals12] = useState(0);
  const [tokenName12, setTokenName12] = useState("");
  const [tokenSymbol1, setTokenSymbol1] = useState("");
  const [totalSupply1, settotalSupply1] = useState(0);
  const [decimals1, setDecimals1] = useState(0);
  const [formData, setFormData] = useState({
    coinName: "",
    tokenAddress: "",
    rewardAddress: "",
    rewardsPerBlock: "",
    withdrawInterval: "",
    harvestInterval: "",
    rewardSupply: "",
    dividentAddress: "",
  });
  const [errorValidTokenAddress, setErrorValidTokenAddress] = useState(false);
  const [errorValidDivTokenAddress, setErrorValidDivTokenAddress] =
    useState(false);
  const [errorValidTokenAddress1, setErrorValidTokenAddress1] = useState(false);
  const [createdAddress, setCreatedAddress] = useState("");
  const [updatingData, setUpdatingData] = useState();
  const [isDeployed, setIsDeployed] = useState(false);
  const [nameChangesButton, setnameChangesButton] = useState("Burn Token");
  const [errorValidRewardTokenAddress, setErrorValidRewardTokenAddress] =
    useState(false);
  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
    checkedF: true,
    checkedG: true,
    checkedH: true,
    checkedI: true,
    checkedJ: true,
  });

  const httpProvider = new Web3.providers.HttpProvider(
    "https://bsc-dataseed1.ninicoin.io"
  );
  const web31 = new Web3(httpProvider);

  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [checked3, setChecked3] = React.useState(true);
  const [checked6, setChecked6] = React.useState(false);
  const [checked9, setChecked9] = React.useState(false);
  const [checked12, setChecked12] = React.useState(false);
  const [checked18, setChecked18] = React.useState(false);
  const [checked24, setChecked24] = React.useState(false);
  const [selectMonth, setSelectMonth] = React.useState("Month");
  const [selectTime, setSelectTime] = React.useState("");
  const [selectBurn, setSelectBurn] = React.useState(false);
  const [selectBurn1, setSelectBurn1] = React.useState(false);
  const [APR, setAPR] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    console.log("sdftgyhujikol", index);
    setValue(index);
  };
  const [selectedValue, setSelectedValue] = React.useState("a");

  const handleChangeRadio = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleChangeNetwork = (event) => {
    setNetwork(event.target.value);
  };

  const [open, setOpen] = React.useState(false);
  const [openStake, setOpenstake] = React.useState(false);

  const handleOpenStake = () => {
    // setIsSubmit(true);
    if (chainId == 56) {
      console.log("not error");
      setOpenstake(true);
    } else {
      swichNetworkHandler(56);
    }
  };

  const handleClickOpen = async () => {
    setIsSubmit(true);
    // if (value === 1) {
    //   if (
    //     formData.rewardsPerBlock == "" &&
    //     Number(formData.rewardsPerBlock) < 0
    //   ) {
    //     setIsDeployed(false);
    //     return;
    //   }
    // }
    // if (value === 2) {
    //   if (
    //     errorValidDivTokenAddress &&
    //     formData.dividentAddress == "" &&
    //     Number(formData.dividentAddress) < 0
    //   ) {
    //     setIsDeployed(false);
    //     return;
    //   }
    // }
    if (value === 0) {
      if (
        !errorValidTokenAddress1 &&
        !errorValidRewardTokenAddress &&
        network !== "" &&
        poolImage64 !== "" &&
        formData.tokenAddress != "" &&
        formData.rewardAddress != "" &&
        formData.rewardSupply != "" &&
        Number(formData.rewardSupply) > 0
      ) {
        setOpen(true);
      }
    }
    if (value === 1) {
      if (
        !errorValidTokenAddress1 &&
        !errorValidRewardTokenAddress &&
        network !== "" &&
        poolImage64 !== "" &&
        formData.tokenAddress != "" &&
        formData.rewardAddress != "" &&
        formData.rewardSupply != "" &&
        formData.rewardsPerBlock &&
        Number(formData.rewardsPerBlock) > 0 &&
        Number(formData.rewardSupply) > 0
      ) {
        // if (value == 0 && selectedValue === "a") {
        //   if (APR == "" && Number(APR) < 0) {
        //     if (
        //       checked3 ||
        //       checked6 ||
        //       checked9 ||
        //       checked12 ||
        //       checked18 ||
        //       checked24
        //     ) {
        //       setIsDeployed(false);
        //       return;
        //     }
        //   }
        // }
        // if (value == 0 && selectedValue === "b") {
        //   if (
        //     APR == "" &&
        //     selectTime === "" &&
        //     selectTime == "" &&
        //     selectTime < 0 &&
        //     Number(APR) < 0
        //   ) {
        //     setIsDeployed(false);
        //     return;
        //   }
        // }
        setOpen(true);
      }
    }
    if (value === 2) {
      if (
        !errorValidDivTokenAddress &&
        !errorValidTokenAddress1 &&
        !errorValidRewardTokenAddress &&
        network !== "" &&
        poolImage64 !== "" &&
        formData.tokenAddress != "" &&
        formData.rewardAddress != "" &&
        formData.rewardSupply != "" &&
        formData.rewardsPerBlock &&
        Number(formData.rewardsPerBlock) > 0 &&
        Number(formData.rewardSupply) > 0
      ) {
        // if (value == 0 && selectedValue === "a") {
        //   if (APR == "" && Number(APR) < 0) {
        //     if (
        //       checked3 ||
        //       checked6 ||
        //       checked9 ||
        //       checked12 ||
        //       checked18 ||
        //       checked24
        //     ) {
        //       setIsDeployed(false);
        //       return;
        //     }
        //   }
        // }
        // if (value == 0 && selectedValue === "b") {
        //   if (
        //     APR == "" &&
        //     selectTime === "" &&
        //     selectTime == "" &&
        //     selectTime < 0 &&
        //     Number(APR) < 0
        //   ) {
        //     setIsDeployed(false);
        //     return;
        //   }
        // }
        setOpen(true);
      }
    }
  };
  const handleClose = () => {
    setOpen(false);
    // setOpenstake(false);
  };
  const handleCloseStake = () => {
    // setOpen(false);
    setOpenstake(false);
  };
  const _onInputChange = (e) => {
    const temp = { ...formData, [e.target.name]: e.target.value };
    setFormData(temp);
    // setIsApproved(false);
  };
  useEffect(() => {
    if (formData.rewardAddress && formData.rewardAddress != "") {
      getRewardTokenAddress();
    } else {
      setDecimals1("");
      setTokenName1("");
      setTokenSymbol1("");
      settotalSupply1("");
    }
  }, [formData.rewardAddress, account, chainId]);

  const getRewardTokenAddress = async () => {
    // console.log("formData.tokenAddress", formData.tokenAddress);
    // console.log("account", account);
    if (account) {
      try {
        if (window.ethereum) {
          const web3 = await getWeb3Obj();

          // console.log("Web3", web3);
          const dataRes = web3.utils.isAddress(formData.rewardAddress);
          if (dataRes) {
            setErrorValidRewardTokenAddress(false);
            const contract = getContract(
              formData.rewardAddress,
              IERC20ABI,
              library,
              account
            );
            // console.log("ContractObj", contract);
            const name = await contract.name();
            const decimals = await contract.decimals();
            setDecimals1(decimals);
            setTokenName1(name);
            // console.log("name", name);
            // console.log("decimals", decimals);
            const symbol = await contract.symbol();
            setTokenSymbol1(symbol);
            // console.log("symbol", symbol);
            const totalSupply = await contract.totalSupply();
            // console.log("totalSupply", totalSupply);
            settotalSupply1(web3.utils.fromWei(totalSupply.toString()));
          } else {
            setDecimals1("");
            setTokenName1("");
            setTokenSymbol1("");
            settotalSupply1("");
            setErrorValidRewardTokenAddress(true);
            // toast.error("Please enter valid token address");
          }
        }
      } catch (error) {
        console.log("error", error);
        setDecimals1("");
        setTokenName1("");
        setTokenSymbol1("");
        settotalSupply1("");
        setErrorValidRewardTokenAddress(true);
        toast.error("Please enter valid token address");
      }
    } else {
      toast.error("Connect wallet");
    }
  };

  useEffect(() => {
    if (formData.tokenAddress && formData.tokenAddress != "") {
      getTokenAddressDetails();
    } else {
      setDecimals("");
      setTokenName("");
      setTokenSymbol("");
      settotalSupply("");
    }
  }, [formData.tokenAddress, account, chainId]);

  const getTokenAddressDetails = async () => {
    if (account) {
      try {
        if (window.ethereum) {
          const web3 = await getWeb3Obj();

          // console.log("Web3", web3);
          const dataRes = web3.utils.isAddress(formData.tokenAddress);
          if (dataRes) {
            setErrorValidTokenAddress1(false);
            const contract = getContract(
              formData.tokenAddress,
              IERC20ABI,
              library,
              account
            );
            // console.log("ContractObj", contract);
            const name = await contract.name();
            const decimals = await contract.decimals();
            setTokenName(name);
            setDecimals(decimals);
            const symbol = await contract.symbol();
            setTokenSymbol(symbol);
            console.log("symbol", symbol);
            const totalSupply = await contract.totalSupply();
            console.log("totalSupply", totalSupply);
            settotalSupply(web3.utils.fromWei(totalSupply.toString()));
            console.log("decimals", decimals);
          } else {
            setDecimals("");
            setTokenName("");
            setTokenSymbol("");
            settotalSupply("");
            setErrorValidTokenAddress1(true);
            // toast.error("Please enter valid token address");
          }
        }
      } catch (error) {
        setDecimals("");
        setTokenName("");
        setTokenSymbol("");
        settotalSupply("");
        console.log("error", error);
        setErrorValidTokenAddress1(true);
        toast.error("Please enter valid token address");
      }
    } else {
      toast.error("Connect wallet");
    }
  };

  useEffect(() => {
    if (formData.dividentAddress && formData.dividentAddress != "") {
      getDividentTokenAddressDetails();
    } else {
      setDecimals12("");
      setTokenName12("");
      setTokenSymbol("");
      settotalSupply("");
    }
  }, [formData.dividentAddress, account, chainId]);

  const getDividentTokenAddressDetails = async () => {
    if (account) {
      try {
        if (window.ethereum) {
          const web3 = await getWeb3Obj();

          console.log("formData.dividentAddress", formData.dividentAddress);
          const dataRes = web3.utils.isAddress(formData.dividentAddress);
          if (dataRes) {
            setErrorValidDivTokenAddress(false);
            const contract = getContract(
              formData.dividentAddress,
              IERC20ABI,
              library,
              account
            );
            console.log("formData.dividentAddress", contract);
            const name = await contract.name();
            const decimals = await contract.decimals();
            setTokenName12(name);
            setDecimals12(decimals);
            console.log("formData.dividentAddress", name);
          } else {
            setErrorValidDivTokenAddress(true);
            setDecimals("");
            setTokenName("");
            setTokenSymbol("");
            settotalSupply("");
            // toast.error("Please enter valid token address");
          }
        }
      } catch (error) {
        setDecimals("");
        setTokenName("");
        setTokenSymbol("");
        settotalSupply("");
        console.log("error", error);
        setErrorValidDivTokenAddress(true);
        toast.error("Please enter valid token address");
      }
    } else {
      toast.error("Connect wallet");
    }
  };
  const sourceCodeFinder = async (searchToken) => {
    let count = [];
    try {
      const resBSC = await axios({
        method: "GET",
        url: `https://api.bscscan.com/api?module=contract&action=getsourcecode&address=${searchToken}&apikey=YourApiKeyToken`,
      });
      console.log(resBSC);
      if (resBSC.status === 200) {
        if (resBSC?.data?.result[0]?.SourceCode !== "") {
          // count.push(1);
        }
        const resETH = await axios({
          method: "GET",
          url: `https://api.etherscan.io/api?module=contract&action=getsourcecode&address=${searchToken}&apikey=YourApiKeyToken`,
        });
        if (resETH.status === 200) {
          if (resETH?.data?.result[0]?.SourceCode !== "") {
            // count.push(2);
          }
          const resPolyGon = await axios({
            method: "GET",
            url: `https://api.polygonscan.com/api?module=contract&action=getsourcecode&address=${searchToken}&apikey=YourApiKeyToken`,
          });
          if (resPolyGon.status === 200) {
            if (resPolyGon?.data?.result[0]?.SourceCode !== "") {
              // count.push(3);
            }
          }
          if (
            resBSC?.data?.result[0]?.SourceCode === "" &&
            resETH?.data?.result[0]?.SourceCode === "" &&
            resPolyGon?.data?.result[0]?.SourceCode === ""
          ) {
            toast.error("Report not found!! Please submit your token request.");
            // setIsLoadingSearchedData(0);
            // setSearchToken("");
            // setIsSearchTrue(false);
          }
        }
        // setNetworkcount(count);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const deployContract = async (id) => {
    console.log(id);
    setIsDeployed(true);
    setIsSubmit(true);
    if (network == "") {
      toast.warn("Plese select network ");
      setIsDeployed(false);
      return;
    }
    console.log(chainId, "-------123", network.chainId);
    if (chainId == network.chainId) {
      const web3 = await getWeb3Obj();
      if (
        poolImage64 !== "" &&
        formData.tokenAddress != "" &&
        formData.rewardAddress != "" &&
        formData.rewardSupply != "" &&
        // formData.rewardsPerBlock != "" &&
        Number(formData.rewardSupply) > 0 &&
        // Number(formData.rewardsPerBlock) > 0 &&
        web3.utils.isAddress(formData.tokenAddress) &&
        web3.utils.isAddress(formData.rewardAddress)
        // moment(formData.withdrawInterval).unix() > moment(formData.harvestInterval).unix()
      ) {
        if (value === 1) {
          if (
            formData.rewardsPerBlock == "" &&
            Number(formData.rewardsPerBlock) < 0
          ) {
            setIsDeployed(false);
            return;
          }
        }
        if (value === 2) {
          if (
            formData.dividentAddress == "" &&
            Number(formData.dividentAddress) < 0
          ) {
            setIsDeployed(false);
            return;
          }
        }
        if (value == 0 && selectedValue === "a") {
          if (APR == "" && Number(APR) < 0) {
            if (
              checked3 ||
              checked6 ||
              checked9 ||
              checked12 ||
              checked18 ||
              checked24
            ) {
              setIsDeployed(false);

              return;
            }
          }
        }
        if (value == 0 && selectedValue === "b") {
          if (
            APR == "" &&
            selectTime == "" &&
            selectTime < 0 &&
            Number(APR) < 0
          ) {
            setIsDeployed(false);
            return;
          }
        }
        try {
          console.log(id);

          // if (!tokenName) {
          setErrorValidTokenAddress(false);
          const contract1 = getContract(
            formData.tokenAddress,
            IERC20ABI,
            library,
            account
          );
          // console.log("ContractObj", contract);
          const name = await contract1.name();
          const decimals = await contract1.decimals();
          setTokenName(name);
          setDecimals(decimals);
          // }
          // if (!tokenName1) {
          const contract = getContract(
            formData.rewardAddress,
            IERC20ABI,
            library,
            account
          );
          // console.log("ContractObj", contract);
          const name1 = await contract.name();
          const decimals1 = await contract.decimals();
          setDecimals1(decimals1);
          setTokenName1(name1);
          // }
          // if (id) {
          //   setnameChangesButton("Burning Satellite Token");
          //   // setUpdatingData('deploy');
          //   const setLiteBurnAmount = 50;
          //   const BurnContract = getContract(
          //     StatelliteStakingAddress,
          //     StatelliteStakingABI,
          //     library,
          //     account
          //   );
          //   console.log("BurnContract", BurnContract);
          //   const erc20address = await BurnContract.pool();
          //   console.log("stake--------", erc20address.stake);
          //   const contractBurnErc = getContract(
          //     erc20address.stake,
          //     ERC20ABI,
          //     library,
          //     account
          //   );
          //   const amountToSend = web31.utils.toWei(
          //     setLiteBurnAmount.toString()
          //   );
          //   console.log("amountToSend", amountToSend);
          //   const balanceOfValue = await contractBurnErc.balanceOf(account);
          //   console.log("balanceOfValue", balanceOfValue.toString());
          //   if (Number(balanceOfValue.toString()) < amountToSend) {
          //     setIsDeployed(false);
          //     return;
          //   }
          //   // console.log("----------------23456778890pojn", contractBurnErc);
          //   const res = await contractBurnErc.transfer(
          //     deadAddress1,
          //     amountToSend
          //   );
          //   console.log(res);
          // }

          const web3 = await getWeb3Obj();

          setnameChangesButton("Approve Reward Token");
          const approveContract = getContract(
            formData.rewardAddress,
            IERC20ABI,
            library,
            account
          );
          const balanceOf = await approveContract.balanceOf(account);
          const balanceOfWei = web3.utils.fromWei(balanceOf.toString());
          console.log("balanceOfWei", balanceOfWei);
          if (Number(balanceOfWei) > Number(formData.rewardSupply)) {
            setnameChangesButton(`Deploy Contract`);

            if (value === 0) {
              /******************** -----Fixed----- ******************** */
              await uploadContractHandler(account, (result) => {
                if (result.status && result.address) {
                  console.log("result.address", result.address);
                  setCreatedAddress(result.address);
                  // setIsDeployed(true);
                  initializeHandler(result.address);
                  // console.log(`${explorerURL}/address/${result.address}`);
                  // window.open(`${explorerURL}/address/${result.address}`);
                  // setnameChangesButton("")
                } else {
                  setIsDeployed(false);
                  setCreatedAddress("");
                  // setIsDeployed(false);
                }
              });
            }
            if (value === 1) {
              /******************** -----Dynimic----- ******************** */
              await uploadContractForDynamicHandler(account, (result) => {
                if (result.status && result.address) {
                  console.log("result.address", result.address);
                  console.log(`${explorerURL}/address/${result.address}`);
                  window.open(`${explorerURL}/address/${result.address}`);
                  setCreatedAddress(result.address);
                  setIsDeployed(true);
                  initializeHandler(result.address);
                  // setnameChangesButton("")
                } else {
                  setIsDeployed(false);
                  setCreatedAddress("");
                  // setIsDeployed(false);
                }
              });
            }
            if (value === 2) {
              /******************** -----Dynimic----- ******************** */
              await uploadContractForDividentHandler(account, (result) => {
                if (result.status && result.address) {
                  console.log("result.address", result.address);
                  console.log(`${explorerURL}/address/${result.address}`);
                  window.open(`${explorerURL}/address/${result.address}`);
                  setCreatedAddress(result.address);
                  setIsDeployed(true);
                  initializeHandler(result.address);
                  // setnameChangesButton("")
                } else {
                  setIsDeployed(false);
                  setCreatedAddress("");
                  // setIsDeployed(false);
                }
              });
            }
            // setUpdatingData();
          } else {
            setIsDeployed(false);
            toast.error("Low Balance");
            // setUpdatingData();
          }
        } catch (error) {
          setIsDeployed(false);
          // setUpdatingData();
          setCreatedAddress("");
          // setIsDeployed(false);
        }
      } else {
        setIsDeployed(false);
        if (!web3.utils.isAddress(formData.tokenAddress)) {
          toast.error("Enter valid stack address");
        } else if (!web3.utils.isAddress(formData.rewardAddress)) {
          toast.error("Enter valid reward currency address");
        } else {
          toast.error("Please enter valid data");
          console.log(formData.rewardAddress);
        }
      }
    } else {
      setIsDeployed(false);
      // swichNetworkH();
      // toast.warn("Switch Network");
      swichNetworkHandler(network.chainId);
      toast.warn("Please swich network to " + network.name);
    }
  };

  useEffect(() => {
    if (network.chainId != chainId) {
      swichNetworkHandler(network.chainId);
    }
  }, [network.chainId]);
  const swichNetworkHandler = async (id) => {
    if (window.ethereum) {
      console.log(id);
      console.log(window.ethereum);
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: "0x" + parseFloat(id).toString(16) }],
        });
      } catch (error) {
        console.log("ERROR", error);
        // toast.warn(error.message);
        if (error.code === 4902) {
          addNetworkHandler(id);
        }
      }
    }
  };

  const addNetworkHandler = async (id) => {
    const NetworkDetails = getNetworkDetails(id);
    try {
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: NetworkDetails,
      });
    } catch (error) {
      console.log("ERROR", error);
      toast.warn(error.message);
    }
  };
  const initializeHandler = async (ids_createdAddress) => {
    // if (chainId == ACTIVE_NETWORK) {
    if (ids_createdAddress && ids_createdAddress.length > 0) {
      setnameChangesButton("Starting Pool");
      try {
        setUpdatingData("create");

        const web3 = await getWeb3Obj();

        const approveContract = getContract(
          formData.rewardAddress,
          IERC20ABI,
          library,
          account
        );

        /********************** ---------Fixed--------- ********************** */
        if (value === 0) {
          let oneDayTimeFrame = 86400;
          let oneMonthTimeFrame = 2629746;
          let totalTimeFrame;
          let timeDurationMonth;
          if (value == 0 && selectedValue === "a") {
            if (checked3) {
              timeDurationMonth = 3 * oneMonthTimeFrame;
            }
            if (checked6) {
              timeDurationMonth = 6 * oneMonthTimeFrame;
            }
            if (checked9) {
              timeDurationMonth = 9 * oneMonthTimeFrame;
            }
            if (checked12) {
              timeDurationMonth = 12 * oneMonthTimeFrame;
            }
            if (checked18) {
              timeDurationMonth = 18 * oneMonthTimeFrame;
            }
            if (checked24) {
              timeDurationMonth = 24 * oneMonthTimeFrame;
            }
          }
          if (value == 0 && selectedValue === "b") {
            console.log(selectMonth);
            if (selectMonth === "Days") {
              timeDurationMonth = selectTime * oneDayTimeFrame;
            }
            if (selectMonth === "Month") {
              timeDurationMonth = selectTime * oneMonthTimeFrame;
            }
            if (selectMonth === "Years") {
              timeDurationMonth = selectTime * oneMonthTimeFrame * 12;
            }
          }
          console.log(
            timeDurationMonth,
            "timeDurationMonth--------",
            timeDurationMonth,
            "-------timeDurationMonth",
            timeDurationMonth
          );

          const contractObj = getContract(
            ids_createdAddress,
            StatelliteStakingFixedABI,
            library,
            account
          );
          console.log(
            ids_createdAddress,
            "new---------------------contractObj+++++++++++++++++++++++++==",
            contractObj
          );
          const Aprwei = parseFloat(APR / 105120).toFixed(12);
          const initializeRes = await contractObj.startPool(
            formData.tokenAddress,
            formData.rewardAddress,
            web3.utils.toWei(Aprwei.toString()),
            timeDurationMonth
          );
          console.log("contractObj", contractObj);
          await initializeRes.wait();
          createStackingAPIHandler(ids_createdAddress);
          const res = await approveContract.transfer(
            ids_createdAddress,
            web3.utils.toWei(formData.rewardSupply.toString())
          ); //*******************************--------------  ask according reward supply */
          await res.wait();
          setnameChangesButton("Updating Pool");
          toast.success("Success");
          history.push("/app/stake-pools");
          setUpdatingData();
          setIsDeployed(false);
          setIsDeployed(false);
        }
        /********************** ---------Dynamic--------- ********************** */
        if (value === 1) {
          const contractObj = getContract(
            ids_createdAddress,
            StatelliteStakingDynamicABI,
            library,
            account
          );
          console.log(
            ids_createdAddress,
            "new---------------------contractObj+++++++++++++++++++++++++==",
            contractObj
          );
          const initializeRes = await contractObj.startPool(
            formData.tokenAddress,
            formData.rewardAddress,
            web3.utils.toWei(formData.rewardsPerBlock.toString())
          );
          console.log("contractObj", contractObj);
          await initializeRes.wait();
          createStackingAPIHandler(ids_createdAddress);
          const res = await approveContract.transfer(
            ids_createdAddress,
            web3.utils.toWei(formData.rewardSupply)
          ); //*******************************--------------  ask according reward supply */
          await res.wait();
          setnameChangesButton("Updating Pool");
          toast.success("Success");
          // /app/stake-pools
          history.push("/app/stake-pools");
          setUpdatingData();
          setIsDeployed(false);
          setIsDeployed(false);
        }
        /********************** ---------Divident--------- ********************** */
        if (value === 2) {
          const contractObj = getContract(
            ids_createdAddress,
            StatelliteStakingDividentABI,
            library,
            account
          );
          console.log(
            ids_createdAddress,
            "new---------------------contractObj+++++++++++++++++++++++++==",
            contractObj
          );
          let weth;
          let router1;
          if (chainId == 1) {
            weth = ETHWeth;
            router1 = ETHRouter;
          }
          if (chainId == 56) {
            weth = BinanceWeth;
            router1 = BinanceRouter;
          }
          if (chainId == 137) {
            weth = MaticWeth;
            router1 = MaticRouter;
          }
          let earnedToStakedPath = [];
          if (formData.tokenAddress !== formData.rewardAddress) {
            earnedToStakedPath = [
              formData.rewardAddress,
              weth,
              formData.tokenAddress,
            ];
          }
          let reflectionToStakedPath = [];
          if (formData.tokenAddress !== formData.dividentAddress) {
            reflectionToStakedPath = [
              formData.dividentAddress,
              weth,
              formData.tokenAddress,
            ];
          }
          console.log("reflectionToStakedPath", reflectionToStakedPath);
          console.log("earnedToStakedPath", earnedToStakedPath);
          const initializeRes = await contractObj.initialize(
            formData.tokenAddress,
            formData.rewardAddress,
            formData.dividentAddress,
            web3.utils.toWei(formData.rewardsPerBlock.toString()),
            router1,
            earnedToStakedPath,
            reflectionToStakedPath
          );
          console.log("contractObj", contractObj);
          await initializeRes.wait();
          createStackingAPIHandler(ids_createdAddress);
          const res = await approveContract.transfer(
            ids_createdAddress,
            web3.utils.toWei(formData.rewardSupply)
          ); //*******************************--------------  ask according reward supply */
          await res.wait();
          setnameChangesButton("Updating Pool");
          toast.success("Success");
          // /app/stake-pools
          history.push("/app/stake-pools");
          setUpdatingData();
          setIsDeployed(false);
          setIsDeployed(false);
        }
      } catch (error) {
        console.log("ERROR", error);
        toast.error(error.message);
        setUpdatingData();
        setIsDeployed(false);
        setIsDeployed(false);
      }
    } else {
      setIsDeployed(false);
      toast.error("Please deploy contract");
    }
    // } else {
    //   setIsDeployed(false);
    //   // swichNetworkHandler();
    // }
  };

  const createStackingAPIHandler = async (ids_createdAddress) => {
    console.log(ids_createdAddress);
    try {
      const res = await axios.post(
        apiConfig.createStacking,
        {
          contractId: ids_createdAddress,
          coinName: network.chainId.toString(),
          image: poolImage64,
          tokenAddress: {
            contractAddress: formData.tokenAddress,
            name: tokenName,
            decimal: decimals,
          },
          rewardAddress: {
            contractAddress: formData.rewardAddress,
            name: tokenName1,
            decimal: decimals1,
          },
          fix: value === 0 ? true : false,
          dynamic: value === 1 ? true : false,
          reflection: value === 2 ? true : false,
          // stackAddress: formData.tokenAddress,
          // rewardAddress: formData.rewardAddress,
          // ids_createdAddress,
        }
        // {
        //   headers: {
        //     token: sessionStorage.getItem("token"),
        //   },
        // }
      );
      console.log("res", res);
      setnameChangesButton("Success");
      // user.getStackingListAPIHandler();
    } catch (error) {
      console.log("EROR", error);
    }
  };

  const [poolImage64, setPoolImage64] = useState("");
  const [poolImage, setPoolImage] = useState("");
  const setImagesTo = async (e) => {
    console.log("e.target.files[0]", e.target.files[0]);
    try {
      const FormValue = new FormData();
      FormValue.append("file", e.target.files[0]);
      // const res = await axios.post(apiConfig.uploadFile);
      const res = await axios({
        method: "POST",
        url: apiConfig.uploadFile,
        data: FormValue,
      });
      console.log("res", res);
      // user.getStackingListAPIHandler();
      if (res.data.statusCode === 200) {
        setPoolImage64(res.data.result);
      }
    } catch (error) {
      console.log("EROR", error);
    }
  };

  const BurnSateliteToken = async () => {
    if (chainId === 56) {
      try {
        setIsDeployed(true);
        setnameChangesButton("Burning Satellite Token");
        // setUpdatingData('deploy');
        const setLiteBurnAmount = user.controlData?.satelliteBurn
          ? user.controlData?.satelliteBurn
          : 50;
        console.log(account, "AdminOwner", user.controlData?.commisionStacking);
        const web3 = (window.web3 = new Web3(window.ethereum));
        // const transfer = await web3.eth.sendTransaction(
        //   {
        //     from: account,
        //     to: AdminOwner,
        //     value: web3.utils.toWei(
        //       user.controlData?.commisionStacking.toString()
        //     ),
        //   },
        //   function async(err, transactionHash) {
        //     if (err) {
        //       console.log("Payment failed Error", err);
        //       return;
        //     } else {
        //     }
        //   }
        // );

        const BurnContract = getContract(
          StatelliteStakingAddress,
          StatelliteStakingABI,
          library,
          account
        );
        console.log(
          user.controlData?.satelliteBurn,
          "BurnContract",
          BurnContract
        );
        const erc20address = await BurnContract.pool();
        console.log("stake--------", erc20address.stake);
        const contractBurnErc = getContract(
          erc20address.stake,
          ERC20ABI,
          library,
          account
        );
        const amountToSend = web31.utils.toWei(
          user.controlData?.satelliteBurn.toString()
        );
        console.log("amountToSend", amountToSend);
        const balanceOfValue = await contractBurnErc.balanceOf(account);
        console.log("balanceOfValue", balanceOfValue.toString());
        if (Number(balanceOfValue.toString()) < amountToSend) {
          setIsDeployed(false);
          toast.error(
            `Low Balance You Need to burn
              ${user.controlData?.satelliteBurn}`
          );
          return;
        }
        // console.log("----------------23456778890pojn", contractBurnErc);
        const res = await contractBurnErc.transfer(deadAddress1, amountToSend);
        await res.wait();
        setSelectBurn(true);
        setIsDeployed(false);
        console.log(res);
      } catch (error) {
        console.log(error);
        setnameChangesButton("Burn Token");
        setIsDeployed(false);
      }
    } else {
      swichNetworkHandler(56);
    }
  };
  console.log("value", value);

  const [openWalletConnect, setOpenWalletConnect] = React.useState(false);

  return (
    <Box className={classes.mainbox}>
      <Container>
        <Box className={classes.mainbox1}>
          <Typography variant="h1">Create Stake</Typography>
        </Box>
        <Box sx={{ minWidth: 120 }} mt={3} style={{ height: "42px" }}>
          <FormControl
            fullWidth
            className={classes.formControl}
            style={{ height: "42px" }}
          >
            <InputLabel
              style={{ padding: "0 5px" }}
              id="demo-simple-select-label"
            >
              Select Coin
            </InputLabel>
            <Select
              MenuProps={{
                disableScrollLock: true,
              }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={network.chainId}
              label="Select Coin"
              onChange={handleChangeNetwork}
              error={
                isSubmit && network === ""
                // errorValidRewardTokenAddress
              }
            >
              {/* <MenuItem> Coin Name</MenuItem> */}
              {networkList.map((data, i) => {
                return (
                  <MenuItem key={data.name} value={data}>
                    {data.symbol}
                  </MenuItem>
                );
              })}
            </Select>
            {isSubmit && network == "" && (
              <FormHelperText error>Please select coin type</FormHelperText>
            )}
          </FormControl>
        </Box>
        <Box className={classes.box11}>
          <Box className={classes.box2}>
            <Grid container>
              <Grid item xs={12} sm={6} lg={6}>
                <label
                  for="fname"
                  style={{ color: "#000" }}
                  className="textfeildbox"
                >
                  Select Image
                </label>
                <Box mt={1} className="mainBox">
                  <input
                    accept="image/*"
                    style={{ display: "none" }}
                    id="raised-button-file"
                    multiple
                    type="file"
                    onChange={(e) => {
                      setPoolImage(URL.createObjectURL(e.target.files[0]));
                      getBase64(e.target.files[0], (result) => {
                        setPoolImage64(result);
                      });
                    }}
                  />
                  <label htmlFor="raised-button-file">
                    <Button
                      variant="contained"
                      color="secondary"
                      component="span"
                      size="large"
                      className={classes.ImgButton}
                    >
                      CHOOSE FILE
                    </Button>
                  </label>
                  {isSubmit && poolImage == "" && (
                    <FormHelperText error>Please select Image</FormHelperText>
                  )}
                </Box>{" "}
              </Grid>
              {poolImage && (
                <Grid item xs={12} sm={6} lg={6} style={{ textAlign: "end" }}>
                  <Box mt={2} className="mainBox">
                    <img height={150} src={poolImage} alt="" />
                  </Box>
                </Grid>
              )}
            </Grid>
            <Box className={classes.box4}>
              <Typography variant="h4">Token Contract Address</Typography>
              <Box className={classes.box3}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  // label="Outlined"
                  // autocomplete="off"
                  autoComplete="nope"
                  variant="outlined"
                  name="tokenAddress"
                  value={formData.tokenAddress}
                  onChange={_onInputChange}
                  error={
                    (isSubmit && formData.tokenAddress === "") ||
                    errorValidTokenAddress1
                  }
                  helperText={
                    (isSubmit &&
                      formData.tokenAddress === "" &&
                      "Please enter address") ||
                    (errorValidTokenAddress1 &&
                      "Please enter a valid token address")
                  }
                />
              </Box>
            </Box>
            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Box className={classes.box4}>
                  <Typography variant="h4">Name</Typography>
                  <Box className={classes.box3}>
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      // label="Outlined"
                      variant="outlined"
                      value={tokenName}
                      disabled
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Box className={classes.box4}>
                  <Typography variant="h4">Decimal</Typography>
                  <Box className={classes.box3}>
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      // label="Outlined"
                      variant="outlined"
                      value={decimals}
                      disabled
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Box className={classes.box4}>
                  <Typography variant="h4">Reward Contract Address</Typography>
                  <Box className={classes.box3}>
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      // label="rewardAddress"
                      variant="outlined"
                      name="rewardAddress"
                      // autocomplete="off"
                      autoComplete="nope"
                      value={formData.rewardAddress}
                      onChange={_onInputChange}
                      error={
                        (isSubmit && formData.rewardAddress === "") ||
                        errorValidRewardTokenAddress
                      }
                      helperText={
                        (isSubmit &&
                          formData.rewardAddress === "" &&
                          "Please enter address") ||
                        (errorValidRewardTokenAddress &&
                          "Please enter a valid token address")
                      }
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Box className={classes.box4}>
                  <Typography variant="h4">Total Reward Supply</Typography>
                  <Box className={`${classes.box3} textFieldB`}>
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      // label="rewardAddress"
                      variant="outlined"
                      type="number"
                      name="rewardSupply"
                      // autocomplete="off"
                      autoComplete="nope"
                      value={formData.rewardSupply}
                      onKeyPress={(event) => {
                        if (event?.key === "-" || event?.key === "+") {
                          event.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        // if (e.target.value && e.target.value != "-") {
                        _onInputChange(e);
                        // }
                      }}
                      error={isSubmit && formData.rewardSupply === ""}
                      helperText={
                        isSubmit &&
                        formData.rewardSupply === "" &&
                        "Please enter reward supply"
                      }
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Box className={classes.box4}>
                  <Typography variant="h4">Name</Typography>
                  <Box className={classes.box3}>
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      // label="Outlined"
                      variant="outlined"
                      value={tokenName1}
                      disabled
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Box className={classes.box4}>
                  <Typography variant="h4">Decimal</Typography>
                  <Box className={classes.box3}>
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      // label="Outlined"
                      variant="outlined"
                      value={decimals1}
                      disabled
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>

            {/* <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={value}
              onChangeIndex={handleChangeIndex}
            > */}
            <TabPanel value={value} index={2} dir={theme.direction}>
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Box className={classes.box4}>
                    <Typography variant="h4">
                      Divident Contract Address
                    </Typography>
                    <Box className={classes.box3}>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        // label="rewardAddress"
                        variant="outlined"
                        name="dividentAddress"
                        // autocomplete="off"
                        autoComplete="nope"
                        value={formData.dividentAddress}
                        onChange={_onInputChange}
                        error={
                          (isSubmit && formData.dividentAddress === "") ||
                          errorValidDivTokenAddress
                        }
                        helperText={
                          (isSubmit &&
                            formData.dividentAddress === "" &&
                            "Please enter address") ||
                          (errorValidDivTokenAddress &&
                            "Please enter a valid token address")
                        }
                      />
                    </Box>
                  </Box>
                </Grid>
                {/* <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Box className={classes.box4}>
                    <Typography variant="h4">Total Reward Supply</Typography>
                    <Box className={`${classes.box3} textFieldB`}>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        // label="rewardAddress"
                        variant="outlined"
                        type="number"
                        name="rewardSupply"
                        // autocomplete="off"
                        autoComplete="nope"
                        value={formData.rewardSupply}
                        onKeyPress={(event) => {
                          if (event?.key === "-" || event?.key === "+") {
                            event.preventDefault();
                          }
                        }}
                        onChange={(e) => {
                          // if (e.target.value && e.target.value != "-") {
                          _onInputChange(e);
                          // }
                        }}
                        error={isSubmit && formData.rewardSupply === ""}
                        helperText={
                          isSubmit &&
                          formData.rewardSupply === "" &&
                          "Please enter reward supply"
                        }
                      />
                    </Box>
                  </Box>
                </Grid> */}
              </Grid>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Box className={classes.box4}>
                    <Typography variant="h4">Name</Typography>
                    <Box className={classes.box3}>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        // label="Outlined"
                        variant="outlined"
                        value={tokenName12}
                        disabled
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Box className={classes.box4}>
                    <Typography variant="h4">Decimal</Typography>
                    <Box className={classes.box3}>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        // label="Outlined"
                        variant="outlined"
                        value={decimals12}
                        disabled
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </TabPanel>
            {/* </SwipeableViews> */}
            <Box mt={3}>
              {/* <Tabbar1 /> */}
              <Box className={classes.root} mt={5}>
                <Grid container>
                  <Grid item xl={6} lg={7} sm={12} xs={12}>
                    <AppBar position="static" color="default">
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                        aria-label="full width tabs example"
                      >
                        <Tab label="Fix" {...a11yProps(0)} />
                        <Tab label="Dynamic" {...a11yProps(1)} />
                        <Tab label="Divident" {...a11yProps(2)} />
                      </Tabs>
                    </AppBar>
                  </Grid>
                </Grid>
                <SwipeableViews
                  axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                  index={value}
                  onChangeIndex={handleChangeIndex}
                >
                  <TabPanel value={value} index={0} dir={theme.direction}>
                    <Box className={classes.durationbox}>
                      <Typography variant="h3">
                        Select custom duration
                      </Typography>

                      <FormGroup row>
                        <Radio
                          checked={selectedValue === "a"}
                          onChange={handleChangeRadio}
                          value="a"
                          name="radio-button-demo"
                          inputProps={{ "aria-label": "A" }}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={checked3}
                              onChange={(e) => {
                                setChecked3(e.target.checked);
                                setSelectedValue("a");
                                setChecked6(false);
                                setChecked9(false);
                                setChecked12(false);
                                setChecked18(false);
                                setChecked24(false);
                                setSelectTime("");
                              }}
                              name="checkedA"
                            />
                          }
                          label="3 months"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={checked6}
                              // onChange={handleChangecheck}
                              onChange={(e) => {
                                setChecked6(e.target.checked);
                                setChecked3(false);
                                setSelectedValue("a");
                                setChecked9(false);
                                setChecked12(false);
                                setChecked18(false);
                                setChecked24(false);
                                setSelectTime("");
                              }}
                              name="checkedB"
                              color="primary"
                            />
                          }
                          label="6 months"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={checked9}
                              onChange={(e) => {
                                setChecked9(e.target.checked);
                                setChecked3(false);
                                setChecked6(false);
                                setSelectedValue("a");
                                setChecked12(false);
                                setChecked18(false);
                                setChecked24(false);
                                setSelectTime("");
                              }}
                              name="checkedC"
                            />
                          }
                          label="9 months"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={checked12}
                              onChange={(e) => {
                                setChecked12(e.target.checked);
                                setChecked3(false);
                                setChecked6(false);
                                setChecked9(false);
                                setSelectedValue("a");
                                setChecked18(false);
                                setChecked24(false);
                                setSelectTime("");
                              }}
                              name="checkedF"
                              color="primary"
                            />
                          }
                          label="12 months"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={checked18}
                              onChange={(e) => {
                                setChecked18(e.target.checked);
                                setChecked3(false);
                                setChecked6(false);
                                setChecked9(false);
                                setChecked12(false);
                                setSelectedValue("a");
                                setChecked24(false);
                                setSelectTime("");
                              }}
                              name="checkedG"
                              color="primary"
                            />
                          }
                          label="18 months"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={checked24}
                              onChange={(e) => {
                                setChecked24(e.target.checked);
                                setChecked3(false);
                                setChecked6(false);
                                setChecked9(false);
                                setChecked12(false);
                                setChecked18(false);
                                setSelectedValue("a");
                                setSelectTime("");
                              }}
                              name="checkedH"
                              color="primary"
                            />
                          }
                          label="24 months"
                        />
                      </FormGroup>
                    </Box>
                    <Box mt={5} style={{ height: "42px", display: "flex" }}>
                      {" "}
                      <Radio
                        checked={selectedValue === "b"}
                        onChange={handleChangeRadio}
                        value="b"
                        name="radio-button-demo"
                        inputProps={{ "aria-label": "B" }}
                      />
                      <Box className={`${classes.box30} textFieldB`} mt={2}>
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          placeholder="Enter custom time"
                          variant="outlined"
                          value={selectTime}
                          onKeyPress={(event) => {
                            if (event?.key === "-" || event?.key === "+") {
                              event.preventDefault();
                            }
                          }}
                          type="number"
                          onChange={(e) => {
                            if (e.target.value && e.target.value != "-") {
                              setSelectTime(Math.abs(Number(e.target.value)));
                            } else {
                              setSelectTime();
                            }

                            setSelectedValue("b");
                            setChecked24(false);
                            setChecked3(false);
                            setChecked6(false);
                            setChecked9(false);
                            setChecked12(false);
                            setChecked18(false);
                          }}
                        />
                      </Box>{" "}
                      &nbsp;&nbsp;&nbsp;
                      <select
                        className="daySelect"
                        onChange={(e) => {
                          setSelectMonth(e.target.value);
                          setSelectedValue("b");
                          setChecked24(false);
                          setChecked3(false);
                          setChecked6(false);
                          setChecked9(false);
                          setChecked12(false);
                          setChecked18(false);
                        }}
                        value={selectMonth}
                      >
                        <option value="Select days">Select days</option>
                        <option value="Days">Days</option>
                        <option value="Month">Month</option>
                        <option value="Years">Years</option>
                      </select>
                    </Box>

                    <Box className={classes.box4}>
                      <Typography variant="h2">Reward APR</Typography>
                      <Box className={`${classes.box3} textFieldB`}>
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          type="number"
                          variant="outlined"
                          value={APR}
                          onKeyPress={(event) => {
                            if (event?.key === "-" || event?.key === "+") {
                              event.preventDefault();
                            }
                          }}
                          onChange={(e) => {
                            if (e?.key === ".") {
                              setAPR(0, e.target.value);
                            } else {
                              setAPR(e.target.value);
                            }
                          }}
                        />
                      </Box>
                      <Typography variant="h4">
                        {APR} APR = {parseFloat(APR / 105120).toFixed(12)}{" "}
                        Reward Per Block
                      </Typography>
                    </Box>
                  </TabPanel>
                  {/* {console.log(value)} */}
                  <TabPanel value={value} index={1} dir={theme.direction}>
                    <Box className={classes.Dynamicbox}>
                      <Typography variant="h3">Reward per Block:</Typography>
                      <Box className={classes.box3}>
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          variant="outlined"
                          name="rewardsPerBlock"
                          value={formData.rewardsPerBlock}
                          onChange={_onInputChange}
                          error={isSubmit && formData.rewardsPerBlock === ""}
                          helperText={
                            isSubmit &&
                            formData.rewardsPerBlock === "" &&
                            "Please enter reward per block"
                          }
                        />
                      </Box>
                      <Typography variant="h3">
                        {formData.rewardsPerBlock} Blocks ={" "}
                        {Math.ceil(
                          (formData.rewardsPerBlock * 1000000) / 9.6450617
                        )}{" "}
                        APR
                      </Typography>
                    </Box>
                  </TabPanel>
                  <TabPanel value={value} index={2} dir={theme.direction}>
                    <Box className={classes.Dynamicbox}>
                      <Typography variant="h3">Reward per Block:</Typography>
                      <Box className={classes.box3}>
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          variant="outlined"
                          name="rewardsPerBlock"
                          value={formData.rewardsPerBlock}
                          onChange={_onInputChange}
                          error={isSubmit && formData.rewardsPerBlock === ""}
                          helperText={
                            isSubmit &&
                            formData.rewardsPerBlock === "" &&
                            "Please enter reward per block"
                          }
                        />
                      </Box>
                      <Typography variant="h3">
                        {formData.rewardsPerBlock} Blocks ={" "}
                        {Math.ceil(
                          (formData.rewardsPerBlock * 1000000) / 9.6450617
                        )}{" "}
                        APR
                      </Typography>
                    </Box>
                  </TabPanel>
                </SwipeableViews>
              </Box>
            </Box>
            <Box className={classes.tabbtn}>
              <Typography variant="h2">
                {/* To stake fee: &nbsp; {user.controlData?.commisionStacking} BNB */}
              </Typography>
              <Typography variant="h2">
                Minimum staking Required {user.controlData?.satelliteStacking}{" "}
                Setelite Or Minimum Setelite Burn{" "}
                {/* {user.controlData?.satelliteBurn} */}
              </Typography>
            </Box>
            {account ? (
              <Box className={classes.twobutton}>
                <Box className={classes.box5}>
                  <Button className={classes.btn1} onClick={handleClickOpen}>
                    Submit
                  </Button>
                </Box>
                &nbsp; &nbsp; &nbsp;
              </Box>
            ) : (
              <Box className={classes.twobutton}>
                <Box className={classes.box5}>
                  <Button
                    className={classes.btn1}
                    onClick={() => setOpenWalletConnect(true)}
                  >
                    Connect Wallet
                  </Button>
                </Box>
                &nbsp; &nbsp; &nbsp;
              </Box>
            )}
          </Box>
        </Box>
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          fullWidth
          disableScrollLock={true}
          maxWidth="sm"
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
            className={classes.colors}
          >
            Satellite
          </DialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom className={classes.colors}>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book.
            </Typography>
            <Typography gutterBottom className={classes.colors}>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book.
            </Typography>
            <Typography gutterBottom className={classes.colors}>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book.
            </Typography>
          </DialogContent>
          <DialogActions>
            {!selectBurn && (
              <Box className={classes.Twobtn}>
                <Box style={{ marginRight: "15px" }}>
                  <Button
                    autoFocus
                    // onClick={() => {
                    //   history.push({
                    //     pathname: "/app/stake-satellite",
                    //   });
                    // }}
                    onClick={() => {
                      handleOpenStake();
                    }}
                    color="primary"
                    className={classes.btn1}
                  >
                    stake{" "}
                  </Button>
                </Box>

                <Box className={classes.Twobtn}>
                  <Button
                    className={classes.btn1}
                    onClick={() => {
                      BurnSateliteToken();
                    }}
                    disabled={isDeployed}
                  >
                    {/* Burn Token */}
                    {nameChangesButton}
                    {isDeployed && <CircularProgress />}
                  </Button>
                </Box>
              </Box>
            )}
            {selectBurn && (
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                {network.chainId != chainId && (
                  <>
                    <Typography variant="body2" style={{ color: "red" }}>
                      Please switch network
                    </Typography>
                    {"   "} &nbsp;
                    <Typography variant="body2" style={{ color: "green" }}>
                      Please do not refresh pages! &nbsp;
                    </Typography>
                    <Box className={classes.Twobtn}>
                      <Button
                        autoFocus
                        onClick={() => {
                          deployContract();
                        }}
                        color="primary"
                        className={classes.btn1}
                      >
                        Switch Network{isDeployed && <CircularProgress />}
                      </Button>
                    </Box>
                  </>
                )}

                {network.chainId == chainId && (
                  <>
                    <Typography variant="body2" style={{ color: "green" }}>
                      Please do not refresh pages! &nbsp;
                    </Typography>
                    <Box className={classes.Twobtn}>
                      <Button
                        autoFocus
                        onClick={() => {
                          deployContract();
                        }}
                        color="primary"
                        className={classes.btn1}
                      >
                        Deploy Contract{isDeployed && <CircularProgress />}
                      </Button>
                    </Box>
                  </>
                )}
              </Box>
            )}
          </DialogActions>
        </Dialog>
        <Dialog
          onClose={handleCloseStake}
          aria-labelledby="customized-dialog-title"
          open={openStake}
          fullWidth
          disableScrollLock={true}
          maxWidth="sm"
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={handleCloseStake}
            className={classes.colors}
          >
            Satellite
          </DialogTitle>
          <DialogContent dividers>
            <StakingTab
              AdminOwner={AdminOwner}
              stakedValue={
                user.controlData?.satelliteStacking
                  ? user.controlData?.satelliteStacking
                  : 50
              }
              createStake={true}
              selectBurn1={selectBurn1}
              setSelectBurn={setSelectBurn}
              setSelectBurn1={setSelectBurn1}
              handleCloseStake={handleCloseStake}
            />
          </DialogContent>
        </Dialog>

        {openWalletConnect && (
          <ConnectWallet
            open={openWalletConnect}
            handleClose={() => setOpenWalletConnect(false)}
          />
        )}
      </Container>
    </Box>
  );
}
