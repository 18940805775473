import React from 'react'
import {
  Grid,
  Box,
  Container,
  Typography,
  makeStyles,
  Button,
  withStyles, Select, MenuItem
} from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import Stepper from '@material-ui/core/Stepper';
import { Link } from "react-router-dom";
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Slider from "@material-ui/core/Slider";
import { RiTelegramLine } from "react-icons/ri";
import { TiSocialTwitterCircular } from "react-icons/ti";
// import { FaTwitter } from "react-icons/fa";
const iOSBoxShadow =
  "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)";
const marks = [
  {
    value: 0,
  },
  {
    value: 2500,
  },
  {
    value: 7500,
  },
  {
    value: 10000,
  },
];

const IOSSlider = withStyles({
  root: {
    color: "#FABE25!important",
    height: 12,
    padding: "15px 0",
  },
  thumb: {
    height: 20,
    width: 20,
    backgroundColor: "#fff",
    boxShadow: iOSBoxShadow,
    marginTop: -8,
    marginLeft: -14,
    "&:focus, &:hover, &$active": {
      boxShadow:
        "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        boxShadow: iOSBoxShadow,
      },
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 12px)",
    top: -22,
    "& *": {
      background: "transparent",
      color: "#fff",
    },
  },
  track: {
    height: 9,
  },
  rail: {
    height: 9,
    opacity: 0.5,
    backgroundColor: "#bfbfbf",
  },
  mark: {
    backgroundColor: "#bfbfbf",
    height: 20,
    width: 1,
    marginTop: -3,
  },
  markActive: {
    opacity: 1,
    backgroundColor: "currentColor",
  },
})(Slider);
const useStyles = makeStyles((theme) => ({
  bannerBox: {
    marginTop: "65px",
  },
  textbox: {
    mint: {
      fontSize: "14px ",
      border: "1px solid #E8424C",
      background: "#E8424C",
      fontWeight: 600,
      height: "44px ",
      color: "#FFFFFF",
      minWidth: "150px ",
      borderRadius: "50px",
      boxShadow: "none ",
      cursor: "pointer",
      [theme.breakpoints.down("xs")]: {
        height: "45px ",
        minWidth: "120px ",
      },
      "&:hover": {
        borderColor: "#E8424C",
        background: "#E8424C",
      },
    },
    "& h1": {
      fontSize: "25px",
      fontWeight: "bold",
      lineHeight: "55px",
      color: "#FABE25",
    },
    "& h2": {
      fontSize: "45px",
      fontWeight: "bold",
      lineHeight: "55px",
      color: "#FABE25",
      [theme.breakpoints.down("xs")]: {
        fontSize: "30px",
      },
    },
    "& h3": {
      fontSize: "45px",
      fontWeight: "bold",
      lineHeight: "55px",
      color: "#fff",
    },
    "& h5": {
      fontSize: "30px",
      fontWeight: "500",
      color: "#fff",
      marginBottom: "10px",
      marginTop: "15px",
    },
    "& h6": {
      color: "#9F9F9F",
      marginBottom: "10px",
    },
    "& p": {
      fontSize: "14px",
      color: "#9F9F9F",
      width: "100%",
      // maxWidth: "600px",
    },
    "& label": {
      fontSize: "16px",
      color: "#fff",
      // maxWidth: "600px",
    },
    "& div": {
      "& button": {
        "&:last-child": {
          marginLeft: "20px",
        },
      },
    },
  },
  technologies: {
    background: '#ECECEC',
    borderRadius: '10px',
    maxHeight: '300px',
    '& img': {
      maxHeight: '300px',
    },
  },

  amount: {
    '& label': {
      color: '#353840',
      fontSize: '18px',
      fontWeight: '400',
      lineHeight: '33px',
    },
  },
  amountdiv: {
    maxWidth: '100%',
    height: '60px',
    border: '1px solid #00ffab',
    borderRadius: ' 5px',
    display: 'flex',
    padding: '0 20px',
    alignItems: 'center',
    fontSize: '45px',
  },

  inputfile: {
    background: '#ECECEC',
    borderRadius: '10px',
    position: 'relative',
    border: '2px dashed #DDD9D9',
    boxSizing: 'border-box',
    cursor: 'pointer',
    padding: "10px",

    '& input': {
      width: '100%',
      height: '100%',
      top: '0',
      left: '0',
      opacity: '0',
      position: 'absolute',
      cursor: 'pointer',
    },

    '& img': {
      padding: '26px',
    },

    '& p': {
      fontSize: '9px',
      fontWeight: 'normal',
      padding: '9px',
      lineHeight: '17px',
      textAlign: 'center',
      color: '#595C62',
      marginTop: '-17px',
    },
  },

  mainBox: {
    padding: "20px 20px 50px",
    overflow: "hidden",
    position: "relative",
    background: "linear-gradient( 152.97deg, rgba(255, 255, 255, 0.08) 0%, rgba(232, 66, 76, 0.062) 100%)",
    transition: "0.5s",
    borderRadius: "0 30px 0 0",
    backdropFilter: "blur(42px)",

    "& h1": {
      fontSize: "25px",
      fontWeight: "bold",
      lineHeight: "55px",
      color: "#FABE25",
    },
    "& p": {
      fontSize: "14px",
      color: "#9F9F9F",
      width: "100%",
      // maxWidth: "600px",
    },
    "& small": {
      fontSize: "12px",
      color: "#6c757d!important",
      // maxWidth: "600px",
    },
    "& label": {
      color: "#9F9F9F",
      padding: "0",
      fontSize: "14px",
      transition: "opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    },
  },
  selectbox: {
    width: "100%",
    border: "2px solid #FABE25",
    height: "37px",
    borderRadius: "5px",
    background: "#18131d",
    color: "#9F9F9F",
    fontSize: "14px",
    padding: "5px",
  },

  buttonright: {
    fontSize: "14px ",
    border: "1px solid #E8424C",
    background: "#E8424C",
    fontWeight: 600,
    height: "44px ",
    borderRadius: "50px",
    color: "#FFFFFF",
    minWidth: "150px ",
    boxShadow: "none ",
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      height: "45px ",
      minWidth: "120px ",
    },
    "&:hover": {
      borderColor: "#E8424C",
      backgroundColor: "#E8424C",
      border: "2px solid #fff",
    },
  },

}))
// function getSteps() {
//   return ['Select campaign settings', 'Create an ad group', 'Create an ad'];
// }
// const classes = useStyles()
function getStepContent(step) {

  switch (step) {
    case 0:
      return (
        <>
          <Box my={3} className="mainBox">
            <label>Token Address</label>
            <TextField
              id="outlined-basic"
              variant="outlined"
              placeholder="0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2"
              fullWidth
            />

            <small style={{    fontSize: "12px",color: "#a09b9b",}}>
              Create pool fee: 0.5BNB
            </small>
          </Box>
        </>);
    case 1:
      return (
        <>
          <Box mt={3} className="mainBox">
            <Grid spacing={4} container>

              <Grid item xs={12} sm={6}>
                <Box mt={2}>
                  <label>* Sale Title</label>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="Token Public Sale Round 1"
                    fullWidth
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box mt={2}>
                  <label>Max. Investment per Wallet (BNB)</label>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="Token Public Sale Round 1"
                    fullWidth
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box>
                  <label>* Min. Investment per Wallet (BNB)</label>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="Token Public Sale Round 1"
                    fullWidth
                  />
                </Box>
              </Grid>


              <Grid item xs={12} sm={6}>
                <Box>
                  <label>* Opens at</label>
                  <TextField
                    type="date"
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="Token Public Sale Round 1"
                    fullWidth
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box>
                  <label>* Closes at</label>
                  <TextField
                    type="date"
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="Token Public Sale Round 1"
                    fullWidth
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box>
                  <label>* Sale Price ( BNB )</label>
                  <TextField

                    id="outlined-basic"
                    variant="outlined"
                    placeholder="Token Public Sale Round 1"
                    fullWidth
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box >
                  <label>* Address where UNSOLD TOKENS will be transferred to</label>
                  <TextField

                    id="outlined-basic"
                    variant="outlined"
                    placeholder="Token Public Sale Round 1"
                    fullWidth
                  />
                </Box>
                <small>
                  Unsold tokens will be sent to burn address by default
                </small>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box>
                  <label>* Hard Cap</label>
                  <TextField

                    id="outlined-basic"
                    variant="outlined"
                    placeholder="Token Public Sale Round 1"
                    fullWidth
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box>
                  <label>* Soft Cap ( BNB )</label>
                  <TextField id="outlined-basic"
                    variant="outlined"
                    placeholder="Token Public Sale Round 1"
                    fullWidth
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box>
                  <label>*Contribution Token</label>
                  <Select labelId="label" id="select" value="20" fullWidth>
                    <MenuItem value="10">BNB</MenuItem>
                    <MenuItem value="20">BUSD</MenuItem>
                    <MenuItem value="20">USDT</MenuItem>
                  </Select>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box mt={3} className="mainBox">
            <Typography variant="h5">Liquidity Lock and Allocation</Typography>
            <Grid spacing={4} container>
              <Grid item xs={12} sm={6}>
                <Box>
                  <label>*  Listing Price (n BNB per 1 Token)</label>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="2"
                    fullWidth
                  />
                </Box>

              </Grid>
              <Grid item xs={12} sm={6}>
                <Box mt={4}>
                  <IOSSlider
                    aria-label="ios slider"
                    defaultValue={60}
                    marks={marks}
                    valueLabelDisplay="on"
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box>
                  <label>* Listing Time</label>
                  <TextField
                    id="outlined-basic"
                    type="date"
                    variant="outlined"
                    placeholder="2"
                    fullWidth
                  />
                </Box>

              </Grid>

              <Grid item xs={12} sm={6}>
                <Box >
                  <label>* LP Tokens Lock Duration (Days)</label>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="0"
                    fullWidth
                  />
                </Box>

              </Grid>
            </Grid>
          </Box>
          <Box mt={3} mb={5} className="mainBox">
            <Typography variant="h5"> Whitelist</Typography>
            <Grid spacing={4} container>
              <Grid item xs={12} sm={12}>
                <Box>
                  <label>Whitelisted Addresses (Comma-separated)</label>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="Example only: 0x89205A3A3b2A69De6Dbf7f01ED13B2108B2c43e7"
                    fullWidth
                  />
                </Box>
                <Box mt={4}>

                  <Button variant="outlined" color="primary" size="large" >
                    ADD
                  </Button>

                </Box>

              </Grid>
            </Grid>
          </Box>
        </>
      );
    case 2:
      return (
        <Box my={3} className="mainBox">
          <Typography variant="h5"> Community Links</Typography>
          <Grid spacing={4} container>
            <Grid item xs={12} sm={6}>
              <Box >
                <label>Website</label>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="https://ABC.io"
                  fullWidth
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box >
                <label>PooCoin Chart</label>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="https://poocoin.io/test"
                  fullWidth
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box >
                <label>Telegram</label>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="https://t.me/ABC"
                  fullWidth
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box >
                <label>Discord</label>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="https://discord.com/invite/aB1c234"
                  fullWidth
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box  >
                <label>Twitter</label>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="https://twitter.com/ABC"
                  fullWidth
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box >
                <label>Description</label>
                <TextField
                  id="outlined-basic"
                  variant="standard"

                  fullWidth multiline rows="4"
                />
              </Box>
            </Grid>
          </Grid>
        </Box>

      );

    case 3:
      return (
        <Box my={3} className="mainBox">
          <Box mb={3}>
            <Typography variant="h5"> Confirm Details</Typography></Box>

            <Grid container spacing={1}>
              <Grid item xs={12} sm={2}>
              <figure style={{ marginLeft: "0" }}>
                <img src="images/logo_2.png" alt=" "  />

              </figure>
              </Grid>
              <Grid item xs={12} sm={10}>
              <Box>
                <Typography variant="h2"> UNIMOON</Typography>
                <a href="https://twitter.com/login?lang=en"> <TiSocialTwitterCircular style={{ fontSize: "25px", color: "#fff", margin: "0 5px" }} />   </a>
                <a href="https://web.telegram.org/"> <RiTelegramLine style={{ fontSize: "25px", color: "#fff", margin: "0 15px 0 5px" }} /> </a>
                <a href="#https://poocoin.app/"> <img src="images/poocoin.png" style={{ width: "22px" }} /> </a>
                <Typography variant="body2"> UNIMOON: The First L1 Blockchain Built to Decentralize Social Media For Everyone. 13% Taxes on PCS Launch.Join The UNIMOON Revolution Want to protect your data, your freedom, and your control? We are breaking the barriers of tech, creating a free-feeling social media app that will change digital content forever. You a single user have the power to help us transform the digital world into a fair, free place where people are valued as much as the content that they create. Are you ready to join us? </Typography>

              </Box>
              </Grid>
            </Grid>
          


          



          <table>
            <tbody>
              <tr>
                <td>Presale Address</td>
                <td class="has-text-right">
                  <a href="https://bscscan.com/address/0x5B16a1093D551Dc84A2256394dE1A00e334eB74e" target="_blank" rel="noreferrer nofollow">0x5B16a1093D551Dc84A2256394dE1A00e334eB74e</a>
                </td>
              </tr>
              <tr>
                <td>Token Name</td>
                <td class="has-text-right">UNIMOON</td>
              </tr>
              <tr>
                <td>Token Symbol</td>
                <td class="has-text-right">UNIMOON</td>
              </tr>
              <tr>
                <td>Token Decimals</td>
                <td class="has-text-right">9</td>
              </tr>
              <tr>
                <td>Token Address</td>
                <td class="has-text-right">
                  <a class="mr-1" href="#" target="_blank" rel="noreferrer nofollow">0x893e14FAfa8df32D9bE7eab810027eeA8828A98c</a>

                </td>
              </tr>
              <tr>
                <td>Total Supply</td>
                <td class="has-text-right">2,000,000,000,000,000 UNIMOON</td>
              </tr>
              <tr>
                <td>Tokens For Presale</td>
                <td class="has-text-right">216,000,000,000,000 UNIMOON</td>
              </tr>
              <tr>
                <td>Tokens For Liquidity</td>
                <td class="has-text-right">151,200,000,000,000 UNIMOON</td>
              </tr>
              <tr>
                <td>Presale Rate</td>
                <td class="has-text-right">1 BNB = 90,000,000,000 UNIMOON</td>
              </tr>
              <tr>
                <td>Listing Rate</td>
                <td class="has-text-right">1 BNB = 90,000,000,000 UNIMOON</td>
              </tr>
              <tr>
                <td>Soft Cap</td>
                <td class="has-text-right">1200 BNB</td>
              </tr>
              <tr>
                <td>Hard Cap</td>
                <td class="has-text-right">2400 BNB</td>
              </tr>
              <tr>
                <td>Unsold Tokens</td>
                <td class="has-text-right">Burn</td>
              </tr>
              <tr>
                <td>Presale Start Time</td>
                <td class="has-text-right">2021.11.08 18:00 (UTC)</td>
              </tr>
              <tr>
                <td>Presale End Time</td>
                <td class="has-text-right">2021.11.10 18:00 (UTC)</td>
              </tr>
              {/* <tr>
       <td>Listing On</td>
       <td class="has-text-right">
         <a class="mr-1" href="https://pancakeswap.finance/swap?outputCurrency=0x893e14FAfa8df32D9bE7eab810027eeA8828A98c" target="_blank" rel="noreferrer nofollow">Pancakeswap</a>
       </td>
     </tr> */}
              <tr>
                <td>Liquidity Percent</td>
                <td class="has-text-right">70%</td>
              </tr>
              <tr>
                <td>Liquidity Unlocked Time</td>
                <td class="has-text-right">
                  <a target="_blank" rel="noopener noreferrer" href="#/pinklock/record/1847?chain=BSC">2022.03.10 17:57 UTC (in 2 months)</a>
                </td>
              </tr>
              <tr>
                <td>Total Team Vesting Tokens</td>
                <td class="has-text-right">100,000,000,000,000 UNIMOON</td>
              </tr>
              <tr>
                <td>Team Vesting Release Time</td>
                <td class="has-text-right">2021.12.25 17:57 UTC</td>
              </tr>
              <tr>
                <td>First Release After Listing (days)</td>
                <td class="has-text-right">45 days</td>
              </tr>
              <tr>
                <td>First Release For Team</td>
                <td class="has-text-right">10%</td>
              </tr>
              <tr>
                <td>Tokens release each cycle</td>
                <td class="has-text-right">15% each 15 days</td>
              </tr>
            </tbody>
          </table>
        </Box>
      );
    default:
      return 'Unknown step';
  }
}
export default function BestSeller() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const steps = 4;

  const isStepOptional = (step) => {
    return step === 1;
  };
  const handleNext = () => {


    setActiveStep((prevActiveStep) => prevActiveStep + 1);

  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleReset = () => {
    setActiveStep(0);
  };
  const classes = useStyles()
  return (
    <Box className={classes.bannerBox}>
      <Box className={classes.textbox} mt={5} mb={5}>
        <Typography variant="h2" align="center">Launchpad</Typography>
      </Box>
      <div className={classes.root}>
        <Stepper activeStep={activeStep}>
          <Step >
            <StepLabel><Typography variant="h6" align="left" >Verify Token</Typography>
              <Typography variant="caption">Enter the token address and verify</Typography></StepLabel>
          </Step>
          <Step >
            <StepLabel><Typography variant="h6" align="left">Launchpad Info</Typography>
              <Typography variant="caption">Enter the information in details about presale</Typography></StepLabel>
          </Step>
          <Step >
            <StepLabel><Typography variant="h6" align="left">Add Additional Info</Typography>
              <Typography variant="caption">Let people know who you are</Typography></StepLabel>
          </Step>
          <Step >
            <StepLabel><Typography variant="h6" align="left">Finish</Typography>
              <Typography variant="caption">Review your information</Typography></StepLabel>
          </Step>
        </Stepper>
        <div>
          {activeStep === steps ? (
            <Box my={3}>
              <Typography className={classes.instructions} style={{ color: "#fff" }}>
                All steps completed - Your Pool is created successfully.
              </Typography>
              <Button variant="outlined" color="primary" size="large" style={{marginTop:"10px"}} onClick={handleReset} className={classes.button}>
                Reset
              </Button>
            </Box>
          ) : (
            <div>
              <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
              <div>
              {/* <Button variant="outlined" color="primary" size="large" >
                    ADD
                  </Button> */}
                <Button variant="outlined" color="primary" size="large" style={{marginRight:"7px"}}
                  disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                  Back
                </Button>


                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  // className={classes.button}
                >
                  {activeStep === steps - 1 ? 'Finish' : 'Next'}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
      <Box mt={5}>
        <Typography variant="caption" align="left" style={{    fontSize: "14px",color: "#a09b9b",}}>Desclaimer: The information provided shall not in any way constitute a recommendation as to whether you should invest in any product discussed. We accept no liability for any loss occasioned to any person acting or refraining from action as a result of any material provided or published.</Typography>
      </Box>
    </Box>
  )
}
