import React from 'react'
import {
  Grid,
  Box,
  Container,
  Typography,
  makeStyles,TextField,Button
} from '@material-ui/core';
import Index from "./Tab/Index";

const useStyles = makeStyles((theme) => ({

  textbox: {
    "& h2": {
      fontSize: "45px",
      fontWeight: "bold",
      lineHeight: "55px",
      color: "#FABE25",
      [theme.breakpoints.down("xs")]: {
        fontSize: "30px",
      },
    },
  },
  mainBox: {
    borderRadius: "10px",
    minHeight: "250px",
    overflow: "hidden",
    position: "relative",
    padding: "25px",
    transition: "0.5s",
    textAlign: "left",
    background:
      "linear-gradient( 152.97deg, rgba(255, 255, 255, 0.08) 0%, rgba(232, 66, 76, 0.062) 100%)",
    borderRadius: "0 30px 0 0",
    backdropFilter: "blur(42px)",
    "& p": {
      fontSize: "16px !important",
      color: "#b8b8b8",
      marginTop: "15px",
    },
    "& label": {
      fontSize: "15px !important",
      lineHeight: "24px",
      color: "#7e7f81 !important",
    },

  },
  connectCard: {
    display: " flex",
    alignItems: "center",
    borderRadius: "5px",
    marginTop: "7px",
    color: "#fff",
    textDecoration: "none",
    "& img": {
      width: "50px",
      height: "50px",
      borderRadius: "50%",
      marginRight: "15px",
    },

  },
  information:{
    backgroundColor: "#090b16",
    border: "1px solid #443309",
    padding: "10px",
    fontSize: "16px",
    color:" #888585",
    "& small":{
      color:"#fabe25",
      cursor: "pointer",
    },
  },
}))

export default function BestSeller() {
  const classes = useStyles()

  return (
    <Box className={classes.bannerBox}>
      <Container maxWidth="lg" align="center">
        <Box className={classes.textbox} mt={5} mb={5}>
          <Typography variant="h2">Token Locker</Typography>
        </Box>
      </Container>
      <Box mt={5}>

        <Box className={classes.mainBox}>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
            <label>Token or LP Token address</label>
            <TextField
              id="outlined-basic"
              variant="outlined"
              placeholder="Enter token or LP token address"
              fullWidth
            />
            </Grid>
            <Grid item xs={12} sm={6}>
            <label>Amount</label>
            <TextField
              id="outlined-basic"
              variant="outlined"
              placeholder="Enter amount"
              fullWidth
            />
            </Grid>
            <Grid item xs={12} sm={6}>
            <label>Lock until (UTC time)</label>
            <TextField
              id="outlined-basic"
              variant="outlined"
              placeholder="select date"
              fullWidth
            />
            </Grid>
          </Grid>
          <Box className={classes.information} mt={3}>
          Please exclude PinkLock's lockup address <small>Ox33d4cC8716Beb13F814F538Ad3b2de3b036f5e2A</small> O from fees, rewards, max tx amount to start locking tokens.<br/> We don't support rebase tokens.
          </Box>
          <Box align="center" mt={3}>
            <Button variant="contained" color="primary">
             Lock
            </Button>
          </Box>
        </Box>
   
      </Box>
    </Box>
  )
}
