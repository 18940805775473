import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
// [1, 3, 4, 5, 42, 56, 97, 1287, 361, 365, 43114, 43113]
export const injected = new InjectedConnector({
  supportedChainIds: [
    1, 56, 137,
    // 43113, 5, 42, 56, 97, 1287, 361, 365, 43114, 80001,
  ],
});

export const walletconnect = new WalletConnectConnector({
  // rpc: {
  //   56: "https://bsc-dataseed4.binance.org",
  // },
  // bridge: "https://bridge.walletconnect.org",
  // qrcode: true,
  // pollingInterval: 12000,
  infuraId: "your_infura_id",
  pollingInterval: 15000,
  qrcode: true,
  qrcodeModalOptions: {
    mobileLinks: ["trust"],
  },
  chainId: 56,
  rpc: {
    56: "https://bsc-dataseed4.binance.org",
  },
});

export const SUPPORTED_WALLETS = [
  {
    name: "METAMASK",
    data: {
      connector: injected,
      name: "MetaMask",
      iconName: "/images/walletImages/metamask-fox.svg",
      description: "Easy-to-use browser extension.",
      href: null,
      color: "#E8831D",
    },
  },
  // {
  //   name: 'BINANCE',
  //   data: {
  //     connector: binanceinjected,
  //     name: 'Binance Chain',
  //     iconName: '/images/walletImages/bcw.svg',
  //     description: 'A Crypto Wallet for Binance Smart Chain',
  //     href: null,
  //     color: '#F9A825',
  //   },
  // },
  // {
  //   name: "TRUSTWALLET",
  //   data: {
  //     connector: injected,
  //     name: "Trust Wallet",
  //     iconName: "/images/walletImages/trust.svg",
  //     description: "The most trusted & secure crypto wallet",
  //     href: null,
  //     color: "#3375BB",
  //   },
  // },
  // {
  //   name: "MATHWALLET",
  //   data: {
  //     connector: mathWalletInjected,
  //     name: "Math Wallet",
  //     iconName: "/images/walletImages/math.png",
  //     description: "Your Gateway to the World of Blockchain",
  //     href: null,
  //     color: "#000000",
  //   },
  // },
  {
    name: "WALLETCONNECT",
    data: {
      connector: walletconnect,
      name: "Wallet Connect",
      iconName: "/images/walletImages/walletConnect.png",
      description: "Your Gateway to the World of Blockchain",
      href: null,
      color: "#000000",
    },
  },
  // {
  //   name: 'TORUS',
  //   data: {
  //     connector: torus,
  //     name: 'TORUS',
  //     iconName: '/images/walletImages/torus.png',
  //     description: 'Your Gateway to the World of Blockchain',
  //     href: null,
  //     color: '#000000',
  //   },
  // },
];
