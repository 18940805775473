// let baseurl = "http://localhost:1871";
let baseurl = "https://apistaking.taralityplus.com";
let user = `${baseurl}/api/v1/user`;
let admin = `${baseurl}/api/v1/admin`;
export const websiteName =
  window.location.protocol + "//" + window.location.host;

const apiConfig = {
  uploadFile: `${user}/uploadFile`,
  createStacking: `${user}/createStacking`,
  listStacking: `${user}/listStacking`,
  setCommision: `${admin}/setCommision`,
  getCommision: `${admin}/getCommision`,
  usergetCommision: `${user}/getCommision`,

  //USER
  connectWallet: `${user}/connectWallet`,

  // profile: `${user}/profile`,
  // updateProfile: `${user}/updateProfile`,
  // recentCollectionList: `${newuser}/collection/recentCollectionList`,

  // userOwendCount: `${user}/userOwendCount/`,
  // userCreatedCount: `${user}/userCreatedCount/`,
  // createReports: `${user}/createReports`,
  // getUserDetails: `${user}/getUserDetails/`,
  // userFollowingCount: `${user}/userFollowingCount/`,
  // userFollowerCount: `${user}/userFollowerCount/`,
  // userLikesCount: `${user}/userLikesCount/`,
  // nftSoldCount: `${user}/nftSoldCount/`,
  // followUnfollow: `${user}/followUnfollow/`,

  //nft
  // ipfsUpload: `${nft}/ipfsUpload`,
  // createNFT: `${nft}/createNFT`,
  // uploadNFT: `${nft}/uploadNFT`,
  // likeDislikeNft: `${nft}/likeDislikeNft/`,
  // cancelOrder: `${nft}/cancelOrder`,
  // sendNFT: `${nft}/sendNFT`,
  // showNftHistory: `${nft}/showNftHistory`,

  // //order
  // createOrder: `${order}/createOrder`,
  // allListOrder: `${order}/allListOrder`,
  // viewOrder: `${order}/viewOrder/`,
  // editOrder: `${order}/editOrder`,
  // buyOrder: `${order}/buyOrder`,
  // likeDislikeOrder: `${order}/likeDislikeOrder/`,
  // sendOrderToUser: `${order}/sendOrderToUser`,

  // //collection
  // createCollection: `${collection}/createCollection`,
  // myCollectionList: `${collection}/myCollectionList`,
  // viewCollection: `${collection}/viewCollection/`,
  // collectionList: `${collection}/collectionList/`,

  // //nfttopseller
  // topSeller: `${newuser}/user/topSalers`,
  // topBuyers: `${newuser}/user/topBuyers`,

  // //collectionOrderList
  // collectionOrderList: `${newuser}/order/particularCollectionOrderList`,

  // //activity
  // allListActivity: `${activity}/allListActivity`,
  // listActivity: `${activity}/listActivity`,

  // //activity
  // createBid: `${bid}/createBid`,
  // hotBid: `${bid}/hotBid`,
  // showActivity: `${nft}/showActivity`,

  // //notification
  // readNotification: `${notification}/readNotification`,
};
export default apiConfig;
