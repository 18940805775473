import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import StakeCardUnactive from "../../../components/StakeCardUnactive";

const useStyles = makeStyles((theme) => ({}));
function Unactive(props) {
  const classes = useStyles();
  return (
    <Box>
      {" "}
      <StakeCardUnactive />
    </Box>
  );
}

export default Unactive;
