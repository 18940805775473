import React from "react";
import {
  makeStyles,
  Grid,
  Typography,
  Box,
  Card,
  CardContent,
  CardActions,
  Button,
  Container,
} from "@material-ui/core";
import Slider from "react-slick";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

const useStyles = makeStyles((theme) => ({

  items1: {
    // display: "flex",
    // justifyContent: "center",
    width: "100%",
    height: "100%",
    // flexDirection: "column"
  },

  cardBox: {
    width: "220px",
    height: "100%",
    position: "relative",
    padding: "10px",
    "&:after": {
      height: "40%",
      width: "100%",
      content: "'' ",
      border: "1px dashed #0AC0EC",
      borderBottom: "none",
      top: 0,
      left: 0,
      position: "absolute",
      backgroundColor: "transparent",
      borderRadius: "10px",
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
    "&:before": {
      //   marginTop: "5px",
      height: "40%",
      width: "100%",
      content: "'' ",
      border: "1px solid #0AC0EC",
      borderTop: "none",
      top: 171,
      left: 0,
      position: "absolute",
      backgroundColor: "transparent",
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      borderRadius: "10px",
    },
  },

  root: {
    // minWidth: "100%",
    width: "202px",
    height: "268px",
    borderRadius: "5px",
    // background: "#0CA9CB",
    background:
      "linear-gradient(180deg, rgba(12, 169, 203, 0.3) 30%, rgba(196, 196, 196, 0) 100%)",
    // background: "rgba(12, 42, 48, 0.3)",
    boxShadow: "100px",
    border: "1px solid #A6A6A6",
    boxSizing: "border-box",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: "16px",
    color: "#FFFFFF",
    textAlign: "center",
    lineHeight: "28px",
  fontWeight:"bold",
  },
  title1: {
    fontSize: "12px",
    color: "#FFFFFF",
    // textAlign: "center",
    fontFamily: "Montserrat",
    lineHeight: "19px",
    display: "flex",
    justifyContent: "center",
    paddingTop: "10px",
  },
  pos: {
    marginBottom: 12,
  },


  textbox: {
    "& h1": {
      fontSize: "50px",
      fontWeight: "bold",
      color: "white",
      marginTop: "-72px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "30px",
        marginTop: "-29px",
      },
      "& span": {
        color: "#392472",
      },
    },
    "& p": {
      color: "white", 
      fontSize: "14px", 
      maxWidth: "767px", 
      lineHeight: "25px",
    },
  
    "& small": {
      justifyContent: "center",
      "& div": {
        width: "50px",
        height: "1px",
        backgroundColor: "white",
        marginRight: "10px",
      },
      fontSize: "25px",
      color: "#392472",
      textShadow: "0px 0px 29px #392472",
      display: "flex",
      marginTop: "10px",
      alignItems: "center",
    },
  },

  mainBox:{
display:"flex",
justifyContent:"center",
alignItems:"center",
  },
}));

function HowToBuy() {
  const classes = useStyles();
  const settings = {
    dots: false,
    slidesToShow: 5,
    slidesToScroll: 3,
    arrows: false,
    className: "recomended",
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2,
          centerMode: false,
          centerPadding: "20px",
          autoplay: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          centerPadding: "20px",
          autoplay: true,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          arrows: false,
          centerMode: false,
          centerPadding: "20px",
          autoplay: true,
    
        },
      },
    ],
  };
  return (
    <Box>
      <Container>
      <Grid container spacing={3}>
            <Grid item xs={12} sm={2}></Grid>
            <Grid item xs={12} sm={8}>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src="./images/howtobuy.png" style={{ maxWidth: "70%" }} />
              </Box>
              <Box className={classes.textbox} align="center">
                <Typography variant="h1">How To Buy VAYU</Typography>
              </Box>
              <Box className={classes.textbox} align="center" mt={2}>
                <Typography variant="body2">
                  A suit of tools were build to help you to create your own
                  tokens and launchpads in a fast, simple and cheap way, with no
                  prior code knowledge required and 100% decentralized!
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={2}></Grid>
          </Grid>

          <Box style={{width:"100%"}} mt={7}>
          <Slider {...settings} className="roadmapslider" className={classes.items2}>
        {/* <Grid item lg={12} md={12} xs={12} className={classes.items2}> */}
        <Box className={classes.cardBox1}>
            <Box className={classes.mainBox}>
            <Box className={classes.cardBox}>
              <Card className={classes.root}>
                <CardContent>
                  <Typography
                    className={classes.title}
                    //   color="textSecondary"
                    gutterBottom
                  >
                    Buy and send BNB <br></br> to Metamask or <br></br>
                    TrustWallet
                  </Typography>
                  <Typography
                    className={classes.title1}
                    //   color="textSecondary"
                    gutterBottom
                  >
                    Lorem ipsum dolor sit amet, consectet adipiscing elit.
                    Tortor habitant donec sagittis viverra euismod Lorem ipsum
                    dolor sit amet, consectet adipiscing elit. Tortor habitant.
                  </Typography>
                </CardContent>
              </Card>
          
            </Box>
            <ArrowForwardIcon style={{ color: "#0AC0EC" }}></ArrowForwardIcon>
            </Box>
        
        
          </Box>
          <Box className={classes.cardBox1}>
            <Box className={classes.mainBox}>
            <Box className={classes.cardBox}>
              <Card className={classes.root}>
                <CardContent>
                  <Typography
                    className={classes.title}
                    //   color="textSecondary"
                    gutterBottom
                  >
                    Buy and send BNB <br></br> to Metamask or <br></br>
                    TrustWallet
                  </Typography>
                  <Typography
                    className={classes.title1}
                    //   color="textSecondary"
                    gutterBottom
                  >
                    Lorem ipsum dolor sit amet, consectet adipiscing elit.
                    Tortor habitant donec sagittis viverra euismod Lorem ipsum
                    dolor sit amet, consectet adipiscing elit. Tortor habitant.
                  </Typography>
                </CardContent>
              </Card>
          
            </Box>
            <ArrowForwardIcon style={{ color: "#0AC0EC" }}></ArrowForwardIcon>
            </Box>
        
        
          </Box>

          <Box className={classes.cardBox1}>
            <Box className={classes.mainBox}>
            <Box className={classes.cardBox}>
              <Card className={classes.root}>
                <CardContent>
                  <Typography
                    className={classes.title}
                    //   color="textSecondary"
                    gutterBottom
                  >
                    Buy and send BNB <br></br> to Metamask or <br></br>
                    TrustWallet
                  </Typography>
                  <Typography
                    className={classes.title1}
                    //   color="textSecondary"
                    gutterBottom
                  >
                    Lorem ipsum dolor sit amet, consectet adipiscing elit.
                    Tortor habitant donec sagittis viverra euismod Lorem ipsum
                    dolor sit amet, consectet adipiscing elit. Tortor habitant.
                  </Typography>
                </CardContent>
              </Card>
          
            </Box>
            <ArrowForwardIcon style={{ color: "#0AC0EC" }}></ArrowForwardIcon>
            </Box>
        
        
          </Box>

          <Box className={classes.cardBox1}>
            <Box className={classes.mainBox}>
            <Box className={classes.cardBox}>
              <Card className={classes.root}>
                <CardContent>
                  <Typography
                    className={classes.title}
                    //   color="textSecondary"
                    gutterBottom
                  >
                    Buy and send BNB <br></br> to Metamask or <br></br>
                    TrustWallet
                  </Typography>
                  <Typography
                    className={classes.title1}
                    //   color="textSecondary"
                    gutterBottom
                  >
                    Lorem ipsum dolor sit amet, consectet adipiscing elit.
                    Tortor habitant donec sagittis viverra euismod Lorem ipsum
                    dolor sit amet, consectet adipiscing elit. Tortor habitant.
                  </Typography>
                </CardContent>
              </Card>
          
            </Box>
            <ArrowForwardIcon style={{ color: "#0AC0EC" }}></ArrowForwardIcon>
            </Box>
        
        
          </Box>
          <Box className={classes.cardBox1}>
            <Box className={classes.mainBox}>
            <Box className={classes.cardBox}>
              <Card className={classes.root}>
                <CardContent>
                  <Typography
                    className={classes.title}
                    //   color="textSecondary"
                    gutterBottom
                  >
                    Buy and send BNB <br></br> to Metamask or <br></br>
                    TrustWallet
                  </Typography>
                  <Typography
                    className={classes.title1}
                    //   color="textSecondary"
                    gutterBottom
                  >
                    Lorem ipsum dolor sit amet, consectet adipiscing elit.
                    Tortor habitant donec sagittis viverra euismod Lorem ipsum
                    dolor sit amet, consectet adipiscing elit. Tortor habitant.
                  </Typography>
                </CardContent>
              </Card>
          
            </Box>
       
            </Box>
        
        
          </Box>
        {/* </Grid> */}
        </Slider>
</Box>



      </Container>
    </Box>
  );
}

export default HowToBuy;
