import React from "react";
import { Box, Typography } from "@material-ui/core";
// import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
// import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
// import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
const useStyles = makeStyles((theme) => ({
  mainBox: {
    background: "#081B29",
    backdropFilter: "blur(42px)",
    padding: "20px",
    border: "1px solid #a6a6a654",
    position: "relative",
    borderRadius: "10px",
    // marginBottom: "50px",
    transition: "0.5s",
    "& svg": {
      fontSize: "32px",
      color: "#05A4CA",
     marginTop: "20px",
    },
    "&:hover": {
      //   transform: "translateY(-10px)",
      background: "#05a4ca",
      "& svg": {
        fontSize: "32px",
        color: "#fff",
      },
      "& p": {
        fontSize: "14px",
        color: "black",
      },
     

      "& h4": {
        color: "black",
      },
    },
    "& h4": {
      fontSize: "20px",
      fontWeight: "700",
      marginBottom: "10px",
      color: "#fff",
    },
    "& figure": {
      margin: "0",
      marginBottom: "20px",
      height: "50px",
      "& img": {
        maxHeight: "50px",
      },
    },
    "& p": {
      fontSize: "14px",
      color: "#D0D0D0",
    },
    "& a": {
      fontSize: "15px",
      textDecoration: "underline",
      color: "#F6A52D",
      position: "absolute",
      bottom: "20px",
      left: "20px",
    },
  },
  iconBox: {
    position: "absolute",
    left: "23%",
    /* right: 50%; */
    transform: "translateX(-50px)",
    top: "-40px",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
}));
export default function TokenCard(props) {
  const classes = useStyles();
  const { data } = props;
  return (
    <Box className={classes.mainBox} align="left">
      <Box className={classes.iconBox}>
        <div className="hexa gon">
          {" "}
          <figure>
            {" "}
            <img src={data.icon} alt="icons" />
          </figure>
        </div>
        <Box
          style={{
            marginLeft: "2rem",
            marginTop: "3rem",
          }}
        >
          <Typography variant="h4">{data.name}</Typography>
        </Box>
      </Box>
      <Box style={{ marginTop: "90px" }}>
        <Typography variant="body2">{data.discription}</Typography>
        <ArrowForwardOutlinedIcon  />
      </Box>

    </Box>
  );
}
