import React from "react";
import BigNumber from "bignumber.js";
import { makeStyles, Typography, Box } from "@material-ui/core";

import RoiCalculatorModal from "../RoiCalculatorModal";
import { light } from "@pancakeswap/uikit";
// import lightTheme from "../../themes/RoiThem";
// import { light } from "@pancakeswap/uikit";
import { ThemeProvider } from "styled-components";

const StyledThemeProvider = (props) => {
  return <ThemeProvider theme={light} {...props} />;
};
const useStyles = makeStyles((theme) => ({
  root: {
    "& .heading": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      // backgroundColor: "#000000",
      padding: "15px",
      "& h4": {
        color: "#FFFFFF",
      },
    },
  },
  maincontent: {
    padding: "5px 15px",
    "& .brewlabs": {
      "& p": {
        paddingBottom: "5px",
        color: "#FFFFFF",
      },
      "& .brewlabsbox": {
        border: "1px solid #FFFFFF",
        borderRadius: "15px",
        padding: "10px",
        "& input": {
          backgroundColor: " transparent",
          border: "none",
          color: "#ffff",
          fontSize: "18px",
          fontWeight: 800,
          textAlign: "right",
          width: "100%",
          "&:focus-visible": {
            outline: "none !important",
          },
        },
      },
    },
    "& .buttonbox": {
      padding: "5px 0px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      "& Button": {
        color: "#FFFFFF",
      },
    },
    "& .staked": {
      "& p": {
        padding: "5px 0px",
        color: "#FFFFFF",
      },
    },
    "& .compounding": {
      padding: "10px 0px",
      "& p": {
        padding: "5px 0px",
        color: "#FFFFFF",
      },
    },
    "& .currentrate": {
      border: "1px solid #FFFFFF",
      borderRadius: "15px",
      padding: "15px",
      "& h6": {
        fontWeight: "bold",
        color: "#FFFFFF",
      },
      "& p": {
        color: "#FFFFFF",
      },
      "& .value": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      },
    },
  },
  mainhelpicon: {
    position: "relative",
    "& .textbox": {
      position: "absolute",
      width: "300px",
      padding: "20px",
      backgroundColor: "#000000",
      borderRadius: "15px",
      right: "-10px",
      top: "-115px",
      display: "none",
      "& p": {
        color: "#FFFFFF",
      },
      "&::after": {
        content: " '' ",
        width: "0",
        height: "0",
        borderLeft: "10px solid transparent",
        borderRight: "10px solid transparent",
        borderTop: "20px solid #000000",
        position: "absolute",
        bottom: "-20px",
        right: "20px",
      },
    },
    "&:hover": {
      "& .textbox": {
        display: "block",
      },
    },
  },
  detailsection: {},
  basecontent: {
    "& .detailcontent": {
      color: "#FFFFFF",
    },
    "& ul": {
      "& li": {
        color: "#FFFFFF",
      },
    },
  },
}));

function RoiCalculator({
  earningTokenSymbol,
  RoiCalculator,
  balanceOf,
  stakeName,
  APY,
  APYpercentage,
  stakeTokenPrice,
  rewardTokenPrice,
}) {
  const classes = useStyles();
  // const [roiCalculator, setRoiCalculator] = useState();
  const [checked, setChecked] = React.useState(true);
  const [alignment, setAlignment] = React.useState("left");
  console.log(APY, "apy----", balanceOf, "balanceOf");

  return (
    <>
      <Box className={classes.root}>
        <Box className="heading">
          <Typography variant="h4">ROI Calculator</Typography>
        </Box>
        {APY && (
          <div className="removeScroll">
            <StyledThemeProvider>
              <RoiCalculatorModal
                balanceOfBNBInUSD={0}
                linkLabel={stakeName}
                stakingTokenBalance={BigNumber(APY)} //staking token balance
                stakingTokenSymbol={stakeName} // token name
                stakingTokenPrice={stakeTokenPrice} //token Price im market
                earningTokenPrice={rewardTokenPrice}
                earningTokenSymbol={earningTokenSymbol}
                apr={APYpercentage}
                multiplier={"40X"}
                displayApr={"37.29"}
                linkHref={
                  "https://pancakeswap.finance/add/BNB/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82"
                }
                isFarm={true}
              />
            </StyledThemeProvider>
          </div>
        )}
      </Box>
    </>
  );
}

export default RoiCalculator;
