import { Contract } from "@ethersproject/contracts";
import { toast } from "react-toastify";
import Web3 from "web3";
import { default_RPC_URL } from "../constants/";
import axios from "axios";

export const mulltiCallAddressBnb =
  "0x32F8647b2ff89B454170A25037D7643d219d6A19";
export const mulltiCallAddressEth =
  "0x2cc8688C5f75E365aaEEb4ea8D6a480405A48D2A";
export const mulltiCallAddressMatic =
  "0xa854Abe6A309C5362A62187C8478AbE6cE88cbaE";
export function sortAddress(add) {
  if (add) {
    const sortAdd = `${add.slice(0, 6)}...${add.slice(add.length - 4)}`;
    return sortAdd;
  } else {
    return add;
  }
}
export function sortAddress1(add) {
  if (add) {
    const sortAdd = `${add.slice(0, 18)}...${add.slice(add.length - 4)}`;
    return sortAdd;
  } else {
    return add;
  }
}

export function getSigner(library, account) {
  return library.getSigner(account).connectUnchecked();
}

export function getProviderOrSigner(library, account) {
  return account ? getSigner(library, account) : library;
}

export function getContract(address, ABI, library, account) {
  return new Contract(address, ABI, getProviderOrSigner(library, account));
}

export const getWeb3Obj = async (RPC_URL = default_RPC_URL) => {
  const httpProvider = new Web3.providers.HttpProvider(RPC_URL);
  const web3 = await new Web3(httpProvider);
  return web3;
};

export const getWeb3ContractObject = async (abi, contractAddress, RPC_URL) => {
  const web3 = await getWeb3Obj(RPC_URL);
  const contract = await new web3.eth.Contract(abi, contractAddress);
  return contract;
};

export const mainnetRPCURL = "https://bsc-dataseed.binance.org/";
export const pancakeRouter = "0x10ED43C718714eb63d5aA57B78B54704E256024E";
const bscBaseURL = `https://api.bscscan.com/`;
export const accountBNBBalance = async (senderAddress) => {
  const web3 = await getWeb3Obj();
  try {
    const response = await axios.get(
      `${bscBaseURL}api?module=account&action=balance&address=${senderAddress}&tag=latest&apikey=13QG8WB4HIGMBHVN8ZGQZRN896ZGXCY5QJ`
    );
    let balance = web3.utils.fromWei(response.data.result, "ether");
    console.log("price", Number(balance));
    return Number(balance);
  } catch (error) {
    alert(error);
  }
};

export function copyTextById(id) {
  var copyText = document.getElementById(id);
  copyText.select();
  copyText.setSelectionRange(0, 99999); /* For mobile devices */
  navigator.clipboard.writeText(copyText.value);
  toast.info(`Copied ${copyText.value}`);
}
