import {
  Container,
  Box,
  Typography,
  Grid,
  makeStyles,
} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles({
  typo: {
    color: "white",
    textDecoration: "none",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "30px",
    textAlign: "center",
    textDecorationLine: "underline",
    marginTop: "1.5rem",
  },
  sate: {
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "30px",
  },
});

export default function NewFooter1() {
  const classes = useStyles();
  return (
    <Box style={{ background: "#0E2137" }}>
      <Container>
        <Grid container spacing={4}>
          <Grid item lg={5} sm={3} md={5} xs={12}>
            <img src="images/Logo1.png" />
          </Grid>
          <Grid item lg={7} sm={9} md={7} xs={12}>
            <Box style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography className={classes.typo}>
                Terms and conditions
              </Typography>

              <Typography className={classes.typo}>Privacy Policy</Typography>

              <Typography className={classes.typo}>Documentations</Typography>

              <Typography className={classes.typo}>Audits</Typography>
            </Box>
          </Grid>
        </Grid>
        <Box
          style={{ borderTop: "1px solid #686868" }}
          textAlign="center"
          mt={1}
          pt={2}
          pb={3}
        >
          <Typography className={classes.sate}>
            Satellite © 2022 • support@satellite • All rights reserved.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}
