import React, { useState, useEffect } from "react";
import {
  Route,
  Switch,
  Redirect,
  withRouter,
  useLocation,
} from "react-router-dom";
import classnames from "classnames";
import { makeStyles, Box } from "@material-ui/core";
//icons

// components
import Header1 from "../Header/Homeheader";
import Header from "../Header";
import Sidebar from "../Sidebar";

// pages
import Maindashboard from "../../pages/maindashboard/Maindashboard";
import Alldashboard from "../../pages/maindashboard/Alldashboard";
import Dashboard from "../../pages/dashboard";
import Staking from "../../pages/staking/Staking";
import IndexBuySwap from "../../pages/BuySwap/IndexBuySwap";
import IndexSwaps from "../../pages/BuySwap/Swap/IndexSwap";
import LiveStake from "../../pages/staking/LiveStake";
import FinishedStake from "../../pages/staking/FinishedStake";
import Rewards from "../../pages/staking/Rewards";
import Withdraw from "../../pages/staking/Withdraw";
import CreateStake from "../../pages/staking/CreateStake";
import Token from "../../pages/token/Token";
import Launched from "../../pages/launched/Launched";
import LivePreSales from "../../pages/launched/LivePreSales";
import Sniffer from "../../pages/sniffer/Sniffer";
import Notification from "../../pages/Notification/Notification";
import Launchedform from "../../pages/launched/Launchedform";
import LaunchedDetail from "../../pages/launched/LaunchedDetail";
import Liquidity from "../../pages/liquidity/Liquidity";
import Index from "../../pages/VerifiedPresell/Index";
import LockLiquidity from "../../pages/LiquidityLocker/LockLiquidity";
import Locker from "../../pages/LiquidityLocker/Locker";
import TokenLocker from "../../pages/LiquidityLocker/TokenLocker";
import TabPage from "../../pages/tabs/TabPage";
import About from "../../pages/About/About";
import Terms from "../../pages/Alert/Terms";
import Privacy from "../../pages/Alert/Privacy";
import Documentation from "../../pages/Alert/Documentation";
import Analytics from "../../pages/Analytics/Analytics";
import Chart from "../../pages/Chart/Chart";
import IndexSwap from "../../pages/Swap/IndexSwap";
import ControlPage from "../../pages/maindashboard/ControlPage";
import SeteliteStart from "../../components/SeteliteStart";
import StakingTabe from "../../pages/Stake/SateliteStake";
// import Tab from "../../pages/Tab/index";
// import Launchpad from "../../pages/tabs/Launchpa
import { useLayoutState } from "../../context/LayoutContext";
import Footer from "./Footer";
// import HowitWorks from "../../pages/Howitworks";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    maxWidth: "100vw",
    overflowX: "hidden",
  },
  content: {
    flexGrow: 1,
    // padding: "24px 100px 0",
    width: `calc(100vw - ${240 + theme.spacing(6)}px)`,
    minHeight: "100vh",
    paddingTop: "80px",
    zIndex: "1",
    overflow: "hidden",

    position: "relative",
    "@media (max-width:1440px)": {
      padding: "24px 30px 0",
    },
    [theme.breakpoints.down("md")]: {
      padding: "24px 24px",
      paddingTop: "80px",
    },
  },
  contentShift: {
    // backgroundImage: "url('../images/banner.png')",
    background:
      "radial-gradient(48.06% 27.42% at 84.2% 48.18%, rgba(32, 104, 188, 0.45) 0%, rgba(52, 107, 171, 0) 100%) , radial-gradient(39.41% 25.47% at 12.19% 49%, rgb(193 215 224 / 45%) 0%, rgba(221, 237, 254, 0.45) 100%) , #FFFFFF",
    zIndex: " 1",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    // backgroundAttachment: "fixed",
    width: `calc(99vw - 225px)`,
    position: "absolute",
    top: 0,
    padding: 0,
    right: "0",
    [theme.breakpoints.down("sm")]: {
      width: `100%`,
    },
  },
  fakeToolbar: {
    ...theme.mixins.toolbar,
  },
  link: {
    "&:not(:first-child)": {
      paddingLeft: 15,
    },
  },

  buttonright: {
    background: "linear-gradient(90deg, #2599FA -23.36%, #42E8E0 59.43%)",
    fontSize: "14px ",
    border: "1px solid transparent",
    fontWeight: 600,
    height: "44px ",
    color: "#FFFFFF",
    minWidth: "135px ",
    borderRadius: "5px",
    boxShadow: "none ",
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      height: "45px ",
      minWidth: "120px ",
    },
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      color: "#fff",
      transition: "0.5s",
      boxSizing: " border-box",
      border: " 2px solid transparent",
      backgroundClip: "padding-box, border-box",
      backgroundOrigin: "padding-box, border-box",
      backgroundImage:
        "linear-gradient(#000, #000), linear-gradient(#2599fa, #42E8E0)",
    },
  },
  mainBodyBox: {
    // height: "100vh",
    // paddingBottom: "100px",
    padding: "100px 0",
    minHeight: "87vh",
    "@media(maxWidth:1280px)": {
      minHeight: "calc(100vh - 145px)",
    },
  },
}));
function Layout(props) {
  const classes = useStyles();
  const location = useLocation();
  const [checkLayout, setCheckLayout] = useState(false);
  // console.log("URL====", window.location.href);
  useEffect(() => {
    const checkUrl = window.location.href.split("#");
    // console.log("locationCheck*********", checkUrl);
    if (checkUrl[1] === "/app/home") {
      setCheckLayout(true);
    } else {
      setCheckLayout(false);
    }
  }, [window.location.href]);

  var layoutState = useLayoutState();
  return (
    <div className={classes.root}>
      {/* {checkLayout ? (
        <>
          <Header1 history={props.history} />

          <div
            className={classnames(classes.content, {
              [classes.contentShift]: layoutState.isSidebarOpened,
            })}
            style={{
              width: "100%",
              // , paddingLeft: "0px", paddingRight: "0px"
            }}
          >
            {" "}
            <Box className={classes.mainBodyBox}>
              <Switch>
                <Route path="/app/home" component={Dashboard} />
              </Switch>
            </Box>
            <Footer checkLayout={checkLayout} />
          </div>
        </>
      ) : ( */}
      <>
        <Header history={props.history} />
        <Sidebar />
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
        >
          <Box className={classes.mainBodyBox}>
            <Switch>
              Staking
              <Route path="/app/stake-dashboard" component={Maindashboard} />
              <Route path="/app/create-staking" component={Alldashboard} />
              <Route path="/app/About" component={About} />
              <Route path="/app/token" component={Token} />
              <Route path="/app/launched" component={Launched} />
              <Route path="/app/sniffer" component={Sniffer} />
              <Route path="/app/create-launched" component={Launchedform} />
              <Route path="/app/notification" component={Notification} />
              <Route path="/app/create-stakE" component={CreateStake} />
              <Route path="/app/reward-staking" component={Rewards} />
              <Route path="/app/withDraw-stake" component={Withdraw} />
              <Route path="/app/launched-detail" component={LaunchedDetail} />
              <Route
                path="/app/launched-live-presale"
                component={LivePreSales}
              />
              <Route path="/app/buy-swap" component={IndexBuySwap} />
              <Route path="/app/swap" component={IndexSwaps} />
              <Route path="/app/analytics" component={Chart} />
              <Route path="/app/chart" component={Analytics} />
              <Route path="/app/liquidity" component={Liquidity} />
              <Route path="/app/lock-liquidity" component={LockLiquidity} />
              <Route path="/app/verifyed-presell" component={Index} />
              <Route path="/app/locker" component={Locker} />
              <Route path="/app/lock-token" component={TokenLocker} />
              <Route path="/app/staking" component={Staking} />
              <Route path="/app/live-staking" component={LiveStake} />
              <Route path="/app/finished-staking" component={FinishedStake} />
              <Route path="/app/tab" component={TabPage} />
              <Route path="/app/terms-conditions" component={Terms} />
              <Route path="/app/privacy-policy" component={Privacy} />
              <Route path="/app/documentation" component={Documentation} />
              <Route path="/app/stake-pools" component={IndexSwap} />
              <Route path="/app/control" component={ControlPage} />
              <Route path="/app/setelite-start" component={SeteliteStart} />
              <Route path="/app/stake-satellite" component={StakingTabe} />
              {/* <Route path="/app/search-NFT" component={UserSearchNFT} /> */}
              <Route
                exact
                path="/app/ui"
                render={() => <Redirect to="/app/ui/icons" />}
              />
            </Switch>
          </Box>

          <Footer />
        </div>
      </>
      {/* )} */}
    </div>
  );
}

export default withRouter(Layout);
