import React, { createContext, useEffect, useState } from "react";
import { injected, SUPPORTED_WALLETS } from "../connectors";
import { useWeb3React } from "@web3-react/core";
import { ACTIVE_NETWORK, NetworkDetails } from "../constants/";
import { getContract } from "../utils";
// import {
//   stakingConAddress1,
//   stakingConAddress2,
//   stakingConAddress3,
//   stakingConAddress4,
// } from "../constants/index";
// import stakingABI1 from "../abis/StakingABI1.json";
// import stakingABI2 from "../abis/StakingABI2.json";
// import stakingABI3 from "../abis/StakingABI3.json";
// import stakingABI4 from "../abis/StakingABI4.json";
import axios from "axios";
import { toast } from "react-toastify";
// import apiConfig from "../config/ApiConfig";
import Web3 from "web3";
import apiConfig from "../config/ApiConfig";

export const UserContext = createContext();
// const { activate, deactivate, account, library, chainId } = useWeb3React();
const setSession = async (userAddress) => {
  if (userAddress) {
    sessionStorage.setItem("userAddress", userAddress);
  } else {
    sessionStorage.removeItem("userAddress");
  }
};

const setTokenSession = (token) => {
  if (token) {
    sessionStorage.setItem("token", token);
  } else {
    sessionStorage.removeItem("token");
  }
};

export default function AuthProvider(props) {
  const { activate, deactivate, account, library, chainId } = useWeb3React();
  const [isLogin, setIsLogin] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [dashboardData, setDashboardData] = useState([]);
  // console.log("------dashboard-----data", dashboardData);
  const [errorMsg] = useState("");
  const [successMSG, setSuccessMSG] = useState("");
  const [userDetails, setUserDetails] = useState("");
  const [userData, setUserData] = useState();
  const [controlData, setControlData] = useState();
  const [yourWalletBalance, setYourWalletBalance] = useState(0);
  const [adminAddress, setAdminAddress] = useState({
    contract1: "",
    contrsct2: "",
    contract3: "",
    contract4: "",
  });
  // console.log("-----------------58---");
  // useEffect(() => {
  //   console.log("-----------------60---");
  //   if (account && chainId) {
  //     if (chainId !== ACTIVE_NETWORK) {
  //       window.scrollTo(0, 0);
  //       if (window.ethereum) {
  //         swichNetworkHandler();
  //       }
  //     }
  //   }
  // }, [chainId, account]);

  // const swichNetworkHandler = async (id) => {
  //   try {
  //     await window.ethereum.request({
  //       method: "wallet_switchEthereumChain",
  //       params: [{ chainId: "0x" + ACTIVE_NETWORK.toString(16) }],
  //     });
  //   } catch (error) {
  //     console.log("ERROR", error);
  //     // toast.warn(error.message);
  //     if (error.code === 4902) {
  //       addNetworkHandler();
  //     }
  //   }
  // };

  // const addNetworkHandler = async () => {
  //   try {
  //     await window.ethereum.request({
  //       method: "wallet_addEthereumChain",
  //       params: NetworkDetails,
  //     });
  //   } catch (error) {
  //     console.log("ERROR", error);
  //     toast.warn(error.message);
  //   }
  // };

  useEffect(() => {
    if (account) {
      connectWalletAPICall();
      // setWalletAddress(account);
    } else {
      setIsLogin(false);
      setTokenSession(null);
    }
  }, [account]);
  // console.log("account", account);

  const connectWalletAPICall = async () => {
    try {
      // console.log("account", account);
      // const res = await axios.post(apiConfig.connectWallet, {
      //   walletAddress: account,
      // });
      const res = await axios({
        method: "POST",
        url: apiConfig.connectWallet,
        // headers: {
        //   authorization: `Bearer ${window.localStorage.getItem("token")}`,
        // },
        data: {
          walletAddress: account,
        },
      });
      if (res.data.statusCode === 200) {
        setTokenSession(res.data.result.token);
        setUserDetails(res.data.result);
        // console.log("account", account);
        setIsLogin(true);
      } else {
        toast.error(res.data.responseMessage);
        setTokenSession();
      }
    } catch (error) {
      setTokenSession();
      console.log("ERROR", error);
      toast.error(error.message);
    }
  };
  useEffect(() => {
    GetCommition();
  }, []);

  // console.log("-----------------143---");
  const GetCommition = async () => {
    try {
      const res = await axios({
        method: "GET",
        url: apiConfig.usergetCommision,
        // headers: {
        //   token: sessionStorage.getItem("token"),
        // },
      });
      console.log("res", res);
      if (res.data.statusCode === 200) {
        console.log(res);
        setControlData(res.data.result[0]);
      }
      console.log("-----------------158---");
    } catch (error) {
      console.log("EROR", error);
    }
  };

  // const GetOwnerWallet = async (id) => {
  //   try {
  //     const contract1 = getContract(
  //       stakingConAddress1,
  //       stakingABI1,
  //       library,
  //       account
  //     );
  //     const contract2 = getContract(
  //       stakingConAddress2,
  //       stakingABI2,
  //       library,
  //       account
  //     );

  //     const contract3 = getContract(
  //       stakingConAddress3,
  //       stakingABI3,
  //       library,
  //       account
  //     );
  //     const contract4 = getContract(
  //       stakingConAddress4,
  //       stakingABI4,
  //       library,
  //       account
  //     );
  //     const contractOwner1 = await contract1.owner();
  //     const contractOwner2 = await contract2.owner();
  //     const contractOwner3 = await contract3.owner();
  //     const contractOwner4 = await contract4.owner();

  //     setAdminAddress({
  //       contract1: contractOwner1,
  //       contrsct2: contractOwner2,
  //       contract3: contractOwner3,
  //       contract4: contractOwner4,
  //     });
  //   } catch (error) {
  //     // toast.error(error.message);
  //     // setharvestLoader(false);
  //     console.log(error);
  //   }
  //   // } else {
  //   //   toast.error("Reward Per Block Updated more than staked !");
  //   // }
  // };
  // useEffect(() => {
  //   GetOwnerWallet();
  // }, [account]);

  // console.log("-----------------215---");
  let data = {
    isLogin,
    isLoading,
    errorMsg,
    successMSG,
    userData,
    yourWalletBalance,
    adminAddress,
    setDashboardData,
    dashboardData,
    userDetails,
    controlData,
    updateUser: (account) => {
      setSession(account);
    },

    connectWallet: (data) => connectToWallet(data),
    logoutHanlder: () => {
      setUserData();
      setIsLogin(false);
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("walletName");
      deactivate();
    },
  };

  const getUserbalce = async () => {
    var web3 = new Web3(library.provider);
    const balance = await web3.eth.getBalance(account);
    const balanceImETH = await web3.utils.fromWei(balance);
    setYourWalletBalance(parseFloat(balanceImETH).toFixed(2));
  };
  useEffect(() => {
    if (account) {
      getUserbalce();
    }
  }, [account, library]);

  const connectToWallet = (data) => {
    if (data) {
      try {
        const connector = data.connector;

        if (connector && connector.walletConnectProvider?.wc?.uri) {
          connector.walletConnectProvider = undefined;
        }

        activate(connector, undefined, true).catch((error) => {
          if (error) {
            window.sessionStorage.removeItem("walletName");
            toast.error(JSON.stringify(error.message));
            window.sessionStorage.removeItem("walletName");
            activate(connector);
          }
        });
      } catch (error) {
        toast.error(JSON.stringify(error.message));
      }
    }
  };

  useEffect(() => {
    if (window.sessionStorage.getItem("walletName")) {
      const selectectWalletDetails = SUPPORTED_WALLETS.filter(
        (data) => data.name === window.sessionStorage.getItem("walletName")
      );
      if (selectectWalletDetails[0]) {
        connectToWallet(selectectWalletDetails[0].data);
      }
    }
  }, []);

  useEffect(() => {
    data.updateUser(account);
  }, [account]); //eslint-disable-line

  return (
    <UserContext.Provider value={data}>{props.children}</UserContext.Provider>
  );
}
