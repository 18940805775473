import React, { useState } from "react";
import {
  Box,
  makeStyles,
  Typography,
  Grid,
  Button,
  Accordion,
  CircularProgress,
  InputAdornment,
  Dialog,
  // Container,
  TextField,
} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import ConnectWallet from "./ConnectWalletPopUp";
import Web3 from "web3";

import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { BiTimer } from "react-icons/bi";
import { AiOutlineCalculator } from "react-icons/ai";
import { MdContentCopy } from "react-icons/md";
import { AiOutlineArrowRight } from "react-icons/ai";

import { useWeb3React } from "@web3-react/core";
import RoiCalculator from "./RoiCalculator/RoiCalculator";
import { ExpandLess } from "@material-ui/icons";
const useStyles = makeStyles((theme) => ({
  compoundBtn: {
    // backgroundColor: "#50525533",
    color: "white",
    background: "#0E2137",
    // padding: "0 10px",
    // color: "#4cb3dc !important",
    "&:hover": {
      backgroundColor: "#4cb3dc",
      color: "#fff !important",
    },
  },
  RoiCalculator: {
    "& .MuiDialogContent-root": {
      padding: "24px 0",
    },
  },
  box1: {
    marginTop: "3rem",
    paddingBottom: "50px",
    "& h1": {
      fontFamily: "'Lexend Deca', sans-serif",
      // fontFamily: "'Lexend Deca'",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "45px",
      lineHeight: "55px",
      textTransform: "capitalize",
      color: "#0E2137",
      textAlign: "center",
      "@media(max-width:767px)": {
        fontSize: "25px",
      },
    },
  },
  // root1: {
  //   width: "100%",
  //   // marginTop: "50px",
  // },
  accordion: {
    marginTop: "20px",
    border: "1.5px solid #50A5C6",
    height: "100%",
    overflow: "hidden",
    position: "relative",
    textAlign: "center",
    transition: "0.3s",
    borderRadius: "20px",
    backgroundColor: "#fff",
  },
  heading: {
    fontFamily: "'Lexend Deca'",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "21px",
    color: "#0E2137",
    "& .MuiButtonBase-root.Mui-disabled": {
      backgroundColor: "gray",
      marginTop: "10px",
    },
  },
  heading0: {
    fontFamily: "'Lexend Deca'",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "21px",
    color: "#0E2137",
  },
  heading10: {
    fontFamily: "'Lexend Deca'",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "21px",
    color: "#0E2137",
  },
  heading1: {
    fontFamily: "'Lexend Deca'",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "21px",
    color: "#5F5F5F",
  },
  box22: {
    display: "flex",
    justifyContent: "end",
    marginTop: "25px",
  },
  btn: {
    background: " #D6F3FF ",
    borderRadius: "5px",
    "@media(max-width:960px)  ": {
      marginTop: "10px",
    },
  },
  btn1: {
    marginTop: "10px",
    background: " #D6F3FF ",
    // background: " #D6F3FF ",
    borderRadius: "5px",
  },
  heading2: {
    fontFamily: "'Lexend Deca'",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "24px",
    color: "#31B0E3",
  },
  heading3: {
    marginTop: "10px",
    fontFamily: "'Lexend Deca'",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "24px",
    color: "#31B0E3",
  },
  heading4: {
    fontFamily: "'Lexend Deca'",
    // fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "21px",
    color: "#0E2137",
    marginTop: "10px",
  },
  btn2: {
    color: "white",
    background: "#0E2137",
    borderRadius: "10px",
    // borderRadius: "10px",
    padding: "15px",
    paddingLeft: "20px",
    paddingRigth: "20px",
    "&:hover": {
      background: "#3cb4e4",
    },
  },
  box2: {
    marginTop: "20px",
    display: "flex",
    // justifyContent: "end",
  },
  btnDetails: {
    color: "#31B0E3",
    fontFamily: "'Lexend Deca'",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "24px",
    "@media(max-width:960px)": {
      display: "none  ",
    },
  },
  // tp1: {
  //   "@media(min-width:1280px)": {
  //     textAlign: "center",
  //   },
  // },
  btn3: {
    background: "#0E2137",
    borderRadius: "10px",
    width: "21rem",
    height: "50px",
    marginTop: "34px",
    color: " #FFFFFF",
    fontWeight: "600",
    "&:hover": {
      background: "#3cb4e4",
    },
    "@media(max-width:1280px)": {
      width: "11rem",
    },
    "@media(max-width:960px)": {
      //   display: "none",
    },
  },
  btn4: {
    "@media(min-width:960px)": {
      //   display: "none",
    },

    "@media(max-width:960px)": {
      //   display: "none",
      width: "200%",
      background: "#0E2137",
      borderRadius: "10px",
      // width: "21rem",
      height: "50px",
      marginTop: "34px",
      color: " #FFFFFF",
      fontWeight: "600",
    },
  },
  box0: {
    display: "flex",
    justifyContent: "center",
    "& h5": {
      marginTop: "30px",
      maxWidth: "56rem",
      fontFamily: "'Lexend Deca'",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "24px",
      textAlign: "center",
      color: " #626262",
    },
  },
  textbtn: {
    fontFamily: "'Lexend Deca'",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "13px",
    lineHeight: "20px",
    color: "#31B0E3",
  },
  btn31: {
    // display: "none",
    "@media(max-width:960px)": {
      display: "initial",
      width: "200%",
      background: "#0E2137",
      borderRadius: "10px",
      // width: "21rem",
      height: "50px",
      marginTop: "34px",
      color: " #FFFFFF",
      fontWeight: "600",
    },
  },
  Dialogbox: {
    color: "white",
    maxWidth: "100%",
    "& h3": {
      // padding: "12px",
      marginTop: "14px",
      color: "white",
    },
  },
  radiusbox: {
    boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
    borderRadius: "20px",
    // border: "1px solid black",
    backgroundColor: " #89d5f5",
    padding: "10px",
    textAlign: "end",
    color: "white",
    "& input": {
      backgroundColor: "transparent",
      border: "none",
      textAlign: "right",
      color: "#0c2739",
      "&:focus-visible": {
        outline: "none",
      },
    },
  },

  Dcontent: {
    padding: "34px",
    marginTop: "6px",
    "& h6": {
      color: "white",
      fontSize: "13px",
    },
  },
  btn: {
    height: "21px",
    // background: "red",
    paddingLeft: "10px",
    paddingRigth: "10px",
    borderRadius: "50px",
  },
  btn1: {
    height: "21px",
    // background: "red",
    paddingLeft: "10px",
    paddingRigth: "10px",
    borderRadius: "50px",
  },
  threebtn: {
    marginTop: "10px",
    display: "flex",
  },
  boxday: {
    marginTop: "20px",
    "& h6": {
      fontSize: "13px",
      color: "white",
    },
  },
  btngroup5: {
    paddingBottom: "5px",
    paddingTop: "5px",
    // border: "1px solid black",
    borderRadius: "30px",
    color: "white",
    display: "flex",
    justifyContent: "space-between",
  },
  btngroup: {
    width: "44px",
    // background: "red",
    textAlign: "center",
    borderRadius: "30px",
    fontSize: "16px",
  },
  boxday2: {
    marginTop: "20px",
  },
  lstbox: {
    marginTop: "30px",
    padding: "20px",
    marginTop: "30px",
    borderRadius: "30px",
    boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
    backgroundColor: "#89d5f5",
    // border: "1px solid black",
    "& input": {
      backgroundColor: "transparent",
      border: "none",
      textAlign: "left",
      color: "#0c2739",
      "&:focus-visible": {
        outline: "none",
      },
    },
    "& h6": {
      fontSize: "13px",
      color: "#0c2739",
    },
    "& h5": {
      fontSize: "10px",
      color: "#0c2739",
      marginTop: "5px",
    },
    "& h2": {
      fontSize: "25px",
      fontWeight: "500",
      color: "#0c2739",
    },
  },
  detailsbox: {
    display: "flex",
    justifyContent: "center",
    marginTop: "30px",
  },
  dashboardBox: {
    background: " rgb(237,247,247)",
    background:
      "linear-gradient(90deg, rgb(137 213 245) 0%, rgb(4 121 255) 100%)",
    padding: "15px",
    borderRadius: "10px",
    textAlign: "center",
    position: "relative",

    "&:hover": {
      "& svg": {
        transform: "translate(-10px, -50%)",
      },
    },
    "& h5": {
      fontSize: "40px",
      color: "#ffffff",
    },
    "& p": {
      fontSize: "15px",
      color: "#323f50",
    },
    "& svg": {
      transition: "0.3s",
      position: "absolute",
      top: "50%",
      fontSize: "80px",
      transform: "translateY(-50%)",
      right: "10px",
      color: "#ffffff45",
    },
  },
  dashboardmainBox: {
    marginBottom: "50px",
  },
  modalContant: {
    "& .MuiDialog-paperWidthSm": {
      height: "225px",
    },
    "& .MuiOutlinedInput-adornedEnd": {
      color: "#fff",
    },
    "& .MuiInputBase-root": {
      color: "#fff",
    },
  },
  marginRemove: {
    width: "100%",
    "& .MuiAccordion-root.Mui-expanded": {
      margin: 0,
    },
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: "12px 0px",
    },
    "& .MuiAccordionDetails-root": {
      display: "block",
      padding: "8px 16px",
    },
  },
}));
const StakingCards = ({
  i,
  datamap,
  enableButtonLoader,
  getClaimDataHandler,
  harvestLoader,
  getUnStakingDataHandler,
  getStakingDataHandler,
  buttonLoader,
  accountTrue,
  StakingHarvest,
  loader,
  CompoundEarned,
  CompoundDivident,
  harvestclaimDividend,
  errorText,
  defaultLoader,
  // web3,
}) => {
  var classes = useStyles();
  const default_RPC_URL = "https://bsc-dataseed1.ninicoin.io";
  const httpProvider = new Web3.providers.HttpProvider(default_RPC_URL);
  const web3 = new Web3(httpProvider);
  const [openWalletConnect, setOpenWalletConnect] = React.useState(false);
  const { account, chainId } = useWeb3React();
  const [roiCalculator, setRoiCalculator] = useState(false);
  const [open, setOpen] = useState(false);
  const [openId, setOpenId] = useState("");
  const [amount, setAmount] = useState("");
  const [expanded, setIsExpanded] = useState("");
  // console.log(datamap);
  const handleClickOpen = (id) => {
    setOpenId(id);
    setOpen(true);
  };
  const HandleRoiCalculator = () => {
    setRoiCalculator(false);
  };
  const handleClose = () => {
    setAmount("");
    setOpen(false);
  };

  React.useEffect(() => {
    if (!buttonLoader) {
      setAmount("");
      setOpen(buttonLoader);
    }
  }, [buttonLoader]);

  function convertToInternationalCurrencySystem(labelValue) {
    // twelve Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e12
      ? (Math.abs(Number(labelValue)) / 1.0e12).toFixed(2) + "T"
      : // Nine Zeroes for Billions
      Math.abs(Number(labelValue)) >= 1.0e9
      ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2) + "B"
      : // Six Zeroes for Millions
      Math.abs(Number(labelValue)) >= 1.0e6
      ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + "M"
      : // Three Zeroes for Thousands
      Math.abs(Number(labelValue)) >= 1.0e3
      ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(2) + "K"
      : Math.abs(Number(labelValue));
  }
  return (
    <Box className={classes.marginRemove}>
      <Accordion className={classes.accordion}>
        <AccordionSummary
          style={{ padding: "20px" }}
          // expandIcon={<ExpandMoreIcon />}

          onClick={() => setIsExpanded((prev) => !prev)}
          aria-controls="panel1a-content"
          id="panel1a-header"
          // onClick={() => setIsExpanded((prev) => !prev)}
        >
          <Grid
            container
            style={{
              alignItems: "center",
              // marginTop: "-37px",
            }}
          >
            <Grid item lg={1} md={1} sm={1} xs={6} align="left">
              <img
                src={datamap?.image}
                alt=""
                style={{ width: "57px", paddingRight: "5px" }}
              />
            </Grid>
            <Grid item lg={3} md={3} sm={3} xs={6}>
              <Typography className={classes.heading0}>
                Earn {datamap?.rewardAddress?.name}
              </Typography>
              <Typography className={classes.heading1}>
                Stake {datamap?.tokenAddress?.name}
              </Typography>
              {datamap?.reflection && (
                <Typography className={classes.heading10}>
                  Reflection {datamap?.dividendTokensymbol}
                </Typography>
              )}
            </Grid>
            <Grid item lg={1} md={1} sm={1} xs={12} align="left">
              {" "}
              <Typography className={classes.heading1}>
                {datamap?.BUSD}
              </Typography>
              <Typography className={classes.heading}>{datamap?.h0}</Typography>
            </Grid>
            <Grid item lg={1} md={1} sm={1} xs={6} align="left">
              <Typography className={classes.heading1}>
                APR :{" "}
                {Math.round(
                  datamap?.rewardPerBlock ? datamap?.rewardPerBlock : 0
                )}{" "}
                %&nbsp;
                <AiOutlineCalculator
                  style={{ fontSize: "15px" }}
                  onClick={(event) => {
                    event.stopPropagation();
                    setRoiCalculator(true);
                  }}
                />
              </Typography>
              {/* <Typography className={classes.heading}>
                {Math.round(datamap?.rewardPerBlock)} %
              </Typography> */}
            </Grid>
            <Grid item lg={3} md={3} sm={3} xs={6}>
              <Typography className={classes.heading1}>
                Total Staked : {datamap?.totalStaked ? datamap?.totalStaked : 0}
              </Typography>
            </Grid>

            <Grid item lg={3} md={3} sm={3} xs={12} align="left">
              <Box
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  // marginTop: "-37px",
                }}
              >
                {" "}
                {/* <Button className={classes.btnDetails}>Detail</Button> */}
                {expanded ? (
                  <>
                    Hide <ExpandLess />
                  </>
                ) : (
                  <>
                    Details
                    <ExpandMoreIcon />
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails style={{ background: "#E2F6FF", padding: "30px" }}>
          <Grid container spacing={2}>
            <Grid item lg={4} md={4} sm={4} xs={6} align="left">
              <Box align="left">
                <Typography
                  className={classes.heading}
                  style={{ marginTop: "8px" }}
                >
                  {!datamap?.coinName == 1 && (
                    <a
                      style={{ textDecoration: "none", color: "#0E2137" }}
                      href={`https://etherscan.io/token/${datamap?.tokenAddress?.contractAddress}`}
                    >
                      See Token Info &nbsp;
                      <AiOutlineArrowRight style={{ color: "#31B0E3" }} />
                    </a>
                  )}
                  {!datamap?.coinName == 56 && (
                    <a
                      style={{ textDecoration: "none", color: "#0E2137" }}
                      href={`https://bscscan.com/token/${datamap?.tokenAddress?.contractAddress}`}
                    >
                      See Token Info &nbsp;
                      <AiOutlineArrowRight style={{ color: "#31B0E3" }} />
                    </a>
                  )}
                  {!datamap?.coinName == 137 && (
                    <a
                      style={{ textDecoration: "none", color: "#0E2137" }}
                      href={`https://polygonscan.com//token/${datamap?.tokenAddress?.contractAddress}`}
                    >
                      See Token Info &nbsp;
                      <AiOutlineArrowRight style={{ color: "#31B0E3" }} />
                    </a>
                  )}
                </Typography>
                <Typography
                  className={classes.heading}
                  style={{ marginTop: "8px" }}
                >
                  View Project Site &nbsp;
                  <MdContentCopy style={{ color: "#31B0E3" }} />
                </Typography>
              </Box>
            </Grid>

            <Grid item lg={4} md={4} sm={4} xs={6}>
              {" "}
              {!datamap?.reflection && (
                <>
                  <Typography className={classes.heading}>
                    <span style={{ color: "#31B0E3" }}>
                      {" "}
                      {datamap?.pendingRewardsETH
                        ? datamap?.pendingRewardsETH
                        : 0}{" "}
                      {datamap?.rewardAddress?.name}{" "}
                    </span>{" "}
                    Earned
                  </Typography>

                  <Typography className={classes.heading}>
                    {account && (
                      <>
                        {/* {datamap?.pendingRewardsETH > 0 && ( */}
                        <Button
                          onClick={() => getClaimDataHandler(i)}
                          className={classes.compoundBtn}
                          // disabled={harvestLoader}
                          disabled={
                            Number(datamap?.pendingRewardsETH) === 0
                              ? true
                              : false ||
                                loader ||
                                harvestLoader ||
                                defaultLoader
                          }
                        >
                          Claim
                        </Button>
                        {/* )} */}
                      </>
                    )}
                  </Typography>
                </>
              )}{" "}
            </Grid>

            <Grid
              item
              lg={4}
              md={4}
              sm={4}
              xs={12}
              style={
                {
                  // marginTop: "-37px",
                }
              }
            >
              {account ? (
                <Grid container spacing={2}>
                  <Grid item lg={6} sm={6} xs={12}>
                    <Button
                      // className={classes.compoundBtn}
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        // getStakingDataHandler(i);

                        handleClickOpen("Deposit");
                      }}
                      fullWidth
                      disabled={enableButtonLoader}
                    >
                      {" "}
                      Stake
                    </Button>
                  </Grid>
                  {/* {datamap?.userInfo?.amount > 0 && ( */}
                  <Grid item lg={6} sm={6} xs={12}>
                    <Typography className={classes.heading}>
                      <Button
                        // className={classes.compoundBtn}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          // getUnStakingDataHandler(i)
                          handleClickOpen("Widthdraw");
                        }}
                        fullWidth
                        disabled={
                          enableButtonLoader || datamap?.userInfo?.amount > 0
                        }
                      >
                        {" "}
                        Unstake
                      </Button>
                    </Typography>
                  </Grid>
                  {/* )} */}
                </Grid>
              ) : (
                <Button
                  className={classes.btn3}
                  onClick={() => setOpenWalletConnect(true)}
                >
                  {" "}
                  Connect Wallets
                </Button>
              )}
            </Grid>
            {datamap?.reflection && account && (
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} lg={6} sm={6} md={6} align="left">
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} lg={6} sm={6} md={6}>
                      <Typography className={classes.heading}>
                        {" "}
                        <small style={{ color: "#31B0E3" }}>
                          {" "}
                          {datamap?.dividendTokensymbol}
                        </small>{" "}
                        REFLECTED{" "}
                      </Typography>
                      {account ? (
                        <Typography
                          variant="body1"
                          style={{ fontSize: "16px", color: "#31B0E3" }}
                        >
                          {" "}
                          {Number(datamap?.pendingDividends) === 0
                            ? 0.0
                            : convertToInternationalCurrencySystem(
                                parseFloat(datamap?.pendingDividends).toFixed(8)
                              )}
                        </Typography>
                      ) : (
                        <Typography
                          variant="body1"
                          style={{ fontSize: "16px", color: "#31B0E3" }}
                        >
                          0
                        </Typography>
                      )}
                    </Grid>

                    <Grid item xs={12} lg={6} sm={6} md={6}>
                      <Box style={{ display: "flex" }}>
                        <Typography className={classes.heading}>
                          <Button
                            style={{ marginRight: "5px" }}
                            className={classes.compoundBtn}
                            onClick={() => CompoundDivident(i)}
                            disabled={
                              Number(datamap?.pendingDividends) === 0
                                ? true
                                : false ||
                                  loader ||
                                  harvestLoader ||
                                  defaultLoader
                            }
                          >
                            Compound
                          </Button>
                          &nbsp;
                        </Typography>
                        <Typography className={classes.heading}>
                          <Button
                            onClick={() => harvestclaimDividend(i)}
                            className={classes.compoundBtn}
                            disabled={
                              Number(datamap?.pendingDividends) === 0
                                ? true
                                : false ||
                                  loader ||
                                  harvestLoader ||
                                  defaultLoader
                            }
                          >
                            Harvest
                          </Button>
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} lg={6} sm={6} md={6} align="left">
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} lg={6} sm={6} md={6}>
                      <Typography className={classes.heading}>
                        {" "}
                        <small style={{ color: "#31B0E3" }}>
                          {" "}
                          {datamap?.earnTokensymbol}
                        </small>{" "}
                        EARNED{" "}
                      </Typography>
                      {account ? (
                        <Typography
                          variant="body1"
                          style={{ fontSize: "16px", color: "#31B0E3" }}
                        >
                          {" "}
                          {Number(datamap?.pendingReward) === 0
                            ? 0.0
                            : convertToInternationalCurrencySystem(
                                parseFloat(datamap?.pendingReward).toFixed(8)
                              )}{" "}
                        </Typography>
                      ) : (
                        <Typography
                          variant="body1"
                          style={{ fontSize: "16px", color: "#31B0E3" }}
                        >
                          0
                        </Typography>
                      )}
                    </Grid>

                    <Grid item xs={12} lg={6} sm={6} md={6}>
                      <Box style={{ display: "flex" }}>
                        <Typography className={classes.heading}>
                          <Button
                            style={{ marginRight: "5px" }}
                            className={classes.compoundBtn}
                            onClick={() => CompoundEarned(i)}
                            disabled={
                              Number(datamap?.pendingReward) === 0
                                ? true
                                : false ||
                                  loader ||
                                  harvestLoader ||
                                  defaultLoader
                            }
                          >
                            Compound
                          </Button>
                          &nbsp;
                        </Typography>
                        <Typography className={classes.heading}>
                          <Button
                            onClick={() => StakingHarvest(i)}
                            className={classes.compoundBtn}
                            disabled={
                              Number(datamap?.pendingReward) === 0
                                ? true
                                : false ||
                                  loader ||
                                  harvestLoader ||
                                  defaultLoader
                            }
                          >
                            Harvest
                          </Button>
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                {/* <Grid item xs={12} lg={6} sm={6} md={6} with>
                  <Grid container spacing="2" >
                    <Grid lg={6}>
                      <Typography className={classes.heading}>
                        {" "}
                        <small style={{ color: "#31B0E3" }}>
                          {" "}
                          {datamap?.dividendTokensymbol}
                        </small>{" "}
                        REFLECTED{" "}
                      </Typography>
                      {account ? (
                        <Typography
                          variant="body1"
                          style={{ fontSize: "16px", color: "#31B0E3" }}
                        >
                          {" "}
                          {Number(datamap?.pendingDividends) === 0
                            ? 0.0
                            : convertToInternationalCurrencySystem(
                                parseFloat(datamap?.pendingDividends).toFixed(8)
                              )}
                        </Typography>
                      ) : (
                        <Typography
                          variant="body1"
                          style={{ fontSize: "16px", color: "#31B0E3" }}
                        >
                          0
                        </Typography>
                      )}
                    </Grid>
                    <Grid lg={6} textAlign="left">
                      <Box style={{ display: "flex", textAlign: "end" }}>
                        <Typography className={classes.heading}>
                          <Button
                            style={{ marginRight: "5px" }}
                            className={classes.compoundBtn}
                            onClick={() => CompoundDivident(i)}
                            disabled={
                              Number(datamap?.pendingDividends) === 0
                                ? true
                                : false ||
                                  loader ||
                                  harvestLoader ||
                                  defaultLoader
                            }
                          >
                            Compound
                          </Button>
                        </Typography>
                        <Typography className={classes.heading}>
                          <Button
                            className={classes.compoundBtn}
                            onClick={() => harvestclaimDividend(i)}
                            disabled={
                              Number(datamap?.pendingDividends) === 0
                                ? true
                                : false ||
                                  loader ||
                                  harvestLoader ||
                                  defaultLoader
                            }
                          >
                            Harvest
                          </Button>
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid> */}
              </Grid>
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>

      {openWalletConnect && (
        <ConnectWallet
          open={openWalletConnect}
          handleClose={() => setOpenWalletConnect(false)}
        />
      )}

      <Dialog
        open={open}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
        disableScrollLock={true}
        className={classes.modalContant}
      >
        <DialogTitle id="alert-dialog-title">
          <span style={{ color: "#fff", fontSize: "14px" }}>
            {" "}
            {`Enter ${openId} Amount?`}
          </span>
        </DialogTitle>
        <DialogContent>
          <Box className="textFieldB">
            <Typography
              variant="h6"
              align="right"
              style={{ color: "#31b0e3", fontSize: " 16px" }}
            >
              {openId === "Widthdraw" ? (
                <>
                  <span style={{ color: "#fff", fontSize: " 16px" }}>
                    {parseFloat(
                      web3.utils.fromWei(datamap?.userInfo.amount?.toString())
                    ).toFixed(2)}{" "}
                  </span>{" "}
                  <span
                    style={{ textTransform: "capitalize", fontSize: " 14pxpx" }}
                  >
                    {" "}
                    {datamap?.symbolStakeToken}{" "}
                  </span>{" "}
                  <span style={{ color: "#fff", fontSize: " 14pxpx" }}>
                    {" "}
                    Available
                  </span>{" "}
                </>
              ) : (
                <>
                  {accountTrue && (
                    <>
                      <span style={{ color: "#fff", fontSize: " 16px" }}>
                        {datamap?.balanceOf ? datamap?.balanceOf : 0}{" "}
                      </span>{" "}
                      <span
                        style={{
                          textTransform: "capitalize",
                          fontSize: " 14pxpx",
                        }}
                      >
                        {datamap?.symbolStakeToken}{" "}
                      </span>
                      <span style={{ color: "#fff", fontSize: " 14pxpx" }}>
                        {" "}
                        Available
                      </span>{" "}
                    </>
                  )}
                </>
              )}
            </Typography>
            <TextField
              disabled={buttonLoader}
              onKeyPress={(event) => {
                if (event?.key === "-" || event?.key === "+") {
                  event.preventDefault();
                }
              }}
              variant="outlined"
              onChange={(e) => {
                if (e.target.value && e.target.value != "-") {
                  setAmount(Math.abs(Number(e.target.value)));
                } else {
                  e.preventDefault();
                }
              }}
              fullWidth
              value={amount}
              // error={errorText && datamap?.balanceOf < amount or}
              placeholder="0"
              type="number"
              style={{ color: "#fff" }}
              // InputProps={{
              //   endAdornment: (
              //     <InputAdornment position="end">
              //       {openId === "Widthdraw" ? (
              //         <Button
              //           disabled={buttonLoader}
              //           style={{
              //             backgroundColor: "#676666",
              //             padding: 0,
              //             color: "#d6d1d1",
              //           }}
              //           // onClick={() => setAmount(stakeAmount)}
              //         >
              //           MAX
              //         </Button>
              //       ) : (
              //         <Button
              //           disabled={buttonLoader}
              //           style={{
              //             backgroundColor: "#676666",
              //             padding: 0,
              //             color: "#d6d1d1",
              //           }}
              //           // onClick={() =>
              //           //   setAmount(web3.utils.fromWei(balanceOf.toString()))
              //           // }
              //         >
              //           MAX
              //         </Button>
              //       )}
              //     </InputAdornment>
              //   ),
              // }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            style={{ color: "#ccc" }}
            disabled={buttonLoader}
          >
            Cancel
          </Button>
          {openId !== "Widthdraw" ? (
            <Button
              onClick={() => getStakingDataHandler(i, amount)}
              autoFocus
              style={{ color: "#31b0e3" }}
              disabled={buttonLoader}
              className={classes.compoundBtn}
            >
              {/* Confirm {buttonLoader && <CircularProgress />} */}
              <span
                style={{
                  padding: "0 13px",
                }}
              >
                Confirm
              </span>

              {buttonLoader && (
                <CircularProgress
                  style={{
                    width: "32px",
                    height: "32px",
                    color: "#31b0e3",
                  }}
                />
              )}
            </Button>
          ) : (
            <Button
              onClick={() => getUnStakingDataHandler(i, amount)}
              color="primary"
              style={{ color: "#31b0e3" }}
              autoFocus
              disabled={buttonLoader}
              className={classes.compoundBtn}
            >
              <span
                style={{
                  padding: "0 13px",
                }}
              >
                Confirm
              </span>

              {buttonLoader && (
                <CircularProgress
                  style={{
                    width: "32px",
                    height: "32px",
                    color: "#31b0e3",
                  }}
                />
              )}
            </Button>
          )}
        </DialogActions>
      </Dialog>

      {datamap?.tokenAddress && (
        <Dialog
          disableScrollLock={true}
          open={roiCalculator}
          onClose={() => setRoiCalculator(false)}
          className={classes.RoiCalculator}
        >
          <DialogContent>
            <RoiCalculator
              rewardTokenPrice={0.004}
              stakeTokenPrice={0.001}
              RoiCalculator={() => HandleRoiCalculator()}
              balanceOf={45}
              stakeName={datamap?.tokenAddress?.name}
              APY={2}
              APYpercentage={15}
              earningTokenSymbol={datamap?.rewardAddress?.name}
            />
          </DialogContent>
        </Dialog>
      )}
    </Box>
  );
};

export default StakingCards;
