import React from "react";
import {
  Box,
  makeStyles,
  Typography,
  Grid,
  Button,
  Container,
  TextField,
  Tab,
} from "@material-ui/core";
import Tabbar1 from "./Tabbar1";
import { useWeb3React } from "@web3-react/core";
import { Link, useHistory } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import { green } from "@material-ui/core/colors";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import axios from "axios";
import apiConfig from "../../config/ApiConfig";
import { UserContext } from "../../context/User";

const useStyles = makeStyles((theme) => ({
  box1: {
    height: "80vh",
    " & h1": {
      fontFamily: "'Lexend Deca', sans-serif",
      // fontFamily: "'Lexend Deca'",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "45px",
      lineHeight: "55px",
      textTransform: "capitalize",
      color: "#0E2137",
      textAlign: "center",
    },
    "& h2": {
      fontSize: "15px",
      fontFamily: "'Lexend Deca'",
      fontWeight: "500",
      lineHeight: "1.6",
      letterSpacing: "0.0075em",
      //   textAlign: "center",
    },
  },
  box2: {
    marginTop: "40px",
    "& h3": {
      fontSize: "15px",
      fontFamily: "'Lexend Deca'",
      fontWeight: "500",
      lineHeight: "1.6",
      letterSpacing: "0.0075em",
      //   textAlign: "center",
    },
  },
  btn: {
    color: "white",
    background: "#0E2137",
    borderRadius: "10px",
    borderRadius: "10px",
    padding: "15px",
    paddingLeft: "20px",
    paddingRigth: "20px",
    "&:hover": {
      background: "#3cb4e4",
    },
  },
}));

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);
const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);
export default function Maindashboard(props) {
  var classes = useStyles();
  const user = React.useContext(UserContext);
  const history = useHistory();
  const [age, setAge] = React.useState("");
  const [month, setMonth] = React.useState("");

  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
    checkedF: true,
    checkedG: true,
    checkedH: true,
    checkedI: true,
    checkedJ: true,
  });
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const handleChange1 = (event) => {
    setMonth(event.target.value);
  };
  const handleChange2 = (event) => {
    setMonth(event.target.value);
  };
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleChangecheck = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const { activate, deactivate, account, library, chainId } = useWeb3React();
  const [satelliteBurn, setsatelliteBurn] = React.useState("");
  const [commisionStacking, setcommisionStacking] = React.useState("");
  const [satelliteStacking, setsatelliteStacking] = React.useState("");
  const [controlData, setControlData] = React.useState("");
  // const user = React.useContext(UserContext);
  console.log("------------");
  const SetCommition = async () => {
    try {
      const res = await axios({
        method: "POST",
        url: apiConfig.setCommision,
        headers: {
          token: sessionStorage.getItem("token"),
        },
        data: {
          commisionId: controlData[0]?._id,
          commisionStacking: commisionStacking,
          satelliteStacking: satelliteStacking,
          satelliteBurn: satelliteBurn,
        },
      });
      // console.log("res", res);
      if (res.data.statusCode === 200) {
        // setDashboardData(res.data.result);
        // setStakingList(res.data.result);
        console.log(res);
      }
    } catch (error) {
      console.log("EROR", error);
    }
  };
  console.log("sessionStorage.getItem(", sessionStorage.getItem("token"));
  React.useEffect(() => {
    if (
      user.isLogin
      // && user.userDetails.userType === "Admin"
    ) {
      GetCommition();
    }
  }, [account, user.isLogin]);

  React.useEffect(() => {
    if (user.isLogin) {
      if (user.userDetails.userType !== "Admin") {
        history.push("/");
      }
    }
  }, [account, user.isLogin]);
  const GetCommition = async () => {
    try {
      const res = await axios({
        method: "GET",
        url: apiConfig.getCommision,
        headers: {
          token: sessionStorage.getItem("token"),
        },
      });
      console.log("res", res);
      if (res.data.statusCode === 200) {
        console.log(res);
        setControlData(res.data.result);
      }
    } catch (error) {
      console.log("EROR", error);
    }
  };

  return (
    <Box className={classes.mainbox}>
      <Container>
        <Box className={classes.box1}>
          <Typography variant="h1">Control</Typography>
          <Box>
            <Typography variant="h2">Set cummison for Staking</Typography>
            <Box className={`${classes.box30} textFieldB`}>
              <TextField
                fullWidth
                id="outlined-basic"
                type="number"
                placeholder="Enter cummison for Staking"
                // label="Outlined"
                variant="outlined"
                autoComplete="off"
                // value="0.00765"
                onKeyPress={(event) => {
                  if (event?.key === "-" || event?.key === "+") {
                    event.preventDefault();
                  }
                }}
                onChange={(e) => {
                  if (e.target.value && e.target.value != "-") {
                    setcommisionStacking(Math.abs(Number(e.target.value)));
                  } else {
                    e.preventDefault();
                  }
                }}
                // onChange={(e) => setcommisionStacking(e.target.value)}
              />
            </Box>
          </Box>
          <Box className={classes.box2}>
            <Typography variant="h3">Set minimum satellite staking</Typography>
            <Box className={`${classes.box30} textFieldB`}>
              <TextField
                fullWidth
                id="outlined-basic"
                type="number"
                placeholder="Enter minimum satellite staking"
                // label="Outlined"
                variant="outlined"
                autoComplete="off"
                onKeyPress={(event) => {
                  if (event?.key === "-" || event?.key === "+") {
                    event.preventDefault();
                  }
                }}
                onChange={(e) => {
                  if (e.target.value && e.target.value != "-") {
                    setsatelliteStacking(Math.abs(Number(e.target.value)));
                  } else {
                    e.preventDefault();
                  }
                }}
                // onChange={(e) => setcommisionStacking(e.target.value)}
                // value="0.00765"
              />
            </Box>
          </Box>
          <Box className={classes.box2}>
            <Typography variant="h3">Set minimum satellite Burn</Typography>
            <Box className={`${classes.box30} textFieldB`}>
              <TextField
                fullWidth
                id="outlined-basic"
                type="number"
                placeholder="Enter minimum satellite Burn"
                // autoComplete="nope"
                autoComplete="off"
                // label="Outlined"
                variant="outlined"
                // value={controlData}
                onKeyPress={(event) => {
                  if (event?.key === "-" || event?.key === "+") {
                    event.preventDefault();
                  }
                }}
                onChange={(e) => {
                  if (e.target.value && e.target.value != "-") {
                    setsatelliteBurn(Math.abs(Number(e.target.value)));
                  } else {
                    setsatelliteBurn();
                  }
                }}
                // onChange={(e) => setcommisionStacking(e.target.value)}
                // value="0.00765"
              />
            </Box>
          </Box>
          <Box mt={3} style={{ marginTop: "30px" }}>
            <Button
              className={classes.btn}
              style={{ color: "white" }}
              onClick={SetCommition}
            >
              {" "}
              Submit
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
