import React from "react";
import {
  makeStyles,
  Typography,
  Box,
  // Button,
  // Container,
  Grid,
} from "@material-ui/core";
// import TextField from "@material-ui/core/TextField";
import DoneIcon from "@material-ui/icons/Done";
import Index from "./Tab/Index";
const useStyles = makeStyles((theme) => ({
  textbox: {
    mint: {
      fontSize: "14px ",
      border: "1px solid #E8424C",
      background: "#E8424C",
      fontWeight: 600,
      height: "44px ",
      color: "#FFFFFF",
      minWidth: "150px ",
      borderRadius: "50px",
      boxShadow: "none ",
      cursor: "pointer",
      [theme.breakpoints.down("xs")]: {
        height: "45px ",
        minWidth: "120px ",
      },
      "&:hover": {
        borderColor: "#E8424C",
        background: "#E8424C",
      },
    },
    "& h1": {
      fontSize: "25px",
      fontWeight: "bold",
      lineHeight: "55px",
      color: "#FABE25",
    },
    "& h2": {
      fontSize: "45px",
      fontWeight: "bold",
      lineHeight: "55px",
      color: "#FABE25",
      [theme.breakpoints.down("xs")]: {
        fontSize: "30px",
      },
    },
    "& h5": {
      fontSize: "30px",
      fontWeight: "500",
      color: "#fff",
      marginBottom: "10px",
      marginTop: "15px",
    },
    "& h6": {
      color: "#9F9F9F",
      marginBottom: "10px",
    },
    "& p": {
      fontSize: "14px",
      color: "#9F9F9F",
      width: "100%",
      // maxWidth: "600px",
    },
    "& label": {
      fontSize: "16px",
      color: "#fff",
      // maxWidth: "600px",
    },
    "& div": {
      "& button": {
        "&:last-child": {
          marginLeft: "20px",
        },
      },
    },
  },
  mainBox: {
    padding: "20px 20px 50px",
    overflow: "hidden",
    position: "relative",
    background:
      "linear-gradient( 152.97deg, rgba(255, 255, 255, 0.08) 0%, rgba(232, 66, 76, 0.062) 100%)",
    transition: "0.5s",
    borderRadius: "0 30px 0 0",
    backdropFilter: "blur(42px)",

    "& h1": {
      fontSize: "25px",
      fontWeight: "bold",
      lineHeight: "55px",
      color: "#FABE25",
      [theme.breakpoints.down("xs")]: {
        fontSize: "14px",
      },
    },
    "& p": {
      fontSize: "14px",
      color: "#9F9F9F",
      width: "100%",
      // maxWidth: "600px",
    },
    "& small": {
      fontSize: "12px",
      color: "#6c757d!important",
      // maxWidth: "600px",
    },
    "& label": {
      color: "#9F9F9F",
      padding: "0",
      fontSize: "14px",
      lineHeight: "30px",
      transition:
        "opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    },
  },
  root: {},
}));

function Launchpad() {
  const classes = useStyles();
  return (
    <Box className={classes.bannerBox}>
      <Box className={classes.textbox} mt={5} mb={5} align="center">
        <Typography variant="h2">Token Creator</Typography>
      </Box>

      <Box className={classes.mainBox} mt={5}>
        <Box mt={5}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <div className={classes.textbox}>
                <Typography variant="h1"> Token Creator</Typography>
                <Typography variant="h6">
                  {" "}
                  Select network to mint your token on Binance
                </Typography>
                <Typography variant="body2">
                  Tokens are fully BEP20 compliant Non-Mintable Tokens. Use the
                  Unicrypt Token factory to mint your very own token at the
                  click of a button. Your token will be shown favourably in the
                  Unicrypt browser and bypass the need for an audit on the token
                  contract itself, as well as when using our ILO dapp. Anyone
                  can query our token mint factory with your token address to
                  see your new token is a valid, safe, and BEP20 compliant
                  token.
                </Typography>
                <Typography variant="h1">Token specs</Typography>
                <ul class="tokenSpecsList">
                  <li>
                    <DoneIcon />
                    No mint function
                  </li>
                  <li>
                    <DoneIcon />
                    No owner / admin functions
                  </li>
                  <li>
                    <DoneIcon />
                    No unsafe code in the token contract itself
                  </li>
                  <li>
                    <DoneIcon />
                    Fully BEP20 compliant
                  </li>
                  <li>
                    {" "}
                    <DoneIcon />
                    Fully decentralised
                  </li>
                </ul>
              </div>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Index />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}

export default Launchpad;
