import React from "react";
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  makeStyles,
  Grid,
  withStyles,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
const useStyles = makeStyles((theme) => ({
  StakeBox: {
    borderRadius: "10px",
    overflow: "hidden",
    position: "relative",
    background:
      "linear-gradient( 152.97deg, rgba(255, 255, 255, 0.08) 0%, rgba(232, 66, 76, 0.062) 100%)",
    transition: "0.5s",
    backdropFilter: "blur(42px)",
    padding: "20px 15px",

    "& h6": {
      color: "#FABE25",
    },
    "& label": {
      marginBottom: "5px",
      display: "block",
      color: "#9F9F9F",
      padding: "0",
      lineHeight: "33px",
      fontSize: "14px",
      transition:
        "opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    },

    // '& span': {

    //   color: "#9F9F9F",
    // padding: "0",
    // lineHeight: "10px",
    // fontSize: "12px",
    // transition: "opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    // },

    "& h1": {
      fontSize: "50px",
      fontWeight: "600",
      lineHeight: "67px",
      letterSpacing: "3px",
      display: "inline-block",
      color: "#fff",
      [theme.breakpoints.down("lg")]: {
        fontSize: "46px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "30px",
        lineHeight: "40px",
      },
    },

    "&:hover": {
      "& .wallet_box": {
        opacity: "1",
        top: "30%",
        right: "-60px",
      },
      "& .wallet_box:first-child": {
        opacity: "1",
        top: "30%",
        right: "-60px",
      },
    },
  },
  box: {
    height: "150px",
    width: "150px",
    borderRadius: "50%",
    background: "#f6a52d4d",
    position: "absolute",
    top: "100%",
    right: "-150px",
    transition: "0.5s all",
  },

  textbox: {
    "& h1": {
      fontSize: "45px",
      fontWeight: "bold",
      lineHeight: "76px",
      color: "#FABE25",
      [theme.breakpoints.down("xs")]: {
        fontSize: "30px",
      },
    },
    "& p": {
      fontSize: "18px",
      color: "#fff",
    },
  },

  tableBox: {
    padding: "50px 0 30px",
  },
  bottomtext: {
    color: "#9F9F9F",
    display: "block",
    padding: "0",
    fontSize: "12px",
    transition:
      "opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    /* line-height: 33px; */
    marginBottom: "5px",
    marginTop: "8px",
  },
}));

function CreateStake(props) {
  const classes = useStyles();

  return (
    <Box mt={5}>
      <Box className={classes.textbox} align="center">
        <Typography variant="h1"> Create Stake</Typography>
      </Box>
      <Container maxWidth="lg" align="left">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Box mt={2} className={classes.StakeBox}>
              <label className={classes.labeltext} for="fname">
                Token Users will Stake
              </label>

              <TextField
                id="outlined-basic"
                variant="outlined"
                placeholder=""
                fullWidth
              />
              <Typography variant="caption" className={classes.bottomtext}>
                The token users can stake to earn rewards from the rewards pool
                you've provided.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box mt={2} className={classes.StakeBox}>
              <label className={classes.labeltext} for="fname">
                Reward Currency{" "}
              </label>

              <TextField
                id="outlined-basic"
                variant="outlined"
                placeholder=""
                fullWidth
              />
              <Typography variant="caption" className={classes.bottomtext}>
                The token you will send the staking contract for users to earn
                for staking
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Box mt={2} className={classes.StakeBox}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Box mt={2} className="mainBox">
                    <label className={classes.labeltext} for="fname">
                      Rewards Supply (# tokens)
                    </label>

                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      placeholder=""
                      fullWidth
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box mt={2} className="mainBox">
                    <label className={classes.labeltext} for="fname">
                      When should pool expire?
                    </label>

                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      placeholder=""
                      fullWidth
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box mt={2} className="mainBox">
                    <label className={classes.labeltext} for="fname">
                      Rewards per block:
                    </label>

                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      placeholder=""
                      fullWidth
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box mt={2} className="mainBox">
                    <label className={classes.labeltext} for="fname">
                      Staker timelock (in days)
                    </label>

                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      placeholder=""
                      fullWidth
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box mt={2} className="mainBox">
                    <Button variant="contained" color="primary">
                      Create Stake Pool
                    </Button>
                  </Box>
                  <Box mt={1} align="left">
                    <Typography
                      variant="caption"
                      className={classes.bottomtext}
                    >
                      Create Stake Fee: 1BNB
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default CreateStake;
