import React from "react";
import {
  Box,
  makeStyles,
  Typography,
  Grid,
  Button,
  Container,
} from "@material-ui/core";
import { BsArrowRight } from "react-icons/bs";
import Tokenomics from "../../pages/dashboard/Tokenomics";
import PieChart from "../../pages/dashboard/PieChart";
import Recent from "../../pages/dashboard/Recent";
import NewFooter1 from "../../pages/dashboard/NewFooter1";
const useStyles = makeStyles((theme) => ({
  mainBox1: {
    paddingBottom: "80px",

    background:
      "radial-gradient(48.06% 27.42% at 84.2% 48.18%, rgba(32, 104, 188, 0.45) 0%, rgb(19 99 193 / 0%) 100%) , radial-gradient(39.41% 25.47% at 12.19% 49%, rgb(10 129 177 / 45%) 0%, rgba(221, 237, 254, 0.45) 100%) , #FFFFFF",
    marginTop: "-60px",
  },
  mainBox: {
    marginTop: "50px",
    color: "#0E2137",
    "& h1": {
      fontFamily: "'Lexend Deca'",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "65px",
      lineHeight: "55px",
      color: "#0E2137",
      textAlign: "center",
    },
  },
  box1: {
    marginTop: "2rem",
    marginBottom: "3rem",
    display: "flex",
    justifyContent: "center",

    "& h4": {
      textAlign: "center",
      fontFamily: "'Lexend Deca'",
      fontStyle: "normal",
      fontWeight: "00",
      fontSize: "16px",
      lineHeight: "2rem",
      maxWidth: "56rem",
      color: "  #626262",
    },
  },

  box12: {
    marginTop: "-40px",
    // paddingBottom: "50px",
    // "@media(max-width:910px)": {
    //   marginTop: "4rem",
    // },
    "@media(max-width:960px)": {
      marginTop: "5rem",
    },
    "@media(max-width:670px)": {
      marginTop: "5rem",
    },
    "@media(max-width:375px)": {
      marginTop: "10rem",
      // fontSize: "30px",
    },
    "& h1": {
      textAlign: "center",
      fontFamily: "'Lexend Deca'",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "65px",
      color: "#0E2137",
      lineHeight: "55px",
      "@media(max-width:415px)": {
        marginTop: "10rem",
        fontSize: "40px",
      },
      "@media(max-width:375px)": {
        fontSize: "35px",
      },
    },
  },
  box2: {
    padding: "20px",
    // border: "1px solid black",
    // height: "10rem",
    width: "100%",
    background: "white",
    position: "relative",
    // margin: "0 15px",
    transition: "0.3s",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "20px",
    "& h2": {
      marginTop: "10px",
      fontFamily: "'Lexend Deca'",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "30px",
      lineHeight: "45px",
      color: "#3CB4E4",
    },
    "& h4": {
      fontWeight: "500",
      fontSize: "15px",
      lineHeight: "24px",
      color: " #626262",
      marginTop: "3px",
    },
    "&:hover": {
      background: "#0E2137",
      //
      "& .img2": {
        position: "absolute",
        bottom: 0,
        right: 0,
      },
      "& h4": {
        color: "white",
      },
      "& h2": { color: "white" },
    },
    "& .img2": {
      position: "absolute",
      bottom: "-135px",
      right: "-135px",
      transition: "0.3s",
    },
  },
  box3: {
    textAlign: "center",
    width: "77px",
    height: "77px",
    left: "189px",
    top: "1021px",
    background: "#3CB4E4",
    borderRadius: "10px",
  },
  link1: {
    marginTop: "20px",
    display: "flex",
    justifyContent: "end",
  },
  GroupBox: {
    display: "flex",
    justifyContent: "end",
  },
  mainBox0: {
    marginTop: "-19rem",
  },
}));

const Services = () => {
  const classes = useStyles();
  return (
    <div className={classes.mainBox1}>
      <Box className={classes.GroupBox}>
        {" "}
        <img
          style={{ position: "relative", marginTop: "-35px" }}
          src="./images/Group.svg"
        ></img>
      </Box>

      <Container>
        <Box className={classes.mainBox0}>
          <Box className={classes.mainBox}>
            <Typography variant="h1">Services</Typography>
          </Box>
          <Box className={classes.box1}>
            <Typography variant="h4">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book.{" "}
            </Typography>
          </Box>
          <Grid container spacing={3}>
            <Grid item lg={4} md-={4} sm={12} xs={12}>
              {" "}
              <Box>
                <Box className={classes.box2}>
                  <img className="img2" src="./images/imgdown.png"></img>
                  <Box className={classes.box3}>
                    {" "}
                    <img
                      className={classes.img1}
                      style={{ marginTop: "20px" }}
                      src="images/(x).png"
                    />
                  </Box>
                  <Typography variant="h2">Auditing</Typography>
                  <Typography variant="h4">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book.{" "}
                  </Typography>
                  {/* <Box className={classes.link1}>
                    <a
                      href="ContactUs"
                      style={{ color: "#3CB4E4", textDecoration: "none" }}
                    >
                   Contact Us 
                    </a>
                    &nbsp; &nbsp;
                    <img src="images/arrow.png"></img>
                  </Box> */}
                </Box>
              </Box>
            </Grid>
            <Grid item lg={4} md-={4} sm={12} xs={12}>
              <Box>
                <Box className={classes.box2}>
                  <Box className={classes.box3}>
                    {" "}
                    <img
                      className={classes.img1}
                      style={{ marginTop: "20px" }}
                      src="images/crown.png"
                    />
                    <img
                      className={classes.img1}
                      style={{ marginBottom: "10px" }}
                      src="images/crown1.png"
                    />
                  </Box>
                  <Typography variant="h2">Engineering</Typography>
                  <Typography variant="h4">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book.{" "}
                  </Typography>
                  {/* <Box className={classes.link1}>
                    <a
                      href="ContactUs"
                      style={{
                        color: "#3CB4E4",
                        textDecoration: "none",
                        fontWeight: "500",
                      }}
                    >
                     Contact Us 
                    </a>
                    &nbsp; &nbsp;
                    <img src="images/arrow.png"></img>
                  </Box> */}
                </Box>
              </Box>
            </Grid>
            <Grid item lg={4} md-={4} sm={12} xs={12}>
              <Box>
                <Box className={classes.box2}>
                  <Box className={classes.box3}>
                    {" "}
                    <img
                      className={classes.img1}
                      style={{ marginTop: "20px" }}
                      src="images/Key.png"
                    />
                  </Box>
                  <Typography variant="h2">Token Bridging</Typography>
                  <Typography variant="h4">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book.{" "}
                  </Typography>
                  {/* <Box className={classes.link1}>
                    <a
                      href="ContactUs"
                      style={{ color: "#3CB4E4", textDecoration: "none" }}
                    >
                     Contact Us 
                    </a>
                    &nbsp; &nbsp;
                    <img src="images/arrow.png"></img>
                  </Box> */}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Tokenomics />
      {/* <PieChart /> */}
      {/* <Box>
        <Container>
          <Box className={classes.box12}>
            <Typography variant="h1">Recent Staking pools</Typography>
            <Box style={{ width: "100%" }} className={classes.box0}>
              {" "}
              <Typography variant="h5">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </Typography>
            </Box> */}
      <Recent home={true} status={"ACTIVE"} ownerCheck={false} />
      {/* </Box>
        </Container>
      </Box> */}
      {/* <NewFooter1 /> */}
    </div>
  );
};

export default Services;
