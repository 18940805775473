import React from 'react'
import {
  Grid,
  Box,
  Container,
  Typography,
  makeStyles,
  Button,
  withStyles
} from '@material-ui/core'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import Paper from '@material-ui/core/Paper';
import { CircularProgressbar } from 'react-circular-progressbar';
import TextField from '@material-ui/core/TextField';
import 'react-circular-progressbar/dist/styles.css';
const useStyles = makeStyles((theme) => ({
  textbox: {
    mint: {
      fontSize: "14px ",
      border: "1px solid #E8424C",
      background: "#E8424C",
      fontWeight: 600,
      height: "44px ",
      color: "#FFFFFF",
      minWidth: "150px ",
      borderRadius: "50px",
      boxShadow: "none ",
      cursor: "pointer",
      [theme.breakpoints.down("xs")]: {
        height: "45px ",
        minWidth: "120px ",
      },
      "&:hover": {
        borderColor: "#E8424C",
        background: "#E8424C",
      },
    },
    "& h1": {
      fontSize: "25px",
      fontWeight: "bold",
      lineHeight: "55px",
      color: "#FABE25",
    },
    "& h2": {
      fontSize: "45px",
      fontWeight: "bold",
      lineHeight: "55px",
      color: "#FABE25",
      "@media (max-width: 767px)": {
        fontSize: "30px",

      },
    },
    "& h3": {
      fontSize: "35px",
      fontWeight: "bold",
      lineHeight: "55px",
      color: "#fff",
      "@media (max-width: 767px)": {
        fontSize: "23px",
        lineHeight: "30px",

      },
    },
    "& h5": {
      fontSize: "30px",
      fontWeight: "500",
      color: "#fff",
      marginBottom: "10px",
      marginTop: "15px",
    },
    "& h6": {
      color: "#9F9F9F",
      marginBottom: "10px",
    },
    "& p": {
      fontSize: "14px",
      color: "#9F9F9F",
      width: "100%",
      // maxWidth: "600px",
    },
    "& label": {
      fontSize: "16px",
      color: "#fff",
      // maxWidth: "600px",
    },
    "& div": {
      "& button": {
        "&:last-child": {
        //   marginLeft: "20px",
        },
      },
    },
  },

   inputBox: {
    width: "100%",
    height:"45px",
    background:"#090c16",
    border: "1px solid #8a8b8b",
    color:"#fff",
    padding:"7px",
  
},

  inputBox: {
    width: "100%",
    height:"50px",
    background:"#090c16",
    border: "1px solid #8a8b8b",
    borderRadius:"5px",
    color:"#fff",
    padding:"7px",
    marginRight: "14px",

    "&:focus-visible": {
      outline: "none",
    },


    "@media (max-width: 767px)": {
        width: "100%",
        
      },
},


CircularProgressbar: {
    height: "100px",
    width: "100px",
    stroke: "#fff",
    position: "relative",

    "& .CircularProgressbar-path": {
        stroke: "#f6a52d",
        strokeLinecap: "round",
        transition: "stroke-dashoffset 0.5s ease 0s",
      },

      "&.CircularProgressbar-text": {
        fill: "#ffffff",
        fontSize: "20px",
        dominantBaseline: "middle",
        textAnchor: "middle",
      },

    "& svg": {
      display: "flex",
      alignItems: "center",
      justyfycontent: "center",
    },
    "& .CircularProgressbar-text": {
      alignItems: "center",
      position: "absolute",
      transform: "translate(0% , 4%)",
      fill: "#ffffff",
    },
  },
  textBox: {
    fontSize: "15px",
    color: "#fff",
    fontWeight: "bold",
  },
  textBox1: {
    fontSize: "15px",
    color: "#fff",
  },
  textBox2: {
    fontSize: "15px",
    color: "#fff",
    marginTop:"20px",
  },
  tableBox:{
    padding: "90px 0 30px",
    [theme.breakpoints.down("xs")]: {
      padding: "50px 0 30px",
    },

  },
  CircularButton: {
    alignItems:"center",
  },
  searchBox: {
    alignItems:"center",

  },


}))

const StyledTableCell = withStyles((theme) => ({
    head: {
      fontSize:"16px",
    fontWeight:"bold",
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 15,
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: "rgb(25 22 32)",
      },
    },
  }))(TableRow);

export default function BestSeller(props) {
    const [progress, setProgress] = React.useState(10);

    React.useEffect(() => {
      const timer = setInterval(() => {
        setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
      }, 800);
      return () => {
        clearInterval(timer);
      };
    }, []);
  
  const classes = useStyles()
   function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  const rows = [
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
  ];

  const percentage = 66;
  return (
    <Box className={classes.bannerBox}>
      <Container maxWidth="lg" align="center">
        <Box className={classes.textbox} mt={5} mb={5}>
          <Typography variant="h2">Contract Sniffer</Typography>
        </Box>
         
         <Box mt={5} >
         <Grid container spacing={3} alignItems="center">

          <Grid item xs={12} sm={8} md={7} lg={5} >
          <Box mt={2} mb={3} className={classes.searchBox}>
          <input type="text" placeholder="Search Address" fullwidth className={classes.inputBox} />               
        </Box>
          </Grid>
          <Grid item xs={12} sm={4} md={5} lg={3} align="left">
          <Button variant="contained" color="secondary" className={classes.sniffe_button}>Sniffe-it</Button>
          </Grid>
        </Grid>
         </Box>
   
         <Box mt={3} mb={5}>
          <Grid container spacing={3}>
             <Grid item xs={12} sm={6} align="left">    
                   <Grid container spacing={2} alignItems="center">
                    <Grid item xs={6}>
                    <Typography variant="h5" className={classes.textBox}>Ownership Renouncement</Typography>
                    </Grid>
                    <Grid item xs={6}>
                    <Typography variant="h6" className={classes.textBox1}>Not Renouncement</Typography> 
                    </Grid>
                    <Grid item xs={6}>
                    <Typography variant="h5" className={classes.textBox}>Max Total Supply</Typography>
                    </Grid>
                    <Grid item xs={6}>
                    <Typography variant="h6" className={classes.textBox1}>0</Typography>
                    </Grid>
                    <Grid item xs={6}>
                    <Typography variant="h5" className={classes.textBox}>Holders</Typography>
                    </Grid>
                    <Grid item xs={6}>
                    <Typography variant="h6" className={classes.textBox}>0 Holders</Typography>
                    </Grid>
                    <Grid item xs={6}>
                    <Typography variant="h5" className={classes.textBox}>High issue</Typography>
                    </Grid>
                    <Grid item xs={6}>
                    <Typography variant="h6" className={classes.textBox1}>3</Typography>
                    </Grid>
                    <Grid item xs={6}>
                    <Typography variant="h5" className={classes.textBox}>High issue</Typography>
                    </Grid>
                    <Grid item xs={6}>
                    <Typography variant="h6" className={classes.textBox1}>3</Typography>
                    </Grid>
                    <Grid item xs={6}>
                    <Typography variant="h5" className={classes.textBox}>Medium issue</Typography>
                    </Grid>
                    <Grid item xs={6}>
                    <Typography variant="h6" className={classes.textBox1}>9</Typography>
                    </Grid>
                    <Grid item xs={6}>
                    <Typography variant="h5" className={classes.textBox}>Low issue</Typography>
                    </Grid>
                    <Grid item xs={6}>
                    <Typography variant="h6" className={classes.textBox1}>50</Typography>
                    </Grid>
                    
                 </Grid>
            </Grid>
          
            <Grid item xs={12} sm={6} align="right">
            <Grid container spacing={2}>
             <Grid item xs={6} sm={4} align="center">
           
                <Box>
                    <CircularProgressbar className={classes.CircularProgressbar} value={percentage} text={`${percentage}%`} style={{width:"80%"}} /> 
                    <Typography variant="h6" className={classes.textBox2} align ="center">Contact Scan</Typography>
                    </Box>

              </Grid>

            <Grid item xs={6} sm={4} align="center">
                <Box>
                    <CircularProgressbar className={classes.CircularProgressbar} value={percentage} text={`${percentage}%`} /> 
                    <Typography variant="h6" className={classes.textBox2} align ="center">Holders Scan</Typography>
                    <Typography variant="h6" className={classes.textBox2} align ="center" style={{marginTop:"0px", color: "#9F9F9F"}}>Top 10 Holders Own</Typography>
                </Box>
            </Grid>

            <Grid item xs={6} sm={4} align="center">
                <Box>
                    <CircularProgressbar className={classes.CircularProgressbar} value={percentage} text={`${percentage}%`} /> 
                    <Typography variant="h6" className={classes.textBox2} align ="center">Liquidity Scan</Typography>
                 
                </Box>
            </Grid>

            <Grid item xs={12} sm={12} align="center">
                      <Box className={classes.CircularButton} mt={2}>
                    <Button variant="contained" color="secondary" 
                       >Track It!
                    </Button>
                      </Box>
                    </Grid>
           </Grid>
       
             
                {/* <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={12} align="right">
                      <Box className={classes.CircularButton}>
                      <Button variant="outlined" color="primary" size="large">
                      Download Code
                    </Button>
                    <Button variant="contained" color="secondary"
                       >Track It!
                    </Button>
                      </Box>
                    </Grid>
                 </Grid> */}
            
            </Grid>
          </Grid>
        

          <Box className={classes.tableBox}>
          <Grid container spacing={3} alignItems="center">
              <Grid item xs={12} sm={12}>
              <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>My Latest Project Sniffed</StyledTableCell>
            <StyledTableCell align="right"></StyledTableCell>
            <StyledTableCell align="right"></StyledTableCell>
            <StyledTableCell align="right"></StyledTableCell>
            <StyledTableCell align="right"></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell component="th" scope="row">
                {row.name}
              </StyledTableCell>
              <StyledTableCell align="right">{row.calories}</StyledTableCell>
              <StyledTableCell align="right">{row.fat}</StyledTableCell>
              <StyledTableCell align="right">{row.carbs}</StyledTableCell>
              <StyledTableCell align="right">{row.protein}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
            
                </Grid>
              </Grid>
          </Box>
        </Box>

      </Container>
    </Box>
  )
}
