import React, { useContext } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Grid,
  Box,
  Hidden,
  Button,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import MoreAction from "./MoreAction";
import { Menu as MenuIcon } from "@material-ui/icons";
import classNames from "classnames";
import ConnectWallet from "../ConnectWalletPopUp/";
// styles
import useStyles from "./styles";
// components
// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";
import { UserContext } from "../../context/User";
import MenuLink from "./MenuLink";
import HeaderCoins from "./HeaderCoins";
import { useWeb3React } from "@web3-react/core";
export default function Header(props) {
  var classes = useStyles();
  const user = useContext(UserContext);
  // global
  const { account, chainId, library } = useWeb3React();
  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  const [openWalletConnect, setOpenWalletConnect] = React.useState(false);
  console.log("layoutState", layoutState);
  return (
    <>
      {openWalletConnect && (
        <ConnectWallet
          open={openWalletConnect}
          handleClose={() => setOpenWalletConnect(false)}
        />
      )}

      <AppBar
        position="fixed"
        className={layoutState.isSidebarOpened ? `appBar` : `appBarCloses`}
        elevation={0}
      >
        <Grid container spacing={2} alignItems="center">
          <Hidden mdUp>
            <Grid item xs={5} sm={4} md={12}>
              <Toolbar className={classes.toolbar}>
                <Hidden mdUp>
                  <IconButton
                    color="inherit"
                    onClick={() => toggleSidebar(layoutDispatch)}
                    className={classNames(
                      classes.headerMenuButtonSandwich,
                      classes.headerMenuButtonCollapse
                    )}
                  >
                    {layoutState.isSidebarOpened ? (
                      <MenuIcon
                        classes={{
                          root: classNames(
                            classes.headerIcon,
                            classes.headerIconCollapse
                          ),
                        }}
                      />
                    ) : (
                      <MenuIcon
                        classes={{
                          root: classNames(
                            classes.headerIcon,
                            classes.headerIconCollapse
                          ),
                        }}
                      />
                    )}
                  </IconButton>
                </Hidden>
                <Hidden smUp>
                  <Link to="/">
                    {" "}
                    <img
                      src="../images/Logo1.png"
                      className="logoimg"
                      alt="logo"
                    />
                  </Link>
                </Hidden>
                <div className={classes.grow} />
              </Toolbar>
            </Grid>
          </Hidden>
          <Grid
            item
            xs={7}
            sm={8}
            md={12}
            align="right"
            className={classes.sidbar}
          >
            <Box className={classes.sidbar2}>
              <Hidden smDown>
                <HeaderCoins />
                {/* <MenuLink /> */}
              </Hidden>
              {/* <Hidden lgUp>
              <MoreAction />
            </Hidden> */}
              {account ? (
                <Button
                  className={classes.buttonright}
                  variant="contained"
                  size="large"
                  // component={Link}
                  // to="/app/home"
                  onClick={() =>
                    window.open(
                      "https://satellitecrypto.tech/#/app/landingpage"
                    )
                  }
                >
                  Home
                </Button>
              ) : (
                <Button
                  className={classes.buttonright}
                  variant="contained"
                  size="large"
                  // component={Link}
                  // to="/app/home"
                  style={{
                    whiteSpace: "nowrap",
                  }}
                  onClick={() => setOpenWalletConnect(true)}
                >
                  Connect Wallet
                </Button>
              )}
              {/* <Button variant="contained" color="primary"  >
                            BUY
                        </Button> */}

              {/* <Box>
              <Button>hjjk</Button>
            </Box> */}
              {/*  */}
              {/* {account && (
              <Box pl={1}>
                <IconButton
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                  size="small"
                  style={{
                    background:
                      "linear-gradient(90deg, #2599FA -23.36%, #42E8E0 59.43%)",
                  }}
                  aria-haspopup="true"
                >
                  {" "}
                  <Avatar src={"images/user1.png"} />
                </IconButton>
                <Menu
                  id="simple-menu"
                  style={{ position: "absolute", top: "3.5%" }}
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <Link
                    to={{
                      pathname: "/app/profile",
                      search: user?.userData?._id,
                    }}
                    style={{ textDecoration: "none" }}
                  >
                    <MenuItem onClick={handleClose}>Profile</MenuItem>
                  </Link>
                  <Link
                    to="/app/editprofile"
                    style={{ textDecoration: "none" }}
                  >
                    <MenuItem onClick={handleClose}>Edit Profile</MenuItem>
                  </Link>
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      user.logoutHanlder();
                    }}
                  >
                    Logout
                  </MenuItem>
                </Menu>
              </Box>
            )} */}
              {/*  */}
            </Box>
          </Grid>
        </Grid>
      </AppBar>
    </>
  );
}
