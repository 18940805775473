import { useState } from "react";
import styled from "styled-components";
import {
  Flex,
  Box,
  Text,
  ExpandableLabel,
  LinkExternal,
  Grid,
  HelpIcon,
  useTooltip,
} from "@pancakeswap/uikit";
import { getApy } from "./compoundApyHelpers";

const Footer = styled(Flex)`
  background: ${({ theme }) => theme.colors.dropdown};
`;

const LinkExternal1 = styled.div`
  a {
    background: none;
    text-decoration: none;
    color: #262831;
    svg {
      fill: #262831;
    }
  }
  text-align: center;
  button {
    text-decoration: none;
    color: #262831;
    svg {
      fill: #262831;
    }
  }
`;
const BulletList = styled.ul`
  list-style-type: none;
  margin-top: 16px;
  padding: 0;
  li {
    margin: 0;
    padding: 0;
  }
  li::before {
    content: "•";
    margin-right: 4px;
    color: ${({ theme }) => theme.colors.textSubtle};
  }
  li::marker {
    font-size: 12px;
  }
`;

const RoiCalculatorFooter = ({
  isFarm,
  apr,
  displayApr,
  autoCompoundFrequency,
  multiplier,
  linkLabel,
  linkHref,
  performanceFee,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const {
    targetRef: multiplierRef,
    tooltip: multiplierTooltip,
    tooltipVisible: multiplierTooltipVisible,
  } = useTooltip(
    <>
      <Text>
        {
          "The Multiplier represents the proportion of CAKE rewards each farm receives, as a proportion of the CAKE produced each block."
        }
      </Text>
      <Text my="24px">
        {
          "For example, if a 1x farm received 1 CAKE per block, a 40x farm would receive 40 CAKE per block."
        }
      </Text>
      <Text>
        {
          "This amount is already included in all APR calculations for the farm."
        }
      </Text>
    </>,
    { placement: "top-end", tooltipOffset: [20, 10] }
  );

  const gridRowCount = isFarm ? 4 : 2;
  const apy = (
    getApy(
      apr,
      autoCompoundFrequency > 0 ? autoCompoundFrequency : 1,
      365,
      performanceFee
    ) * 100
  ).toFixed(2);

  return (
    <Footer
      p="16px"
      flexDirection="column"
      style={{
        // maxWidth: "330px",
        // padding: " 0 10px",
        color: "#747681",
        fill: "#747681",
      }}
    >
      <LinkExternal1>
        <ExpandableLabel
          expanded={isExpanded}
          onClick={() => setIsExpanded((prev) => !prev)}
          // style={{ color: "#2628319c" }}
          style={{ color: "#747681" }}
          // className="classesFooter"
        >
          {isExpanded ? "Hide" : "Details"}
        </ExpandableLabel>
      </LinkExternal1>
      {isExpanded && (
        <Box px="8px">
          <Grid
            gridTemplateColumns="2.5fr 1fr"
            gridRowGap="8px"
            gridTemplateRows={`repeat(${gridRowCount}, auto)`}
          >
            {/* {isFarm && (
              <>
                <Text color="textSubtle" small>
                  {"APR (incl. LP rewards)"}
                </Text>
                <Text small textAlign="right">
                  {displayApr}%
                </Text>
              </>
            )}
            <Text color="textSubtle" small>
              {isFarm ? "Base APR (CAKE yield only)" : "APR"}
            </Text>
            <Text small textAlign="right">
              {apr.toFixed(2)}%
            </Text> */}
            <Text color="textSubtle" small>
              APY
              {/* {autoCompoundFrequency > 0 ? autoCompoundFrequency : 1} */}
            </Text>
            <Text small textAlign="right">
              {apy}%
            </Text>
            <Text color="textSubtle" small>
              APY
              {autoCompoundFrequency > 0 ? autoCompoundFrequency : 1}
            </Text>
            <Text small textAlign="right">
              {apy}%
            </Text>
            {/* {isFarm && (
              <>
                <Text color="textSubtle" small>
                  {"Farm Multiplier"}
                </Text>
                <Flex justifyContent="flex-end" alignItems="flex-end">
                  <Text small textAlign="right" mr="4px">
                    {multiplier}
                  </Text>
                  <span ref={multiplierRef}>
                    <HelpIcon color="textSubtle" width="16px" height="16px" />
                  </span>
                  {multiplierTooltipVisible && multiplierTooltip}
                </Flex>
              </>
            )} */}
          </Grid>
          <BulletList>
            <li>
              <Text
                fontSize="12px"
                textAlign="center"
                color="textSubtle"
                display="inline"
              >
                {"Calculated based on current rates."}
              </Text>
            </li>
            {/* {isFarm && (
              <li>
                <Text
                  fontSize="12px"
                  textAlign="center"
                  color="textSubtle"
                  display="inline"
                >
                  {
                    "LP rewards: 0.17% trading fees, distributed proportionally among LP token holders."
                  }
                </Text>
              </li>
            )} */}
            <li>
              <Text
                fontSize="12px"
                textAlign="center"
                color="textSubtle"
                display="inline"
              >
                {
                  "All figures are estimates provided for your convenience only, and by no means represent guaranteed returns."
                }
              </Text>
            </li>
            {performanceFee > 0 && (
              <li>
                <Text
                  mt="14px"
                  fontSize="12px"
                  textAlign="center"
                  color="textSubtle"
                  display="inline"
                >
                  {
                    ("All estimated rates take into account this pool’s %fee%% performance fee",
                    {
                      fee: performanceFee,
                    })
                  }
                </Text>
              </li>
            )}
          </BulletList>
          <LinkExternal1>
            <Flex justifyContent="center" mt="24px">
              <LinkExternal
                href={linkHref}
                style={{ color: "#747681", fill: "#747681" }}
              >
                {linkLabel}
              </LinkExternal>
            </Flex>
          </LinkExternal1>
        </Box>
      )}
    </Footer>
  );
};

export default RoiCalculatorFooter;
