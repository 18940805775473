import React from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import { green } from "@material-ui/core/colors";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "40%",
    marginTop: "30px",
    "& h6": {
      fontSize: "16px",
      fontFamily: "'Lexend Deca'",
      fontWeight: "500",
      lineHeight: "1.6",
      letterSpacing: "0.0075em",
      paddingBottom: "10px",
    },
  },
  durationbox: {
    marginTop: "5px",
    "& h3": {
      fontSize: "16px",
      fontFamily: "'Lexend Deca'",
      fontWeight: "500",
      lineHeight: "1.6",
      letterSpacing: "0.0075em",
      // paddingBottom: "10px",
    },
  },
  box4: {
    marginTop: "1rem",
    "& h2": {
      fontSize: "16px",
      fontFamily: "'Lexend Deca'",
      fontWeight: "500",
      lineHeight: "1.6",
      letterSpacing: "0.0075em",
      paddingBottom: "10px",
    },
    "& h4": {
      fontSize: "16px",
      fontFamily: "'Lexend Deca'",
      fontWeight: "500",
      lineHeight: "1.6",
      letterSpacing: "0.0075em",
      paddingTop: "10px",
    },
  },
  Dynamicbox: {
    "& h3": {
      fontSize: "16px",
      fontFamily: "'Lexend Deca'",
      fontWeight: "500",
      lineHeight: "1.6",
      letterSpacing: "0.0075em",
      paddingTop: "10px",
    },
  },
  box30: {
    width: "160px",
    // paddingTop: "15px",
    paddingBottom: "15px",
  },
}));

export default function FullWidthTabs() {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [checked3, setChecked3] = React.useState(true);
  const [checked6, setChecked6] = React.useState(false);
  const [checked9, setChecked9] = React.useState(false);
  const [checked12, setChecked12] = React.useState(false);
  const [checked18, setChecked18] = React.useState(false);
  const [checked24, setChecked24] = React.useState(false);
  const [selectMonth, setSelectMonth] = React.useState("Month");
  const [selectTime, setSelectTime] = React.useState();
  const [APR, setAPR] = React.useState();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: false,
    checkedC: false,
    checkedF: false,
    checkedG: false,
    checkedH: false,
    checkedI: false,
    checkedJ: false,
  });
  const handleChangecheck = (event) => {
    console.log(typeof event.target.name);
    console.log(state);
    event.preventDefault();
    try {
      if (state.checkedA) {
        console.log(state.checkedA);
        setState({ ...state, ["checkedA"]: false });
      }
      if (state.checkedB) {
        console.log(state.checkedB);
        setState({ ...state, checkedB: false });
      }
      if (state.checkedC) {
        console.log(state.checkedC);
        setState({ ...state, checkedC: false });
      }
      if (state.checkedF) {
        console.log(state.checkedF);
        setState({ ...state, checkedF: false });
      }
    } catch (error) {
      console.log(error);
    }
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const [selectedValue, setSelectedValue] = React.useState("a");
  const [pCheckSelect, setPCheckSelect] = React.useState(true);

  const handleChangeRadio = (event) => {
    setSelectedValue(event.target.value);
  };
  return (
    <Box className={classes.root} mt={5}>
      {/* <Typography variant="h6"> Earned team</Typography> */}
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="Fix" {...a11yProps(0)} />
          <Tab label="Dynamic" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <Box className={classes.durationbox}>
            {/* <Box className={classes.field1}></Box> */}
            <Typography variant="h3">Select custom duration</Typography>

            <Radio
              checked={selectedValue === "a"}
              onChange={handleChangeRadio}
              value="a"
              name="radio-button-demo"
              inputProps={{ "aria-label": "A" }}
            />

            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked3}
                    onChange={(e) => {
                      setChecked3(e.target.checked);
                      setSelectedValue("a");
                      setChecked6(false);
                      setChecked9(false);
                      setChecked12(false);
                      setChecked18(false);
                      setChecked24(false);
                      setSelectTime("");
                    }}
                    name="checkedA"
                  />
                }
                label="3 months"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked6}
                    // onChange={handleChangecheck}
                    onChange={(e) => {
                      setChecked6(e.target.checked);
                      setChecked3(false);
                      setSelectedValue("a");
                      setChecked9(false);
                      setChecked12(false);
                      setChecked18(false);
                      setChecked24(false);
                      setSelectTime("");
                    }}
                    name="checkedB"
                    color="primary"
                  />
                }
                label="6 months"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked9}
                    onChange={(e) => {
                      setChecked9(e.target.checked);
                      setChecked3(false);
                      setChecked6(false);
                      setSelectedValue("a");
                      setChecked12(false);
                      setChecked18(false);
                      setChecked24(false);
                      setSelectTime("");
                    }}
                    name="checkedC"
                  />
                }
                label="9 months"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked12}
                    onChange={(e) => {
                      setChecked12(e.target.checked);
                      setChecked3(false);
                      setChecked6(false);
                      setChecked9(false);
                      setSelectedValue("a");
                      setChecked18(false);
                      setChecked24(false);
                      setSelectTime("");
                    }}
                    name="checkedF"
                    color="primary"
                  />
                }
                label="12 months"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked18}
                    onChange={(e) => {
                      setChecked18(e.target.checked);
                      setChecked3(false);
                      setChecked6(false);
                      setChecked9(false);
                      setChecked12(false);
                      setSelectedValue("a");
                      setChecked24(false);
                      setSelectTime("");
                    }}
                    name="checkedG"
                    color="primary"
                  />
                }
                label="18 months"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked24}
                    onChange={(e) => {
                      setChecked24(e.target.checked);
                      setChecked3(false);
                      setChecked6(false);
                      setChecked9(false);
                      setChecked12(false);
                      setChecked18(false);
                      setSelectedValue("a");
                      setSelectTime("");
                    }}
                    name="checkedH"
                    color="primary"
                  />
                }
                label="24 months"
              />
            </FormGroup>
          </Box>
          <Box
            mt={5}
            style={{ height: "42px", display: "flex", height: "42px" }}
          >
            {" "}
            <Radio
              checked={selectedValue === "b"}
              onChange={handleChangeRadio}
              value="b"
              name="radio-button-demo"
              inputProps={{ "aria-label": "B" }}
            />
            <Box
              className={`${classes.box30} textFieldB`}
              mt={2}
              // className="textFieldB"
            >
              <TextField
                fullWidth
                id="outlined-basic"
                placeholder="Enter custom time"
                // label="Outlined"
                variant="outlined"
                // value="0.00765"
                value={selectTime}
                onKeyPress={(event) => {
                  if (event?.key === "-" || event?.key === "+") {
                    event.preventDefault();
                  }
                }}
                type="number"
                onChange={(e) => {
                  if (e.target.value && e.target.value != "-") {
                    setSelectTime(Math.abs(Number(e.target.value)));
                  } else {
                    setSelectTime();
                  }

                  setSelectedValue("b");
                  setChecked24(false);
                  setChecked3(false);
                  setChecked6(false);
                  setChecked9(false);
                  setChecked12(false);
                  setChecked18(false);
                }}
              />
            </Box>{" "}
            &nbsp;&nbsp;&nbsp;
            <select
              className="daySelect"
              onChange={(e) => {
                setSelectMonth(e.target.value);
                setSelectedValue("b");
                setChecked24(false);
                setChecked3(false);
                setChecked6(false);
                setChecked9(false);
                setChecked12(false);
                setChecked18(false);
              }}
              value={selectMonth}
            >
              <option value="Select days">Select days</option>
              <option value="Days">Days</option>
              <option value="Month">Month</option>
              <option value="Years">Years</option>
            </select>
            {/* 
             <FormControl
                    variant="outlined"
                    fullWidth
                    className={classes.dropdown12}
                  >
                    <Select
                      labelId="mutiple-select-label"
                      multiple
                      value={selected}
                      onChange={handleChangeSelect}
                      renderValue={(selected) => selected.join(", ")}
                      // MenuProps={MenuProps}
                    >
                      <MenuItem
                        value="all"
                        classes={{
                          root: isAllSelected ? classes.selectedAll : "",
                        }}
                      >
                        <ListItemIcon>
                          <Checkbox
                            classes={{
                              indeterminate: classes.indeterminateColor,
                            }}
                            checked={isAllSelected}
                            indeterminate={
                              selected.length > 0 &&
                              selected.length < exchangeList.length
                            }
                          />
                        </ListItemIcon>
                        <ListItemText
                          classes={{ primary: classes.selectAllText }}
                          primary="Select All"
                        />
                      </MenuItem>
                      {exchangeList &&
                        exchangeList.map((option) => (
                          <MenuItem key={option} value={option}>
                            <ListItemIcon>
                              <Checkbox
                                checked={selected.indexOf(option) > -1}
                              />
                            </ListItemIcon>
                            <ListItemText primary={option} />
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl> */}
            {/* <FormControl
              className={classes.selct1}
              style={{ height: "42px", width: "10rem" }}
            >
              <InputLabel id="demo-simple-select-label">Select days</InputLabel>
              <Select
                style={{ heigth: "40px" }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                fullWidth
                value={staterrr}
                label="Select Coin"
                onChange={(e) => setStatet(e.target.value)}
              >
                <MenuItem value={10}>Days</MenuItem>
                <MenuItem value={20}>Weeks</MenuItem>
                <MenuItem value={30}>months</MenuItem>
              </Select>
            </FormControl> */}
          </Box>

          <Box className={classes.box4}>
            <Typography variant="h2">Reword APR</Typography>
            <Box className={`${classes.box3} textFieldB`}>
              <TextField
                fullWidth
                id="outlined-basic"
                type="number"
                variant="outlined"
                value={APR}
                onKeyPress={(event) => {
                  if (event?.key === "-" || event?.key === "+") {
                    event.preventDefault();
                  }
                }}
                onChange={(e) => {
                  if (e?.key === ".") {
                    setAPR(0, e.target.value);
                  } else {
                    setAPR(e.target.value);
                  }
                }}
              />
            </Box>
            <Typography variant="h4">Calculating -1.2:&nbsp;BNB</Typography>
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <Box className={classes.Dynamicbox}>
            <Typography variant="h3">Reword per Block:</Typography>
            <Box className={classes.box3}>
              <TextField
                fullWidth
                id="outlined-basic"
                value="0.0120"
                variant="outlined"
                // value="0.00765"
              />
            </Box>
            <Typography variant="h3">250000 Blocks=1.200 APR</Typography>
          </Box>
        </TabPanel>
      </SwipeableViews>
    </Box>
  );
}
