import React from "react";
import {
  Box,
  makeStyles,
  Typography,
  Grid,
  Button,
  Container,
} from "@material-ui/core";
import { MdOutlineContentCopy } from "react-icons/md";
import { FaTwitter } from "react-icons/fa";
import { FaTelegramPlane } from "react-icons/fa";
import { FiFacebook } from "react-icons/fi";
import { FaDiscord } from "react-icons/fa";
import { copyTextByValue } from "../../services";
import { useWeb3React } from "@web3-react/core";
import { sortAddress1 } from "../../utils/index";

const useStyles = makeStyles((theme) => ({
  bannerBox1: {
    background:
      "radial-gradient(48.06% 27.42% at 84.2% 48.18%, rgba(32, 104, 188, 0.45) 0%, rgba(52, 107, 171, 0) 100%) , radial-gradient(39.41% 25.47% at 12.19% 49%, rgb(193 215 224 / 45%) 0%, rgba(221, 237, 254, 0.45) 100%) , #FFFFFF",
  },
  bannerBox: {
    backgroundImage: "url('../images/vector.png')",
    backgroundRepeat: "no-repeat",
    backgroundPosition: " top right",
    backgroundSize: "55%",
    marginTop: "-7rem",
    paddingBottom: "10rem",
    "@media(max-width:960px)": {
      background: "white",

      backgroundRepeat: "no-repeat",
      backgroundPosition: "right",
      // marginTop: "-3rem",
    },
  },
  bodyStyle: {
    padding: "30px",
    overflow: "hidden",
    position: "relative",
    background:
      "linear-gradient(180deg, rgba(12, 169, 203, 0.3) 0%, rgba(196, 196, 196, 0) 100%)",
    textAlign: "center",
    borderRadius: "5px",

    "& h1": {
      fontSize: "30px",
      color: "#fff",
      fontWeight: "600",
      lineHeight: "55px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "25px",
      },
    },
  },

  times: {
    color: "#fff",
    width: "90px",
    height: "90px",
    margin: "auto",
    display: "flex",
    zIndex: "1",
    position: "relative",
    fontSize: "29px",
    marginTop: "10px",
    alignItems: "center",
    fontWeight: "bold",
    borderRadius: "10px",
    justifyContent: "center",
    background:
      "linear-gradient(180deg, rgba(12, 169, 203, 0.2) 0%, rgba(81, 218, 249, 0.2) 100%)",
    [theme.breakpoints.down("xs")]: {
      width: "60px",
      height: "60px",
    },

    "& h1": {
      fontSize: "45px",
      color: "#fff",
      fontWeight: "600",
      fontWeight: "bold",
      fontFamily: "'Quantico', sans-serif",
      [theme.breakpoints.down("xs")]: {
        fontSize: "30px",
      },
    },
  },
  mainBox: {
    color: "white",
  },
  mintingButton: {
    color: "#fff",
    background: "linear-gradient(272.26deg, #0DD0FF 36.78%, #05A4CA 86.13%)",
    boxSizing: "border-box",
    borderRadius: "5px",
    width: "35%",
    height: "48px",
  },
  textbox: {
    "& h1": {
      fontSize: "44px",
      fontWeight: "bold",
      marginBottom: "10px",
      color: "white",
      maxWidth: "700px",
      lineHeight: "55px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "40px",
        lineHeight: "40px",
      },
    },
    "& h5": {
      fontSize: "30px",
      fontWeight: "500",
      fontWeight: "bold",
      color: "#654BA9",
      marginBottom: "10px",
    },
    "& p": {
      fontSize: "16px",
      color: "#535059",
      width: "100%",
      maxWidth: "600px",
    },
    "& div": {
      "& button": {
        "&:last-child": {
          marginLeft: "20px",
        },
      },
    },
  },
  box1: {
    display: "flex",
    justifyContent: "end",
    pading: "2rem 0 3rem 0",
    "@media(max-width:960px)": {
      // marginTop: "6rem",
      display: "flex",
      justifyContent: "center",
    },
    "& img": {
      marginTop: "13rem",
      width: "100%",
      "@media(max-width:960px)": {
        marginTop: "6rem",
        display: "flex",
        justifyContent: "center",
      },
    },
  },
  iconBox: {
    top: "44%",
    left: "78px",
    position: "absolute",
    transform: "translateY(-50%)",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      left: "0",
    },
    [theme.breakpoints.down("xs")]: {
      left: "0",
    },
  },
  iconimg: {
    "& a": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background:
        "linear-gradient(180deg, rgba(12, 169, 203, 0.3) 0%, rgba(196, 196, 196, 0) 100%)",
      borderRadius: "50%",
      width: "40px",
      height: "40px",
      marginTop: "9px",
    },
    "& svg": {
      fontSize: "20px",
      color: "#55ACEE",
      "&:hover": {
        color: "#fff",
        cursor: "pointer",
      },
    },
  },
  box2: {
    "@media(max-width:960px)": {
      // background: "white",
      // backgroundImage: "url('./images/vector.png')",
      // backgroundRepeat: "no-repeat",
      // backgroundPosition: "right",
      // marginTop: "-3rem",
    },
  },
  mainBoxText: {
    "& p": {
      fontFamily: "'Poppins', sans-serif",
      fontWeight: "bold",
      lineHeight: "1.5",
      letterSpacing: "0.00938em",
      fontSize: "14px",
      color: "#fff",
    },
  },
  buttonBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  // bannerIcon:{
  //   width: "80px",
  //   height: "80px",
  //   position: "relative",
  // },
  ContentBox: {
    marginTop: "17rem",
    "@media(max-width:960px)": {
      marginTop: "8rem",
    },
    // width: "132%",
    "& h2": {
      fontFamily: "'Lexend Deca', sans-serif",
      // fontFamily: "'Lexend Deca'",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "45px",
      lineHeight: "55px",
      textTransform: "capitalize",
      color: "#0E2137",
    },
    "& h3": {
      fontFamily: "'Lexend Deca', sans-serif",
      marginTop: "10px",
      // fontFamily: "'Lexend Deca'",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "27px",
      lineHeight: "45px",
      color: "#0E2137",
      marginTop: "5px",
    },
    "& h5": {
      fontFamily: "'Lexend Deca', sans-serif",
      marginTop: "10px",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "24px",
      color: "#0E2137",
      maxWidth: "32rem",
    },
  },
  copy: {
    width: "75%",
    marginTop: "2rem",
    background: "#0E2137",
    alignItems: "center",
    borderRadius: "10px",
    padding: "13px",
    display: "flex",
    // justifyContent: "space-between",
    border: "2px solid #4E6D90",
    height: "50px",

    "& h3": {
      paddingLeft: "10px",
      paddingRight: "10px",
      fontFamily: "'Lexend Deca'",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "18px",
      lineHeight: "22px",
      color: "#FFFFFF",
      marginTop: "5px",
    },
    "& h6": {
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "18px",
      color: "#D2D2D2",
      "@media(max-width:500px)": {
        display: "none",
      },
    },
  },
  iconBoxs: {
    border: "2px solid #4E6D90",
    borderRadius: "50px",
    width: "3rem",
    height: "3rem",
    textAlign: "center",
    alignItems: "center",
    background: "#0E2137",
    display: "flex",
    justifyContent: "center",
  },
  icon1: {
    cursor: "pointer",
    fontSize: "20px",
    color: "white",
  },
}));
export default function Dashboard(props) {
  const { activate, deactivate, account, library, chainId } = useWeb3React();
  var classes = useStyles();
  return (
    <>
      <Box className={classes.bannerBox1}>
        <Box className={classes.bannerBox}>
          <Container>
            {" "}
            <Grid container>
              <Grid item lg={5} md={5} sm={12} xs={12}>
                <Box>
                  <Box>
                    {" "}
                    <Box className={classes.ContentBox}>
                      <Typography variant="h2">SATELLITE</Typography>
                      <Typography variant="h3">
                        Informative Trustworthy Experienced
                      </Typography>
                      <Typography variant="h5">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book.
                      </Typography>
                    </Box>
                    <Box>
                      {account && (
                        <Box className={classes.copy}>
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            {" "}
                            <MdOutlineContentCopy
                              style={{
                                fontSize: "20px",
                                marginRight: "15px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                copyTextByValue({ account });
                              }}
                            />
                            {/* <Typography variant="h3">Copied</Typography> */}
                          </Box>

                          <Typography variant="h6">
                            {sortAddress1(account)}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                    <Box style={{ marginTop: "2rem", display: "flex" }}>
                      <Box className={classes.iconBoxs}>
                        {" "}
                        <FaTwitter className={classes.icon1} />
                      </Box>
                      &nbsp; &nbsp; &nbsp;
                      <Box className={classes.iconBoxs}>
                        {" "}
                        <FiFacebook className={classes.icon1} />
                      </Box>
                      &nbsp; &nbsp; &nbsp;
                      <Box className={classes.iconBoxs}>
                        {" "}
                        <FaDiscord className={classes.icon1} />
                      </Box>
                      &nbsp; &nbsp; &nbsp;
                      <Box className={classes.iconBoxs}>
                        {" "}
                        <FaTelegramPlane className={classes.icon1} />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item lg={7} md={7} sm={12} xs={12}>
                <Box className={classes.box1}>
                  {/* <img src="images/vector.png" alt="images" width="100%" /> */}{" "}
                  <Box className={classes.box2}>
                    <img src="images/Coin.png" alt="images" width="100%" />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </>
  );
}
