import React from "react";
import {
  Container,
  Typography,
  Box,
  makeStyles,
  Grid,
  Button,
} from "@material-ui/core";
import Slider from "react-slick";

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    justifyContent: "center",
    "& h2": {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "30px",
      color: "#FFFFFF",
      paddingTop: "2rem",
    },
  },
  bannerBox: {
    backgroundColor: "#0C2739",
    width: "100%",
  },
}));

export default function NewFooter() {
  const classes = useStyles();

  return (
    <Box className={classes.bannerBox}>
      {/* <Container maxWidth="xl"> */}
      <Grid container className={classes.footer}>
        <Grid item lg={4} md={4} sm={4} xs={12}>
          <Box className={classes.card}>
            <img src="./images/logo.png" className={classes.img1} />
          </Box>
        </Grid>
        <Grid item lg={4} md={4} sm={4} xs={12}>
          <Box className={classes.card}>
            <img src="./images/Footer.png" className={classes.img1} />
          </Box>
        </Grid>
        <Grid item lg={4} md={4} sm={4} xs={12}>
          <Box className={classes.card}>
            <Typography variant="h2">Copyright © 2022 VAYU</Typography>
          </Box>
        </Grid>
      </Grid>
      {/* </Container> */}
    </Box>
  );
}
