import React from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import { BsBellFill } from "react-icons/bs";
import { FaTelegramPlane } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
// import useStyles from "./styles";
import { AiOutlineRise } from "react-icons/ai";
const Token = [
  {
    count: "#1",
    token: "Shiba",
    icon: "images/logo_2.png",
  },
  {
    count: "#1",
    token: "Shiba",
    icon: "images/logo_2.png",
  },
  {
    count: "#1",
    token: "Shiba",
    icon: "images/logo_2.png",
  },
  {
    count: "#1",
    token: "Shiba",
    icon: "images/logo_2.png",
  },
  {
    count: "#1",
    token: "Shiba",
    icon: "images/logo_2.png",
  },
  {
    count: "#1",
    token: "Shiba",
    icon: "images/logo_2.png",
  },
];
const useStyles = makeStyles((theme) => ({
  marqueAndText: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    "& h5": {
      fontSize: "16px",
      color: "#fff",
      marginRight: "5px",
      marginLeft: "10px",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
  },
  mainmenus: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
  },
  marquee: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    "& h4": {
      fontSize: "14px",
      color: "#989898",
      marginRight: "5px",
      fontWeight: "600",
    },
    "& h5": {
      fontSize: "14px",
      color: "#F6A52D",
      marginRight: "5px",
      marginLeft: "0px",
      textTransform: "uppercase",
    },
    "& img": {
      width: "15px",
      height: "15px",
      borderRadius: "50%",
      marginRight: "15px",
    },
  },
  coinPrice: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    whiteSpace: "nowrap",
    "& span": {
      background: "#4CB3DC",
      fontSize: "11px",
      borderRadius: "3px",
      padding: "6px",
      color: "#fff",
      padding: "6px",
      marginRight: "7px",
      fontWeight: "bold",
    },
    "& h4": {
      fontSize: "14px",
      color: "#fff",
      marginRight: "5px",
      fontWeight: "600",
    },
  },
}));
export default function HeaderCoins(props) {
  var classes = useStyles();
  return (
    <Box className={classes.mainmenus}>
      <Box className={classes.marqueAndText}>
        {/* <Typography variant="h5" >
       <AiOutlineRise />
       </Typography> */}
        {/* <marquee style={{ color: 'red', fontSize: '3em' }} scrollamount="3">
     <Box className={classes.marquee}>
     {Token.map((data, i) => {
            return (
              <>
            
               <Typography variant="h4">{data.count}</Typography>
               <Typography variant="h5">{data.token}</Typography>
               <img src={data.icon} alt="" />
               </>
            );
          })}
            </Box>
     </marquee> */}
      </Box>
      {/* <Link className={classes.navLinks} to="/app/notification">
        <BsBellFill style={{fontSize:"20px", color:"#fff", margin:"0 15px"}}/>
      </Link>
   <Link className={classes.navLinks} to="#">
       
        <FaTwitter style={{fontSize:"16px", color:"#fff", margin:"0 5px"}}/>
        
      </Link>
   <Link className={classes.navLinks} to="#">
      
        <FaTelegramPlane style={{fontSize:"16px", color:"#fff", margin:"0 15px 0 5px"}}/>
      </Link> */}

      {/* <Box className={classes.coinPrice}>
        <span>GV</span>
        <Typography variant="h4">$ 1.15</Typography>
      </Box> */}
    </Box>
  );
}
