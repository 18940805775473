import React from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

const useStyles = makeStyles((theme) => ({
  textbox: {
    mint: {
      fontSize: "14px ",
      border: "1px solid #E8424C",
      background: "#E8424C",
      fontWeight: 600,
      height: "44px ",
      color: "#FFFFFF",
      minWidth: "150px ",
      borderRadius: "50px",
      boxShadow: "none ",
      cursor: "pointer",
      [theme.breakpoints.down("xs")]: {
        height: "45px ",
        minWidth: "120px ",
      },
      "&:hover": {
        borderColor: "#E8424C",
        background: "#E8424C",
      },
    },
    "& h1": {
      fontSize: "25px",
      fontWeight: "bold",
      lineHeight: "55px",
      color: "#FABE25",
    },
    "& h2": {
      fontSize: "45px",
      fontWeight: "bold",
      lineHeight: "55px",
      color: "#FABE25",
    },
    "& h5": {
      fontSize: "30px",
      fontWeight: "500",
      color: "#fff",
      marginBottom: "10px",
      marginTop: "15px",
    },
    "& h6": {
      color: "#9F9F9F",
      marginBottom: "10px",
    },
    "& p": {
      fontSize: "14px",
      color: "#9F9F9F",
      width: "100%",
      // maxWidth: "600px",
    },
    "& label": {
      fontSize: "16px",
      color: "#fff",
      // maxWidth: "600px",
    },
    "& div": {
      "& button": {
        "&:last-child": {
          marginLeft: "20px",
        },
      },
    },
  },
  mainBox: {
    padding: "20px 20px 50px",
    overflow: "hidden",
    position: "relative",
    background:
      "linear-gradient( 152.97deg, rgba(255, 255, 255, 0.08) 0%, rgba(232, 66, 76, 0.062) 100%)",
    transition: "0.5s",
    borderRadius: "0 30px 0 0",
    backdropFilter: "blur(42px)",

    "& h1": {
      fontSize: "25px",
      fontWeight: "bold",
      lineHeight: "55px",
      color: "#FABE25",
    },
    "& p": {
      fontSize: "14px",
      color: "#9F9F9F",
      width: "100%",
      // maxWidth: "600px",
    },
    "& small": {
      fontSize: "12px",
      color: "#6c757d!important",
      // maxWidth: "600px",
    },
    "& label": {
      color: "#9F9F9F",
      padding: "0",
      fontSize: "14px",
      transition:
        "opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    },
  },
}));

function Roadmap(props) {
  const classes = useStyles();

  return (
    // <Page title="GainPool">
    <Box pt={3} pb={6}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <Typography variant="h1"> Mint your own token!</Typography>
          <Grid spacing={4} container>
            <Grid item xs={12} sm={6}>
              <Box mt={2}>
                <label>Token Name</label>
                <TextField id="outlined-basic" variant="outlined" fullWidth />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box mt={2}>
                <label>Token Symbol</label>
                <TextField id="outlined-basic" variant="outlined" fullWidth />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box>
                <label>Total supply (excluding decimals e.g. 100 tokens)</label>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="Total supply"
                  fullWidth
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Box>
                <label>Decimals (18 reccomended)</label>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="Total supply"
                  fullWidth
                />
              </Box>
              <span>Total supply (including decimals - raw amount) = 0</span>
            </Grid>
          </Grid>
          <Box>
            {" "}
            <label>Total supply (including decimals - raw amount) = 0</label>
          </Box>
          <Box mt={1}>
            {" "}
            <label>Fee: 0.2 ETH</label>
          </Box>
          <Box mt={1} mb={3}>
            {" "}
            <label>+ 0.2% total supply</label>
          </Box>
          <Box align="center">
            <Button
              className={classes.mint}
              variant="contained"
              size="large"
              color="primary"
            >
              MINT A NEW TOKEN
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
    // </Page>
  );
}

export default Roadmap;
