import React from "react";
import {
  Box,
  Container,
  makeStyles,
  Typography,
  Grid,
  Button,
  FormControl,
  OutlinedInput,
  InputAdornment,
  InputLabel,
  MenuItem,
} from "@material-ui/core";
import { ImTwitter } from "react-icons/im";
import { AiOutlineGlobal } from "react-icons/ai";
import { FaTelegramPlane } from "react-icons/fa";
import Drag from "./Drag";
import Index from "./Tab/Index";

const useStyles = makeStyles((theme) => ({
  mainBox: {
    minHeight: "80vh",
    paddingTop: "70px",
    paddingBottom: "70px",
    background:
      "radial-gradient(48.06% 27.42% at 84.2% 48.18%, rgba(32, 104, 188, 0.45) 0%, rgba(52, 107, 171, 0) 100%) , radial-gradient(39.41% 25.47% at 12.19% 49%, rgb(193 215 224 / 45%) 0%, rgba(221, 237, 254, 0.45) 100%) , #FFFFFF",
  },
  mainBox1: {
    "& h1": {
      fontFamily: "'Lexend Deca', sans-serif",
      // fontFamily: "'Lexend Deca'",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "45px",
      lineHeight: "55px",
      textTransform: "capitalize",
      color: "#0E2137",
      textAlign: "center",
    },
  },

  box2: {
    border: "2px solid #0E2137",
    padding: "20px",
    transition: "0.5s",
    borderRadius: "10px",
    backgroundColor: "#FFFFFF",
    "&:hover": {
      transform: "translateY(-10px)",
      backgroundColor: "#0E2137",

      "& .icons": {
        color: "white",
      },

      " & .p1": {
        color: "white",
      },

      btn: {
        background: "#3c0040",
      },
      "& h3": { color: "white" },
      "& h6": { color: "white" },
    },
    "& .icons": {
      color: "#0E2137",
      width: "1em",
      height: "1em",

      fontSize: "1.5rem",
      // "&:hover": {
      //   color: "#fff",
      // },
    },
    "& h3": {
      fontSize: "20px",
      fontFamily: "'Lexend Deca'",
      lineHeight: "1.235",
      letterSpacing: "0.00735em",
      color: "#000000",
      fontWeight: "bold",
    },
    "& h6": {
      fontSize: "16px",
      fontFamily: "'Lexend Deca'",
      lineHeight: "1.6",
      letterSpacing: "0.0075em",
      color: "#0E2137",
      fontWeight: "500",
    },
  },
  btn: {
    width: "108px",
    height: "30px",
    display: "flex",
    padding: "5px 10px",
    alignItems: "center",
    borderRadius: "15px",
    justifyContent: "space-between",
    backgroundColor: "#e1c0e4",
  },
  box3: {
    display: "flex",
    justifyContent: "space-between",
    //
  },
  box4: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "20px",
    color: "black",
    "& h6": {
      fontSize: "16px",
      fontFamily: "'Lexend Deca'",
      lineHeight: "1.6",
      letterSpacing: "0.0075em",
      color: "#000000",
      fontWeight: "500",
    },
  },
  box5: {
    display: "flex",
    justifyContent: "space-between",
    "& h6": {
      fontSize: "16px",
      fontFamily: "'Lexend Deca'",
      lineHeight: "1.6",
      letterSpacing: "0.0075em",
      color: "#0E2137",
      fontWeight: "500",
    },
  },
  box6: {
    marginTop: "30px",
    display: "flex",
    justifyContent: "space-between",
    "&:hover": {
      color: "white",
      "& h3": { color: "white" },
      "& h6": { color: "white" },
    },

    "& h6": {
      fontSize: "16px",
      // fontFamily: "'Lexend Deca'",
      lineHeight: "1.6",
      letterSpacing: "0.0075em",
      color: "black",
      // fontWeight: "500",
    },
  },
  p1: {
    display: "flex",
    fontSize: "16px",
    // fontFamily: "'Lexend Deca'",
    fontFamily: '"Roboto",  sans-serif',
    lineHeight: "1.43",
    letterSpacing: "0.01071em",
    color: "#000000",
    fontWeight: "300",

    "& h6": {
      fontSize: "16px",
      fontFamily: "'Lexend Deca'",
      lineHeight: "1.6",
      letterSpacing: "0.0075em",
      color: "#0E2137",
      fontWeight: "500",
    },
  },
  box7: {
    display: "flex",
    justifyContent: "center",
  },

  box0: {
    marginTop: "50px",
  },
  radius1: {
    width: "13px",
    height: "13px",
    borderRadius: "7px",
    backgroundColor: "#0E2137",
  },
  span1: {
    fontSize: "12px",
  },
  box8: {
    display: "flex",
    // justifyContent: "space-between",
  },
}));

const Data1 = [
  {
    img1: "images/pool1.png",
    img2: "images/pool2.png",
    typo1: "Audited by",
    typo2: "   Rise of Defenders - GA",
    typo3: "RDR / BUSD",
    typo4: "Max BUSD",
    typo5: "Access",
    typo6: "49.99968",
    typo7: "BSL",
    typo8: "Progress",
    typo9: "Participants",
    typo10: "205",
    typo11: "100.00%",
    typo12: "277778/277778",
    typo13: "  Ratio per 1 BUSD ",
    typo14: "  27.77778 RDR ",
  },
  {
    img1: "images/pool1.png",
    img2: "images/pool2.png",
    typo1: "Audited by",
    typo2: "   Rise of Defenders - GA",
    typo3: "RDR / BUSD",
    typo4: "Max BUSD",
    typo5: "Access",
    typo6: "49.99968",
    typo7: "BSL",
    typo8: "Progress",
    typo9: "Participants",
    typo10: "205",
    typo11: "100.00%",
    typo12: "277778/277778",
    typo13: "  Ratio per 1 BUSD ",
    typo14: "  27.77778 RDR ",
  },
  {
    img1: "images/pool1.png",
    img2: "images/pool2.png",
    typo1: "Audited by",
    typo2: "   Rise of Defenders - GA",
    typo3: "RDR / BUSD",
    typo4: "Max BUSD",
    typo5: "Access",
    typo6: "49.99968",
    typo7: "BSL",
    typo8: "Progress",
    typo9: "Participants",
    typo10: "205",
    typo11: "100.00%",
    typo12: "277778/277778",
    typo13: "  Ratio per 1 BUSD ",
    typo14: "  27.77778 RDR ",
  },
  {
    img1: "images/pool1.png",
    img2: "images/pool2.png",
    typo1: "Audited by",
    typo2: "   Rise of Defenders - GA",
    typo3: "RDR / BUSD",
    typo4: "Max BUSD",
    typo5: "Access",
    typo6: "49.99968",
    typo7: "BSL",
    typo8: "Progress",
    typo9: "Participants",
    typo10: "205",
    typo11: "100.00%",
    typo12: "277778/277778",
    typo13: "  Ratio per 1 BUSD ",
    typo14: "  27.77778 RDR ",
  },
  {
    img1: "images/pool1.png",
    img2: "images/pool2.png",
    typo1: "Audited by",
    typo2: "   Rise of Defenders - GA",
    typo3: "RDR / BUSD",
    typo4: "Max BUSD",
    typo5: "Access",
    typo6: "49.99968",
    typo7: "BSL",
    typo8: "Progress",
    typo9: "Participants",
    typo10: "205",
    typo11: "100.00%",
    typo12: "277778/277778",
    typo13: "  Ratio per 1 BUSD ",
    typo14: "  27.77778 RDR ",
  },
  {
    img1: "images/pool1.png",
    img2: "images/pool2.png",
    typo1: "Audited by",
    typo2: "   Rise of Defenders - GA",
    typo3: "RDR / BUSD",
    typo4: "Max BUSD",
    typo5: "Access",
    typo6: "49.99968",
    typo7: "BSL",
    typo8: "Progress",
    typo9: "Participants",
    typo10: "205",
    typo11: "100.00%",
    typo12: "277778/277778",
    typo13: "  Ratio per 1 BUSD ",
    typo14: "  27.77778 RDR ",
  },
];
export default function Dashboard(props) {
  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  var classes = useStyles();

  return (
    <>
      <Box className={classes.mainBox}>
        <Container>
          <Box className={classes.mainBox1}>
            <Typography variant="h1">Live staking pools</Typography>
            <Box mt={8}>
              <Index />
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
}
