import React from "react";
import { Box, Container, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
//import PieChart from "./BestSeller";
import Piechart from "./PieChart";

export default function chart() {
  const use = makeStyles({
    root: {
      padding: "4rem",
    },

    heading: {
      "& h1": {
        // fontFamily: "Montserrat",
        fontWeight: "600",
        marginBottom: "12px",
        fontSize: "50px",
        lineHeight: "76px",
        textAlign: "center",
        color: "#4CB3DC",
        marginTop:"30px"
      },
    },

    graphboxtext: {
      padding:"20px",
      "& h2": {
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "18px",
        lineHeight: "58px",
        color: "#FFFFFF",
      },
      "& h3": {
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "30px",
        lineHeight: "30px",
        color: "#FFFFFF",
        "@media(max-width:420px)": {
          fontSize: "20px",
        },
      },
      "& h4": {
        paddingTop: "1rem",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "18px",
        lineHeight: "30px",
        color: "#0DD0FF",
      },
    },
    graphbox: {
      width: "100%",
    boxShadow: "7px 7px 4px rgb(0 0 0 / 10%)",
    textAlign: "left",
    borderRadius: "16px",
    backdropFilter: "blur(42px)",
    background:" #081B29",
    border: "1px solid #a6a6a654",
    boxSizing: "border-box",

    
    },

    downgraph: {
      marginTop: "1rem",
      padding: "1rem",
      background: "rgba(12, 42, 48, 0.3)",
      boxShadow: "7px 7px 4px rgba(0, 0, 0, 0.1)",
      backdropFilter: "blur(42px)",
      borderRadius: "16px",
    },
    downgraphtext: {
      borderRight: " 1px solid #113E5F",

      "& h1": {
        textAlign: "center",
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "30px",
        color: "#fff",
       
      },
      "& h2": {
        paddingTop: "1rem",
        textAlign: "center",
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "23px",
        lineHeight: "30px",
        color: "#FFFFFF",
      },
      "@media(max-width:420px)": {
        borderRight: "none",
      },
    },
    downgraphtext1: {
      "& h1": {
        textAlign: "center",
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "30px",
        color: "#FFFFFF",
      },
      "& h2": {
        paddingTop: "1rem",
        textAlign: "center",
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "23px",
        lineHeight: "30px",
        color: "#FFFFFF",
      },
    },
    piechart: {
      background: "rgba(12, 42, 48, 0.3)",
      boxShadow: "7px 7px 4px rgba(0, 0, 0, 0.1)",
      backdropFilter: "blur(42px)",
      borderRadius: "16px",
    },
  });
  const classes = use();
  return (
    <Box className={classes.root}>
      <Box className={classes.heading} >
      <Typography variant="h1">Analytics</Typography>
      </Box>
      <Box mt={2}>
        <Grid container spacing={3}>
          <Grid item lg={7} md={12} sm={12} xs={12}>
            <Box className={classes.graphbox}>
              <Box className={classes.graphboxtext}>
                <Typography variant="h2">VAYUBSC - USD</Typography>
                <Typography variant="h3">0.000000003839</Typography>
              </Box>
              <Box className={classes.graphboximg}>
                <img src="./images/chart_1.png" style={{ width: "100%" }} />
              </Box>
            </Box>
            <Box className={classes.graphbox} mt={2} style={{minHeight:"114px",}}>
              <Box mt={2} mb={2}>
              <Grid container>
                <Grid item lg={4} md={6} sm={12} xs={12}>
                  <Box className={classes.downgraphtext}>
                    <Typography variant="h1">VAYU BSC MARKET CAP</Typography>
                    <Typography variant="h2">$162,380,777.71</Typography>
                  </Box>
                </Grid>

                <Grid item lg={4} md={6} sm={12} xs={12}>
                  <Box className={classes.downgraphtext}>
                    <Typography variant="h1">TOTAL HOLDERS</Typography>
                    <Typography variant="h2">380,777.71</Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={6} sm={12} xs={12}>
                  <Box className={classes.downgraphtext1}>
                    <Typography variant="h1">TOTAL TRANSACTIONS</Typography>
                    <Typography variant="h2">162,380,777.71</Typography>
                  </Box>
                </Grid>
              </Grid>
              </Box>
            </Box>
          </Grid>

          <Grid item lg={5} md={12} sm={12} xs={12}>
            <Box className={classes.piechart}>
              <Piechart />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
